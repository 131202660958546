import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { CircularProgress, MenuItem, Select } from "@mui/material";

import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";

import { v4 as uuidv4 } from "uuid";

import "../../styles.css";
import CommonModal from "../../CommonComponent/Modal";
import API from "../../../apiServices/api";
// import { joinArray } from "../../../utils/commonFunctions";

const redirectionUser = process.env.REACT_APP_REDIRECTION_URL;

function EditToolbar(props) {
  const {
    setRows,
    setSelectedValues,
    setIsEdit,
    setIsEditAttribute,
    disabledButton,
    setDisabledButton,
    setRowModesModel,
    selectedKey,
    setNewCatUploaded,
  } = props;

  const [openDeleteCatalogModal, setOpenDeleteCatalogModal] = useState(false);

  const handleCloseModal = () => {
    setOpenDeleteCatalogModal(!openDeleteCatalogModal);
  };

  const handleClick = () => {
    const id = uuidv4();
    setSelectedValues({ category: "", subCategory1: "", subCategory2: "" });
    setIsEdit(true);
    setDisabledButton(true);
    setIsEditAttribute(false);
    setRows((oldRows) => [
      {
        id,
        CATEGORY: "",
        SUB_CATEGORY1: "",
        SUB_CATEGORY2: "",
        ATTRIBUTE_NAME: "",
        DESCRIPTION: "",
        isNew: true,
      },
      ...oldRows,
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "CATEGORY" },
    }));
  };

  const handleDelete = async () => {
    setNewCatUploaded(false);

    const payload = {
      account_name: redirectionUser,
      entity_name: selectedKey,
    };
    try {
      const response = await API.deleteEntity(payload);
      if (response.status === 200) {
        setNewCatUploaded(true);
        toast.success(`Catalog - ${selectedKey} Deleted Successfully`);
      } else {
        toast.error(
          `We are facing issue while deleting the Catalog - ${selectedKey}`
        );
      }
    } catch (error) {
      toast.error(
        `We are facing issue while deleting the Catalog - ${selectedKey}`
      );
      console.error("Error fetching data:", error);
    }
  };

  return (
    <GridToolbarContainer className="m-2 justify-between">
      <div className="flex justify-end w-full">
        <Button
          className="text-white text-sm bg-deep-navy rounded-md"
          startIcon={<DeleteIcon />}
          onClick={() => setOpenDeleteCatalogModal(!openDeleteCatalogModal)}
        >
          Delete Catalog
        </Button>
        <Button
          className={`${
            disabledButton ? "bg-gray-500" : "bg-deep-navy"
          } text-white text-sm px-2 rounded-md ml-4`}
          startIcon={<AddIcon />}
          onClick={handleClick}
          disabled={disabledButton}
        >
          Add record
        </Button>
      </div>
      <CommonModal
        open={openDeleteCatalogModal}
        handleClose={handleCloseModal}
        handleClickYes={handleDelete}
        message={`Are you sure, you want to delete this Catalog - ${selectedKey}?`}
        buttons={true}
      />
    </GridToolbarContainer>
  );
}

const UpdateAttributeTable = ({ selectedKey, user, setNewCatUploaded }) => {
  const [rows, setRows] = useState([]);
  const [attributeList, setAttributeList] = useState([]);

  const [isEdit, setIsEdit] = useState(false);

  const [rowModesModel, setRowModesModel] = useState({});
  const [clickDeleteButton, setClickDeleteButton] = useState({ id: "" });
  const [loader, setLoader] = useState(false);
  const [loadingDataMessage, setLoadingDataMessage] = useState("");
  const [editableAttribute, setEditableAttribute] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [subCategory1List, setSubCategory1List] = useState([]);
  const [subCategory2List, setSubCategory2List] = useState([]);

  const [disabledButton, setDisabledButton] = useState(false);
  const [isEditAttribute, setIsEditAttribute] = useState(false);

  const [selectedValues, setSelectedValues] = useState({
    category: "",
    subCategory1: "",
    subCategory2: "",
  });
  const [deleteAttribute, setDeleteAttribute] = useState({
    attribute_name: "",
    id: "",
  });
  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(!openModal);
    setClickDeleteButton({ ...clickDeleteButton, id: "" });
  };

  const getDataFromEntity = async () => {
    setLoadingDataMessage(
      `We are fetching the data for ${selectedKey}. Please wait..`
    );
    const payload = {
      account_name: redirectionUser,
      provider_name: user?.account_name,
      entity_name: selectedKey,
    };
    try {
      const response = await API.getEntityRecord(payload);
      if (response.status === 200 && response?.data?.data) {
        const data = response?.data?.data;
        setRows(data?.map((obj) => ({ ...obj, id: uuidv4() })));

        let attributes = data?.map((item) => item.ATTRIBUTE_NAME);
        setAttributeList(attributes);
        setLoadingDataMessage("");
      } else {
        setLoadingDataMessage(
          `There is no data for ${selectedKey}. please select another Entity.`
        );
        setRows([]);
        setAttributeList([]);
      }
    } catch (error) {
      setLoadingDataMessage(
        `We are facing some issue for fetching the data for ${selectedKey}. please select another Entity.`
      );
      console.error("Error fetching data:", error);
    }
  };

  const getAllCategories = async () => {
    const payload = {
      account_name: redirectionUser,
      entity_name: selectedKey,
    };
    try {
      const response = await API.getCategoriesList(payload);
      if (response.status === 200 && response?.data?.data) {
        const cat_list = [];
        response?.data?.data?.forEach((obj) => {
          cat_list.push(obj.CATEGORY);
        });
        setCategoryList(cat_list);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (selectedKey !== false) {
      getDataFromEntity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKey]);

  useEffect(() => {
    if (isEdit) {
      getAllCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  // get all Sub Category1 from selected Category...
  useEffect(() => {
    if (selectedValues.category !== "") {
      const getAllSubcategories = async () => {
        const payload = {
          account_name: redirectionUser,
          category: selectedValues?.category,
        };
        try {
          const response = await API.getAllSubCategories1List(payload);
          if (response.status === 200 && response?.data?.data) {
            let data = response?.data?.data;
            if (data?.length > 0) {
              const sub_cat_list = [];
              data?.forEach((obj) => {
                sub_cat_list.push(obj);
              });
              setSubCategory1List(sub_cat_list);
            } else {
              setSubCategory1List([]);
            }
          } else {
            setSubCategory1List([]);
          }
        } catch (error) {
          setSubCategory1List([]);
          console.error("Error fetching data:", error);
        }
      };
      getAllSubcategories();
    }
  }, [selectedValues.category]);

  // get all Sub Category2 from selected Category and Sub Category1...
  useEffect(() => {
    if (selectedValues.category !== "" && selectedValues?.subCategory1 !== "") {
      const getAllSubcategories = async () => {
        const payload = {
          account_name: redirectionUser,
          category: selectedValues?.category,
          sub_category1: selectedValues?.subCategory1,
        };
        try {
          const response = await API.getAllSubCategories2List(payload);
          if (response.status === 200 && response?.data?.data) {
            let data = response?.data?.data;
            if (data?.length > 0) {
              const sub_cat_list = [];
              data?.forEach((obj) => {
                sub_cat_list.push(obj);
              });
              setSubCategory2List(sub_cat_list);
            } else {
              setSubCategory2List([]);
            }
          } else {
            setSubCategory2List([]);
          }
        } catch (error) {
          setSubCategory2List([]);
          console.error("Error fetching data:", error);
        }
      };
      getAllSubcategories();
    }
  }, [selectedValues.category, selectedValues?.subCategory1]);

  const deleteRecordsAPI = async () => {
    setOpenModal(!openModal);
    setClickDeleteButton({ ...clickDeleteButton, id: deleteAttribute.id });

    const payload = {
      account_name: redirectionUser,
      provider_name: user?.account_name,
      entity_name: selectedKey,
      attribute_name: deleteAttribute.attribute_name,
    };
    try {
      const response = await API.deleteAttribute(payload);
      if (response.status === 200) {
        setDeleteAttribute({
          ...deleteAttribute,
          attribute_name: "",
          id: "",
        });
        setClickDeleteButton({ ...clickDeleteButton, id: "" });
        getDataFromEntity();
        toast.success("Record Deleted Successfully");
      } else {
        setDeleteAttribute({ ...deleteAttribute, attribute_name: "", id: "" });
        setClickDeleteButton({ ...clickDeleteButton, id: "" });
        toast.error("We are facing issue while Deleting the Record.");
      }
    } catch (error) {
      setDeleteAttribute({ ...deleteAttribute, attribute_name: "", id: "" });
      setClickDeleteButton({ ...clickDeleteButton, id: "" });
      toast.error("We are facing issue while Deleting the Record.");
      console.error("Error fetching data:", error);
    }
  };

  const updateRecordsAPI = async (result, tag, newRow, updatedRow) => {
    setLoader(true);
    const payload = {
      account_name: redirectionUser,
      result: result,
    };
    try {
      const response = await API.insertUpdateCatalogue(payload);
      if (response.status === 200) {
        if (tag === "insert") {
          callProcedureAddRecords(newRow, updatedRow);
        } else {
          callProcedureUpdateRecords(newRow, updatedRow);
        }
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  const callProcedureAddRecords = async (newRow, updatedRow) => {
    const payload = {
      account_name: redirectionUser,
    };
    try {
      const response = await API.procedureAddAttribute(payload);
      if (response.status === 200) {
        setRows(rows?.map((row) => (row.id === newRow.id ? updatedRow : row)));
        toast.success("Record Added Successfully");
        setLoader(false);
        setDisabledButton(false);
        getDataFromEntity();
      } else {
        setLoader(false);
        setDisabledButton(false);
        toast.error("There is an issue with Adding the record");
      }
    } catch (error) {
      setLoader(false);
      setDisabledButton(false);
      toast.error("There is an issue with Adding the record");
      console.error("Error fetching data:", error);
    }
  };

  const callProcedureUpdateRecords = async (newRow, updatedRow) => {
    const payload = {
      account_name: redirectionUser,
    };
    try {
      const response = await API.procedureUpdateAttribute(payload);
      if (response.status === 200) {
        toast.success("Record Updated Successfully");
        setRows(rows?.map((row) => (row.id === newRow.id ? updatedRow : row)));
        setDisabledButton(false);
        setLoader(false);
        getDataFromEntity();
      } else {
        setLoader(false);
        setDisabledButton(false);
        toast.error("There is an issue with Updating the record");
      }
    } catch (error) {
      setLoader(false);
      setDisabledButton(false);
      toast.error("There is an issue with Updating the record");
      console.error("Error fetching data:", error);
    }
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    const editableAttribute = rows.filter((row) => row.id === id);
    setEditableAttribute(editableAttribute[0]?.ATTRIBUTE_NAME);
    setDisabledButton(true);
    setIsEdit(true);
    setIsEditAttribute(true);
    setSelectedValues({
      category: editableAttribute[0]?.CATEGORY,
      subCategory1: editableAttribute[0]?.SUB_CATEGORY1,
      subCategory2: editableAttribute[0]?.SUB_CATEGORY2,
    });
  };

  const handleSaveClick = (id) => () => {
    setIsEdit(false);
    setIsEditAttribute(false);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    const attributeName = rows.filter((row) => row.id === id);
    setOpenModal(!openModal);
    setDeleteAttribute({
      ...deleteAttribute,
      attribute_name: attributeName[0]?.ATTRIBUTE_NAME,
      id: id,
    });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
    setDisabledButton(false);
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = {
      ...newRow,
      CATEGORY: selectedValues.category,
      SUB_CATEGORY1: selectedValues.subCategory1,
      SUB_CATEGORY2: selectedValues.subCategory2,
    };
    const index = attributeList?.filter(
      (item) => item?.toLowerCase() === updatedRow.ATTRIBUTE_NAME?.toLowerCase()
    );
    if (updatedRow.CATEGORY === "") {
      // toast.error("Please enter the Category.", {
      //   className: 'error-toast',
      // });
      toast.error("Please enter the Category.");
      return;
    } else if (updatedRow.SUB_CATEGORY1 === "") {
      toast.error("Please enter the Sub Category1.");
      return;
    }
    // else if (updatedRow.SUB_CATEGORY2 === "") {
    //   toast.error("Please enter the Sub Category2.");
    //   return;
    // }
    else if (updatedRow.DESCRIPTION === "") {
      toast.error("Please enter the Description.");
      return;
    }
    if (updatedRow.ATTRIBUTE_NAME === "") {
      toast.error("Please enter the Attribute Name.");
      return;
    } else if (newRow.isNew && index.length > 0) {
      toast.error("The Attribute Name is already exists.");
      return;
    } else if (
      index.length > 0 &&
      editableAttribute?.toLowerCase() !==
        updatedRow?.ATTRIBUTE_NAME.toLowerCase()
    ) {
      toast.error("The Attribute Name is already exists.");
      return;
    }

    const updatedObj = {
      entity: selectedKey,
      Attributes: updatedRow.ATTRIBUTE_NAME,
      name: user?.account_name,
      modification: {
        Attributes: updatedRow.ATTRIBUTE_NAME,
        Description: updatedRow.DESCRIPTION,
        Category: updatedRow.CATEGORY,
        Sub_Category1: updatedRow.SUB_CATEGORY1,
        Sub_Category2: updatedRow.SUB_CATEGORY2,
      },
      tag: "",
    };
    if (newRow.isNew) {
      delete updatedObj.Attributes;
      updatedObj.tag = "insert";
      let result = JSON.stringify(updatedObj);
      updateRecordsAPI(result, "insert", newRow, updatedRow);
    } else {
      updatedObj.tag = "update";
      let result = JSON.stringify(updatedObj);
      updateRecordsAPI(result, "update", newRow, updatedRow);
    }
    // return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: "CATEGORY",
      headerName: "Category",
      flex: 1,
      editable: true,
      renderEditCell: (params) => {
        const handleMultiSelectChange = (event) => {
          setSelectedValues((prevSelectedValues) => ({
            ...prevSelectedValues,
            category: event.target.value,
            subCategory1: "",
            subCategory2: "",
          }));
          setSubCategory1List([]);
          setSubCategory2List([]);
        };

        return (
          <Select
            labelId={`role-label-${params.id}`}
            id={`role-select-${params.id}`}
            value={selectedValues.category}
            onChange={handleMultiSelectChange}
          >
            {categoryList?.map((item) => {
              return <MenuItem value={item}>{item}</MenuItem>;
            })}
          </Select>
        );
      },
    },
    {
      field: "SUB_CATEGORY1",
      headerName: "Sub Category1",
      flex: 1,
      editable: true,
      renderEditCell: (params) => {
        const handleMultiSelectChange = (event) => {
          setSelectedValues((prevSelectedValues) => ({
            ...prevSelectedValues,
            subCategory1: event.target.value,
          }));
          setSubCategory2List([]);
        };

        return (
          <Select
            labelId={`role-label-${params.id}`}
            id={`role-select-${params.id}`}
            value={selectedValues.subCategory1}
            onChange={handleMultiSelectChange}
          >
            {subCategory1List?.map((item) => {
              return (
                <MenuItem value={item.SUB_CATEGORY1}>
                  {item.SUB_CATEGORY1}
                </MenuItem>
              );
            })}
          </Select>
        );
      },
    },
    {
      field: "SUB_CATEGORY2",
      headerName: "Sub Category2",
      flex: 1,
      editable: true,
      renderEditCell: (params) => {
        const handleMultiSelectChange = (event) => {
          setSelectedValues((prevSelectedValues) => ({
            ...prevSelectedValues,
            subCategory2: event.target.value,
          }));
        };
        return (
          <Select
            labelId={`role-label-${params.id}`}
            id={`role-select-${params.id}`}
            value={selectedValues.subCategory2}
            onChange={handleMultiSelectChange}
          >
            {subCategory2List?.filter((item) => item?.SUB_CATEGORY2 !== null)
              .length > 0 ? (
              subCategory2List
                ?.filter((item) => item?.SUB_CATEGORY2 !== null)
                .map((item) => (
                  <MenuItem
                    key={item?.SUB_CATEGORY2}
                    value={item?.SUB_CATEGORY2}
                  >
                    {item?.SUB_CATEGORY2}
                  </MenuItem>
                ))
            ) : (
              <MenuItem>No Data Found</MenuItem>
            )}
          </Select>
        );
      },
    },
    {
      field: "ATTRIBUTE_NAME",
      headerName: "Attributes",
      flex: 1,
      editable: isEditAttribute ? false : true,
    },
    {
      field: "DESCRIPTION",
      headerName: "Description",
      flex: 1,
      editable: true,
    },
    // {
    //   field: "TECH_NAME",
    //   headerName: "Tech Name",
    //   flex: 1,
    //   editable: true,
    // },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      cellClassName: "actions",
      flex: 1,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const isInDeleteMode = clickDeleteButton.id === id;

        if (isInEditMode) {
          if (loader) {
            return [
              <GridActionsCellItem
                icon={
                  <CircularProgress
                    style={{ width: "20px", height: "20px", color: "#09479f" }}
                  />
                }
                label="Loading"
                className="textPrimary"
                // onClick={handleEditClick(id)}
                color="inherit"
                title="Loading"
              />,
            ];
          } else {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: "primary.main",
                }}
                title="Save"
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
                title="Cancel"
              />,
            ];
          }
        }

        if (isInDeleteMode) {
          return [
            <GridActionsCellItem
              icon={
                <CircularProgress
                  style={{ width: "20px", height: "20px", color: "#09479f" }}
                />
              }
              label="Loading"
              className="textPrimary"
              // onClick={handleEditClick(id)}
              color="inherit"
              title="Loading"
            />,
          ];
        } else {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
              disabled={disabledButton}
              title="Edit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
              title="Delete"
            />,
          ];
        }
      },
    },
  ];

  return (
    <Box
      sx={{
        height: "auto",
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      {rows?.length > 0 ? (
        <DataGrid
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: {
              setRows,
              setSelectedValues,
              setIsEdit,
              setIsEditAttribute,
              disabledButton,
              setDisabledButton,
              setRowModesModel,
              selectedKey,
              setNewCatUploaded,
            },
          }}
          disableColumnMenu={true} // Disable column menu (including sorting options)
          disableColumnFilter={true} // Disable column filter menu
        />
      ) : (
        loadingDataMessage !== "" && (
          <div className="w-full flex justify-center text-deep-navy font-bold text-sm">
            {loadingDataMessage}
          </div>
        )
      )}
      <CommonModal
        open={openModal}
        handleClose={handleCloseModal}
        handleClickYes={deleteRecordsAPI}
        message={"Are you sure, you want to delete this Record?"}
        buttons={true}
      />
    </Box>
  );
};

export default UpdateAttributeTable;
