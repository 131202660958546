import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import NewCatalogUploadModal from "./components/NewCatalogUploadModal";

import UpdateAttributeTable from "./components/UpdateAttributeTable";
import Spinner from "../CommonComponent/Spinner";
import API from "../../apiServices/api";

const redirectionUser = process.env.REACT_APP_REDIRECTION_URL;

const UploadCatalog = () => {
  const state = useSelector((state) => state);
  const user = state && state.user;
  const [entityList, setEntityList] = useState([]);

  const [catalogDisable, setCatalogDisable] = useState(true);

  const [newCatUploaded, setNewCatUploaded] = useState(false);

  const [newCatalogModal, setNewCatalogModal] = useState(false);

  const [loader, setLoader] = useState(false);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setLoader(true);

    const getAllEntities = async () => {
      const payload = {
        account_name: redirectionUser,
        user: user?.account_name,
      };
      try {
        const response = await API.getAllEntities(payload);
        if (response.status === 200 && response?.data?.data) {
          const data = response?.data?.data;
          let result = data?.map((item) => item.ENTITY_NAME);
          setEntityList(result);
          setLoader(false);
          setCatalogDisable(true);
        } else {
          setEntityList([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getAllEntities();
  }, [user?.account_name, newCatUploaded]);

 // console.log("Result", entityList);

  return (
    <div className="flex flex-col px-5">
      <div className="flex justify-between items-center pt-4">
        <h3 className="text-2xl text-deep-navy font-bold">Upload Catalog</h3>
        {entityList?.length > 0 ? (
          <button
            className={`${catalogDisable && "opacity-20"
              } text-white bg-deep-navy px-4 py-2 rounded-md`}
            disabled={catalogDisable}
            onClick={() => setNewCatalogModal(!newCatalogModal)}
          >
            New Catalog Entry
          </button>
        ) : (
          <button
            className="text-white bg-deep-navy px-4 py-2 rounded-md"
            onClick={() => setNewCatalogModal(!newCatalogModal)}
          >
            New Catalog Entry
          </button>
        )}

      </div>

      <div className="w-full mt-2 mb-4 text-deep-navy">
        <p className="pt-2">
          Streamline catalog updates for the data partners by effortlessly
          uploading new data with ease by uploading a file through the
          user-friendly functionality.
        </p>
      </div>

      {entityList?.length > 0 ? (
        !loader ? (
          <div className="rounded-none">
            {entityList?.map((key) => {
              return (
                <Accordion
                  expanded={expanded === key}
                  onChange={handleChange(key)}
                  className="my-4 font-bold"
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        className={expanded === key ? "text-deep-navy" : "text-white"}
                      />
                    }
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className={
                      expanded === key
                        ? "bg-cyan text-white"
                        : "bg-deep-navy text-white"
                    }
                  >
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                      {key}
                    </Typography>
                  </AccordionSummary>
                  {expanded === key && (
                    <AccordionDetails>
                      <UpdateAttributeTable
                        selectedKey={expanded}
                        user={user}
                        setNewCatUploaded={setNewCatUploaded}
                      />
                    </AccordionDetails>
                  )}
                </Accordion>
              );
            })}
          </div>
        ) : (
          <div className="flex justify-center">
            <Spinner />
          </div>
        )
      ) : (
        <div className="text-lg text-deep-navy">
          There is no cataloges uploaded yet. Please upload a new Catalog.
        </div>
      )}

      {newCatalogModal && (
        <NewCatalogUploadModal
          open={newCatalogModal}
          setNewCatUploaded={setNewCatUploaded}
          close={() => setNewCatalogModal(!newCatalogModal)}
          user={user}
        />
      )}
    </div>
  );
};

export default UploadCatalog;
