import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Papa from "papaparse";
import { read, utils } from "xlsx";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { Alert, CircularProgress, SwipeableDrawer } from "@mui/material";

import * as actions from "../../redux/actions/index";
import CommonModal from "../CommonComponent/Modal";
import CustomTable from "../CommonComponent/Table";
import SampleTemplate from "../../Assets/CSVTemplates/Sample_Template_Match_Rate.xlsx";
import IntegrationImage from "../../Assets/integration.png";

import {
  ApprovedStatus,
  CompletedStatus,
  RejectedStatus,
  FailedStatus,
  WaitingStatus,
  OtherStatus,
} from "../CommonComponent/StatusColumn";
import { handleDate, isObjectEmpty } from "../../utils/commonFunctions";

import enrichment from "../../Assets/Data organization_Isometric.svg";
import Match_Rate_Card from "../../Assets/Match_Rate_card.png";

import Spinner from "../CommonComponent/Spinner";
import { toast } from "react-toastify";

import "../styles.css";
import "../pure-react.css";
import API from "../../apiServices/api";
import MatchAttributes from "./matchAttributes";

const nodeURL = process.env.REACT_APP_NODE_URL;
const redirectionUser = process.env.REACT_APP_REDIRECTION_URL;
const maxFileSize = process.env.REACT_APP_MAX_FILE_SIZE;

const initialState = {
  Query_Name: "advertiser_match",
  Column_Names: "",
  Consumer_Name: "",
  Provider_Name: "",
  File_Name: "",
  Match_Attribute: {},
  Match_Attribute_Value: {},
  file: "",
  attachment_type: "sf_table",
  sf_table_name: "",
};

const MatchRate = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const user = state && state.user;
  const TableData =
    state && state.PublisherForm && state.PublisherForm.TableData;
  const requestId =
    state && state.PublisherForm && state.PublisherForm.RequestId;
  const loadingTable =
    state && state.PublisherForm && state.PublisherForm.loadingTable;
  const [formData, setFormData] = useState({
    ...initialState,
    Consumer_Name: user?.account_name,
  });

  const [tableHead, setTableHead] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [userData, setUserData] = useState(null);
  const [profileData, setProfileData] = useState(null);

  const [loadIntegrate, setLoadIntegrate] = useState(false);
  const [providerList, setProviderList] = useState([]);
  const [identifierList, setIdentifierList] = useState([]);
  const [snowflakeTableList, setSnowflakeTableList] = useState([]);
  const [matchAttributesList, setMatchAttributesList] = useState([]);
  const [matchAttributesStatus, setMatchAttributesStatus] = useState(false);

  const [byPassAPICalled, setByPassAPICalled] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [outputError, setOutputError] = useState(null);
  //const [loader, setLoader] = useState(false);

  const [viewActionModal, setViewActionModal] = useState(false);
  const [requestFailedReason, setRequestFailedReason] = React.useState({
    openModal: false,
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [downloadSample, setDownloadSample] = useState(false);

  //const [consumerSorceTable, setConsumerSorceTable] = useState("");
  // const [integratingRows, setIntegratingRows] = useState([]);
  //const [integratedProviders, setIntegratedProviders] = useState([]);

  const [toggleDrawerPosition, setToggleDrawerPosition] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    search: false,
  });

  useEffect(() => {
    let intervalId;
    if (byPassAPICalled === true) {
      intervalId = setInterval(() => {
        fetchMainTable();
      }, 5000);
    }
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [byPassAPICalled]);

  useEffect(() => {
    if (TableData) {
      setTableHead(TableData?.head || []);
      setTableRows(TableData?.rows || []);
    }
  }, [TableData]);

  useEffect(() => {
    dispatch(
      actions.PublisherForm({
        loadingTable: true,
      })
    );
    fetchMainTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getAllProviders = async () => {
      const payload = {
        account_name: redirectionUser,
        db_name: user?.consumerDBName,
        consumer_name: user?.account_name,
        template_name: "match_rate",
      };
      try {
        const response = await API.getAllProvidersList(payload);
        if (response.status === 200 && response?.data?.data) {
          setProviderList(response?.data?.data);
        } else {
          setProviderList([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getAllProviders();
  }, [user?.consumerDBName, user?.account_name]);

  useEffect(() => {
    const getProfileData = async () => {
      const payload = {
        account_name: "dataexadmin",
        user: "dataexadmin",
      };

      try {
        const response = await API.getProfilePageData(payload);
        if (response?.status === 200 && response?.data?.data) {
          setProfileData(response?.data?.data);
        } else {
          setProfileData([]);
        }
       // console.log("profileData[0]?.EMAIL : ",  profileData[0]?.EMAIL);
      } catch (error) {
        console.error("Error while fetching data:", error);
      }
    };
    getProfileData();
  }, [user?.name, user?.account_name]);


  // get Identifier Type
  useEffect(() => {
    if (formData?.Provider_Name !== "") {
      const getIdentifierTypes = async () => {
        const payload = {
          account_name: redirectionUser,
          provider_name: formData?.Provider_Name,
          consumer_name: user?.account_name,
        };
        try {
          const response = await API.getIdentifierTypes(payload);
          if (response.status === 200 && response?.data?.data) {
            setIdentifierList(
              response?.data?.data?.[0]?.IDENTIFIER_TYPE?.split(",")
            );
          } else {
            setIdentifierList([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      getIdentifierTypes();
    }
  }, [user?.account_name, formData?.Provider_Name]);

  // get Match Attributes

  useEffect(() => {
    if (formData?.Provider_Name !== "") {
      const getMatchAttributes = async () => {
        const payload = {
          account_name: redirectionUser,
          provider_name: formData?.Provider_Name,
          consumer_name: user?.account_name,
        };
        try {
          const response = await API.getMatchAttributes(payload);
          if (response.status === 200 && response?.data?.data) {
            setMatchAttributesList(response?.data?.data);
          } else {
            setMatchAttributesList([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      getMatchAttributes();
    }
  }, [user?.account_name, formData?.Provider_Name]);

  // get Match Attributes status

  useEffect(() => {
    if (formData?.Provider_Name !== "") {
      const getMatchAttributesStatus = async () => {
        const payload = {
          account_name: redirectionUser,
          provider_name: formData?.Provider_Name,
          consumer_name: user?.account_name,
        };
        try {
          const response = await API.getMatchAttributesStatus(payload);
          if (response.status === 200 && response?.data?.data) {
            if (response?.data?.data[0]?.COUNT === 1) {
              setMatchAttributesStatus(true);
            } else {
              setMatchAttributesStatus(false);
            }
          } else {
            setMatchAttributesStatus(false);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      getMatchAttributesStatus();
    }
  }, [user?.account_name, formData?.Provider_Name]);

  useEffect(() => {
    if (formData?.attachment_type === "sf_table") {
      const getSnowflakeTable = async () => {
        const payload = {
          account_name: user?.account_name,
          db_name: user?.consumerDBName,
        };
        try {
          const response = await API.getSnowflakeTables(payload);
          if (response.status === 200 && response?.data?.data) {
            let result = response?.data?.data?.map((item) => {
              return item.TABLE_NAME;
            });
            setSnowflakeTableList(result);
          } else {
            setSnowflakeTableList([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      getSnowflakeTable();
    }
  }, [user?.consumerDBName, user?.account_name, formData?.attachment_type]);

  // UseEffect used for Inserting the Provider...

  const fetchMainTable = async () => {
    const payload = {
      account_name: user?.account_name,
      db_name: user?.consumerDBName,
      template_name: "ADVERTISER MATCH",
      role: user?.role?.includes("Consumer_Admin") ? "ADMIN" : "USER",
      user_name: user?.name,
    };
    try {
      const response = await API.fetchData(payload);
      if (response.status === 200 && response?.data?.data) {
        let res = response?.data?.data;
        setTableData(res);
        dispatch(
          actions.PublisherForm({
            loadingTable: false,
          })
        );
      } else {
        dispatch(
          actions.PublisherForm({
            loadingTable: false,
          })
        );
      }
    } catch (error) {
      dispatch(
        actions.PublisherForm({
          loadingTable: false,
        })
      );
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const getUserData = async () => {
      const payload = {
        account_name: user?.account_name,
        user_name: user?.name,
      };

      try {
        const response = await API.getUserDataResetPass(payload);
        if (response?.status === 200 && response?.data?.data) {
          let data = response?.data?.data[0];
          setUserData(data);
        } else {
          setUserData({});
        }
      } catch (error) {
        console.error("Error while fetching data:", error);
      }
    };
    getUserData();
  }, [user?.name, user?.account_name]);

  const sendEmail = async (provider_name, consumer_name) => {
    setLoadIntegrate(true);
    const payload = {
     
      //recipient: "pravinlandge36@gmail.com",
      recipient: profileData[0]?.EMAIL,
      email_id: userData?.EMAIL,     
      provider_name: provider_name,
      consumer_name: consumer_name,
    };
    try {
      const response = await API.mailforintegration(payload);
      if (response.status === 200) {
        setOutputError(null);
        // setLoader(false);
        setLoadIntegrate(false);
      } else {
        setOutputError(
          "There is an issue to send the mail Please try again later."
        );
        // setLoader(false);
        setLoadIntegrate(false);
      }
    } catch (error) {
      setOutputError(
        "There is an issue to send the mail Please try again later."
      );
      // setLoader(false);
      setLoadIntegrate(false);
      console.log("Error fetching data:", outputError);
      console.error("Error fetching data:", error);
    }
  };

  const handleCustomerFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectProvider = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileInput = (event) => {
    event.preventDefault();
    setFileErrorMessage("");
    const maxSizeInBytes = parseInt(maxFileSize) * 1024 * 1024; // 1MB (adjust as needed)

    console.log("maxSizeInBytes", maxSizeInBytes);
    var fileInput = document.getElementById("myFileInput");
    var file = fileInput?.files[0];

    if (file && file.size > maxSizeInBytes) {
      setFileErrorMessage(
        `File size exceeds the maximum allowed size (${maxFileSize}MB). Please choose a smaller file.`
      );
      // if (fileInputRef.current) {
      //   fileInputRef.current.value = "";
      // }
      return;
    }

    setFormData({ ...formData, file: file });
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (fileExtension === "csv") {
        // Handle CSV file
        Papa.parse(file, {
          complete: function (results) {
            const jsonData = results?.data;
            // Assuming the first row contains the column names
            const headers = jsonData[0];

            if (jsonData?.length > 1) {
              if (headers?.length > 1) {
                setFileErrorMessage(
                  "Columns are added more than one in the CSV file"
                );
              } else if (headers?.length < 1) {
                setFileErrorMessage("Please add one Column in the CSV file");
              } else if (headers?.length === 1) {
                if (
                  headers[0]?.toUpperCase() === "EMAIL" ||
                  headers[0]?.toUpperCase() === "PHONE" ||
                  headers[0]?.toUpperCase() === "MAID-WIP"
                ) {
                  setFileErrorMessage("");
                } else {
                  setFileErrorMessage("Invalid CSV file. Upload not allowed.");
                }
              } else {
                setFileErrorMessage("Invalid CSV file. Upload not allowed.");
              }
            } else {
              setFileErrorMessage("Invalid CSV file. No Data present.");
            }
          },
        });
      } else if (fileExtension === "xlsx") {
        // Handle XLSX file
        const reader = new FileReader();
        reader.onload = () => {
          const arrayBuffer = reader.result;
          const workbook = read(arrayBuffer, { type: "array" });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = utils.sheet_to_json(worksheet, { header: 1 });

          // Assuming the first row contains the column names
          const headers = jsonData[0];
          if (jsonData?.length > 1) {
            if (headers?.length > 1) {
              setFileErrorMessage(
                "Columns are added more than one in the XLSX file"
              );
            } else if (headers?.length < 1) {
              setFileErrorMessage("Please add one Column in the XLSX file");
            } else if (headers?.length === 1) {
              if (
                headers[0]?.toUpperCase() === "EMAIL" ||
                headers[0]?.toUpperCase() === "PHONE" ||
                headers[0]?.toUpperCase() === "MAID-WIP"
              ) {
                setFileErrorMessage("");
              } else {
                setFileErrorMessage("Invalid XLSX file. Upload not allowed.");
              }
            } else {
              setFileErrorMessage("Invalid XLSX file. Upload not allowed.");
            }
          } else {
            setFileErrorMessage("Invalid XLSX file. No Data present.");
          }
        };

        reader.readAsArrayBuffer(file);
      } else {
        setFileErrorMessage(
          "Invalid file type. Only CSV and XLSX files are allowed."
        );
      }
    }
  };

  // const isValidInput = (inputString) => {
  //   const regex = /^[0-9][0-9,-]*[0-9]$/; // regex pattern to match only comma, hyphen, and numeric values and start and end with numeric values
  //   return regex.test(inputString); // returns true if inputString matches the regex pattern, false otherwise
  // };

  const callByPassAPI = async (newReqId, providerAccIdentifier) => {
    setByPassAPICalled(true);

    const payload = {
      account_name: user?.account_name,
      db_name: user?.consumerDBName,
      provider_account_identifier: providerAccIdentifier,
      newReqId: newReqId,
      template_name: "ADVERTISER MATCH",
    };
    try {
      const response = await API.callProcedureMatchRate(payload);
      if (response.status === 200) {
        fetchMainTable();
        setByPassAPICalled(false);
        try {
          const response = await API.getRequestDataUsingRunId(payload);
          if (
            response.status === 200 &&
            response?.data?.data &&
            response?.data?.data?.length > 0
          ) { 
            
            let result = response?.data?.data[0];
            const payload = {
              account_name: redirectionUser,
              RUN_ID: result.RUN_ID,
              TEMPLATE_NAME: result.TEMPLATE_NAME,
              CONSUMER_RECORD_COUNT: result.CONSUMER_RECORD_COUNT,
              PROVIDER_NAME: result.PROVIDER_NAME,
              CONSUMER_NAME: result.CONSUMER_NAME,
              // REQUEST_TS: result?.REQUEST_TS,
              REQUEST_TS: result?.RUN_ID,
              STATUS: result.STATUS,
              MATCHED_PERCENTAGE:
                (parseInt(result?.MATCH_COUNT) /
                  parseInt(result?.CONSUMER_RECORD_COUNT)) *
                100,
              ATTRIBUTES: result?.IDENTIFIER_TYPE,
              USER_NAME: result?.USER_NAME,
            };
            try {
              const response = await API.insertLogTableData(payload);
              if (response.status === 200) {
                fetchMainTable();
                setByPassAPICalled(false);
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        setByPassAPICalled(false);
        fetchMainTable();
      }
    } catch (error) {
      setByPassAPICalled(false);  
      fetchMainTable();   
      console.error("Error fetching data:", error);
    }
  };

  const insertDataIntoTable = async (filename) => {
    try {
      const payload = {
        account_name: user?.account_name,
        db_name: user?.consumerDBName,
        provider_name: formData.Provider_Name,
      };

      const response = await API.getProviderAccount(payload);
      if (
        response.status === 200 &&
        response?.data?.data[0]?.PROV_ACCT_IDENTIFIER
      ) {
        let providerAccIdentifier =
          response?.data?.data[0]?.PROV_ACCT_IDENTIFIER;
         try {
          const payload = {
            account_name: user?.account_name,
            template_name: formData?.Query_Name,
            provider_name: formData?.Provider_Name,
            columns: formData?.Column_Names,
            consumer_name: formData?.Consumer_Name,
            run_id: formData?.RunId,
            file_name: filename,
            attribute_name: matchAttributesStatus
              ? JSON.stringify(formData?.Match_Attribute)
              : null,
            // attribute_value: formData?.Match_Attribute_Value,
            consumer_database_name: user?.consumerDBName,
            tag: formData?.attachment_type,
            provider_account_identifier: providerAccIdentifier,
            user_name: user?.name,
          };

          const response = await API.insertMatchRateRequest(payload);
          if (response.status === 200) {
            try {
              const payload = {
                account_name: formData?.Provider_Name,
                // db_name: user?.providerDBName,
                db_name: `DCR_${providerAccIdentifier}_PROVIDER_DB`,
                run_id: formData.RunId,
              };

              const response = await API.insertRunId(payload);
              if (response.status === 200) {
                // Reset the file input
                if (fileInputRef.current) {
                  fileInputRef.current.value = "";
                }
                fetchMainTable();
                setFormData({
                  ...initialState,
                  Consumer_Name: user?.account_name,
                });
                setToggleDrawerPosition({
                  ...toggleDrawerPosition,
                  right: false,
                });
                callByPassAPI(formData.RunId, providerAccIdentifier);
                setLoading(false);
              }
            } catch (error) {
              setLoading(false);

              console.error("Error fetching data:", error);
            }
          } else {
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          console.error("Error fetching data:", error);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (matchAttributesStatus && isObjectEmpty(formData?.Match_Attribute)) {
      toast.error("please select Match Attribute");
      return;
    }
    formData.RunId = Date.now();
    setLoading(true);
    setErrorMessage("");

    if (formData?.attachment_type === "attachment") {
      if (downloadSample || fileErrorMessage !== "") {
        setDownloadSample(false);
        return;
      }
      // Upload file in Local uploadedFiles folder..
      const fileName = `${formData.RunId + "." + formData?.file?.name?.split(".")[1]
        }`;
      const modifiedFile = new File([formData?.file], fileName, {
        type: formData?.file.type,
      });
      formData.File_Name = fileName;
      formData.file = modifiedFile;
      const localFile = new FormData();

      localFile.append("myFile", modifiedFile);

      axios
        .post(`${nodeURL}/api/localFileUpload`, localFile, {
          headers: {
            "content-type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then(async (response) => {
          if (parseInt(response?.status) === 200) {
            const payload = {
              account_name: user?.account_name,
              filename: fileName,
              identifyer: formData?.Column_Names.toUpperCase(),
              db_name: user?.consumerDBName,
            };
            try {
              const response = await API.attachment(payload);
              if (
                parseInt(response?.status) === 200 &&
                response?.data?.data === true
              ) {
               // fetchMainTable();
                insertDataIntoTable(fileName);
              } else {
                //fetchMainTable();
                setLoading(false);
                setErrorMessage(
                  "The data is not matching with requested Identifier."
                );
              }
            } catch (error) {
              setLoading(false);

              console.error("Error fetching data:", error);
            }
          } else {
            setLoading(false);
            setErrorMessage("Something went wrong, please try again later !!!");
          }
        })
        .catch((error) => {
          setLoading(false);
          setErrorMessage("Something went wrong, please try again later !!!");
          console.error("Error fetching data:", error);
        });
    } else {
      insertDataIntoTable(formData?.sf_table_name);
    }
  };

  const fetchcsvTableData = async (templateName, runId) => {
    templateName = templateName.replace(/\s/g, "_");
    setViewActionModal(true);

    try {
      const payload = {
        account_name: user?.account_name,
        templateName: templateName,
        run_id: runId,
        consumer_database_name: user?.consumerDBName,
      };

      const response = await API.viewRequestDataMatchRate(payload);

      if (response.status === 200 && response?.data?.data) {
        let data = response?.data?.data;
        let head = [];
        let row = [];
        if (data?.length > 0) {
          head = data && Object.keys(data[0]);
          data?.map((obj) => {
            return row.push(head?.map((key) => obj[key]));
          });
        }
        dispatch(
          actions.PublisherForm({
            TableData: { head: head, rows: row },
            RequestId: runId,
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const downloadNewFile = () => {
    const link = document.createElement("a");
    link.href = SampleTemplate;
    link.download = "Sample_Template_Match_Rate.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toast.success(`The File has been downloaded...`);
  };

  // const getIntegratedProviders = async () => {
  //   const payload = {
  //     account_name: redirectionUser,
  //     CONSUMER_NAME: user?.account_name,
  //   };
  //   try {
  //     const response = await API.getIntegratedProviders(payload);
  //     if (
  //       response.status === 200 &&
  //       response?.data?.data &&
  //       response?.data?.data?.length > 0
  //     ) {
  //       let result = response?.data?.data?.map((item) => item.PROVIDER_NAME);
  //       setIntegratedProviders(result);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // useEffect(() => {
  //   getIntegratedProviders();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user?.account_name]);

  // //useEffect for finding the Consumers Source Table...
  // useEffect(() => {
  //   const getConsumerSourceTable = async () => {
  //     const payload = {
  //       account_name: user?.account_name,
  //       db_name: user?.consumerDBName,
  //     };
  //     try {
  //       const response = await API.getConsumerSourceTable(payload);
  //       if (response.status === 200 && response?.data?.data) {
  //         let data = response?.data?.data;
  //         setConsumerSorceTable(data[0]?.SETTING_VALUE);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  //   getConsumerSourceTable();
  // }, [user?.consumerDBName, user?.account_name]);

  // This Function call after press the integration button
  // const handleIntegration = async (provider_name, entity_name) => {
  //   setIntegratingRows((prevRows) => [
  //     ...prevRows,
  //     `${provider_name}-${entity_name}`,
  //   ]);
  //   // setIntegrating(true);

  //   const payload = {
  //     account_name: redirectionUser,
  //     CONSUMER_NAME: user?.account_name,
  //     provider_name: provider_name,
  //     CONSUMER_ACCOUNT: user?.ConsumerPartyAccount,
  //     PROVIDER_ACCOUNT: null,
  //     provider_source_table: entity_name,
  //     consumer_source_table: consumerSorceTable,
  //   };

  //   try {
  //     const response = await API.getPartyAccount(payload);
  //     if (response.status === 200 && response?.data?.data) {
  //       let data = response?.data?.data;
  //       let providerPartyAccount = data[0]?.PARTY_ACCOUNT;

  //       payload.PROVIDER_ACCOUNT = providerPartyAccount;

  //       try {
  //         const response = API.integrateConsumer(payload);
  //         if (response.status === 200) {
  //           try {
  //             const response = API.insertIntegrateConsumer(payload);
  //             if (response.status === 200 && response?.data?.data) {
  //               console.log("response?.data?.data", response?.data?.data);
  //               // setIntegrating(false);
  //             }
  //           } catch (error) {
  //             console.error("Error fetching data:", error);
  //           }
  //         }
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // const handelFlag = async (provider_name, consumer_name) => {
  //   setLoadIntegrate(true);
  //   const payload = {
  //     account_name: redirectionUser,
  //     provider_name: provider_name,
  //   };
  //   try {
  //     const response = await API.getPartyAccount(payload);
  //     if (response.status === 200 && response?.data?.data) {
  //       let data = response?.data?.data;
  //       let providerPartyAccount = data[0]?.PROVIDER_ACCOUNT;
  //       let providerName = data[0]?.PROVIDER_NAME;
  //       const payload = {
  //         provider_name: providerName,
  //         provider_id: providerPartyAccount,
  //         consumer_name: user?.account_name,
  //         consumer_id: user?.ConsumerPartyAccount,
  //         consumer_user_name: user?.name,
  //       };
  //       try {
  //         const response = await API.advanceIntegration(payload);
  //         if (response.status === 200) {
  //           console.log("Response of UpdateFlag", response?.data?.data);
  //           setLoadIntegrate(false);
  //           fetchMainTable();
  //         }
  //       } catch (error) {
  //         console.error("Error of Upadte Flag:", error);
  //       }
  //     }
  //   }
  //   catch (error) {
  //     console.error("Error of Upadte Flag:", error);
  //   }
  // };

  const handleToggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setFormData({ ...initialState, Consumer_Name: user?.account_name });
    setToggleDrawerPosition({ ...toggleDrawerPosition, [anchor]: open });
  };

  return (
    <div className="flex flex-col w-full px-4 ">
      <div className="flex flex-row justify-between items-center w-full mt-2">
        <div>
          <h3 className="text-xl font-bold text-deep-navy mr-2">Match Rate</h3>
        </div>
        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            <button
              className="my-2 pr-4 flex items-center justify-center rounded-md bg-deep-navy px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#00FFFF] hover:text-deep-navy focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-green"
              onClick={handleToggleDrawer(anchor, true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              raise new request
            </button>
            {toggleDrawerPosition[anchor] && (
              <SwipeableDrawer
                anchor={anchor}
                open={toggleDrawerPosition[anchor]}
                onClose={handleToggleDrawer(anchor, false)}
                onOpen={handleToggleDrawer(anchor, true)}
              >
                <div className="flex flex-col flex-shrink h-[62rem] w-full px-5 bg-deep-navy text-electric-green bg-[url('/static/media/Target audience _Two Color.6aa8a9f45675ef6dfbc33c3c3b61aa03.svg')] ">
                  {/* <img
                    className="absolute  w-96  bottom-1 opacity-90 z-10 right-0 text-amarant-400"
                    src={searchillustration}
                    alt=""
                  /> */}
                  <form
                    className="my-4 px-4 py-2 h-auto w-96 z-20  bg-deep-navy/50 "
                    name="myForm"
                    onSubmit={handleSubmit}
                  >
                    <div className="flex flex-row justify-between ">
                      <h3 className="text-xl font-semibold">
                        New Match Rate Request
                      </h3>
                      <button onClick={handleToggleDrawer("right", false)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div className="mt-2 pb-2 flex flex-col">
                        <label>data partner</label>
                        <select
                          id="provider"
                          name="Provider_Name"
                          required
                          className="block w-full rounded-md border-0 py-1.5 text-[#FFFFFF]  bg-deep-navy shadow-sm ring-1 ring-inset ring-[#00FFB4] placeholder:text-[#FFFFFF] focus:ring-2 focus:ring-inset focus:ring-electric-green sm:text-sm sm:leading-6"
                          value={formData["Provider_Name"]}
                          onChange={handleSelectProvider}
                        >
                          <option value="">please select</option>
                          {providerList?.length > 0 ? (
                            providerList?.map((item, index) => (
                              <option key={index} value={item.PROVIDER_NAME}>
                                {item.PROVIDER_NAME}
                              </option>
                            ))
                          ) : (
                            <option value="">Loading...</option>
                          )}
                        </select>
                      </div>
                      {/* <div className="mt-2 pb-21 flex flex-col">
                        <label>Attachment type</label>
                        <select
                          name="attachment_type"
                          onChange={handleCustomerFormData}
                          required
                          value={formData.attachment_type}
                          className="block w-full rounded-md border-0 py-1.5 text-electric-green  bg-deep-navy shadow-sm ring-1 ring-inset ring-true-teal placeholder:text-true-teal focus:ring-2 focus:ring-inset focus:ring-electric-green sm:text-sm sm:leading-6"
                        >
                          <option value="">please select</option>
                          <option value="attachment">Attachment</option>
                          <option value="sf_table">SF Table</option>
                        </select>
                      </div> */}

                      {formData.attachment_type === "attachment" ? (
                        <>
                          <div className="mt-2 pb-21 flex flex-col">
                            <label>Upload File</label>
                            <input
                              // className="block w-full rounded-md border-0 py-1.5 text-electric-green  bg-deep-navy shadow-sm ring-1 ring-inset ring-true-teal placeholder:text-true-teal focus:ring-2 focus:ring-inset focus:ring-electric-green sm:text-sm sm:leading-6"
                              className="block w-full text-sm text-true-teal
                        file:mr-4 file:py-2 file:px-4 file:rounded-md
                        file:border-0 file:text-sm file:font-semibold
                        file:bg-electric-green file:text-deep-navy
                        hover:file:bg-true-teal hover:file:cursor-pointer"
                              type="file"
                              id="myFileInput"
                              onChange={handleFileInput}
                              required
                              ref={fileInputRef}
                            />
                          </div>
                          <div className="my-4 flex flex-col">
                            <button
                              className="flex w-fit text-electric-green"
                              onClick={() => {
                                downloadNewFile();
                                setDownloadSample(true);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  stroke-linejoin="round"
                                  d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                              <span className="pl-2 underline">
                                Download Template
                              </span>
                            </button>
                          </div>

                          <div className="my-2">
                            {fileErrorMessage !== "" && (
                              <Alert
                                className="my-4 text-red-600"
                                severity="error"
                              >
                                {fileErrorMessage}
                              </Alert>
                            )}
                          </div>
                        </>
                      ) : (
                        <div className="mt-2 pb-21 flex flex-col">
                          <label>Advertisers Table</label>
                          <select
                            name="sf_table_name"
                            onChange={handleCustomerFormData}
                            required
                            value={formData.sf_table_name}
                            className="block w-full rounded-md border-0 py-1.5 text-[#FFFFFF]  bg-deep-navy shadow-sm ring-1 ring-inset ring-[#00FFB4] placeholder:text-true-teal focus:ring-2 focus:ring-inset focus:ring-electric-green sm:text-sm sm:leading-6"
                          >
                            <option value="">please select</option>
                            {snowflakeTableList?.map((table_name, index) => {
                              return (
                                <option key={index} value={table_name}>
                                  {table_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}

                      <div className="mt-2 pb-21 flex flex-col">
                        <label>Identifier Type</label>
                        <select
                          name="Column_Names"
                          onChange={handleCustomerFormData}
                          required
                          value={formData.Column_Names}
                          className="block w-full rounded-md border-0 py-1.5 text-[#FFFFFF]  bg-deep-navy shadow-sm ring-1 ring-inset ring-[#00FFB4] placeholder:text-true-teal focus:ring-2 focus:ring-inset focus:ring-electric-green sm:text-sm sm:leading-6"
                        >
                          <option value="">please select</option>
                          {identifierList?.map((item, index) => {
                            return (
                              <option key={index} value={item?.toLowerCase()}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {matchAttributesStatus && (
                        <div className="mt-2 pb-21 flex flex-col">
                          <MatchAttributes
                            formData={formData}
                            setFormData={setFormData}
                            matchAttributesList={matchAttributesList}
                          />
                        </div>
                      )}

                      <div className="flex justify-end">
                        {loading ? (
                          <div className="flex w-full justify-center rounded-md bg-electric-green px-3 py-1.5 text-sm font-semibold leading-6 text-deep-navy shadow-sm hover:bg-true-teal focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-green mt-4">
                            <CircularProgress
                              style={{
                                width: "24px",
                                height: "24px",
                                color: "#FFFFFF",
                              }}
                            />
                          </div>
                        ) : (
                          <button
                            className="flex w-full justify-center rounded-md bg-electric-green px-3 py-1.5 text-sm font-semibold leading-6 text-deep-navy shadow-sm hover:bg-true-teal focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-green mt-4"
                            type="submit"
                          >
                            submit request
                          </button>
                        )}
                      </div>
                      <div className="py-2">
                        {errorMessage !== "" ? (
                          <Alert className="my-4 text-red-600" severity="error">
                            {errorMessage}
                          </Alert>
                        ) : (
                          loading && (
                            <Alert
                              className="my-4 text-red-600"
                              severity="error"
                            >
                              Uploading the table data. Please wait
                            </Alert>
                          )
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </SwipeableDrawer>
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="w-full mt-2 mb-4 text-deep-navy">
        <p className="">
          Determine the match rate by comparing advertiser and partners data,
          revealing the count of matching data records and ensuring accurate
          alignment between the two datasets.
        </p>
      </div>

      <img
        className="absolute object-cover w-1/4 -z-50 bottom-1 opacity-20 right-0"
        src={enrichment}
        alt=""
      />
      {!loadingTable ? (
        <div className="flex flex-col w-full px-2">
          <h1 className="text-xl font-regular text-deep-navy pb-2 font-bold flex justify-center">
            Recent Requests
          </h1>
          {tableData?.length > 0 ? (
            <div className="my-4 grid grid-cols-1 gap-4 sm:grid-cols-3">
              {tableData?.map((item, index) => {
                // const isIntegrating = integratingRows?.includes(
                //   `${item.PROVIDER_NAME}-${item.ENTITY_NAME}`
                // );
                // const integratedProvidersList = integratedProviders?.map(
                //   (item) => item.toLowerCase()
                // );
                // const isIntegrated = integratedProvidersList?.includes(
                //   `${item?.PROVIDER_NAME?.toLowerCase()}`
                // );
                return (
                  <div
                    key={index}
                    class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
                  >
                    <div
                      className={`${item.STATUS.toLowerCase() === "true"
                        ? "bg-electric-green"
                        : item.STATUS.toLowerCase() === "false"
                          ? "bg-red-200"
                          : item.STATUS.toLowerCase() === "completed"
                            ? "bg-electric-green"
                            : item.STATUS.toLowerCase() === "failed"
                              ? "bg-red-200"
                              : "bg-downriver-200"
                        } h-10 flex items-center rounded-lg`}
                    >
                      <span className="text-green-900 mx-4 font-bold">
                        {item.STATUS.toLowerCase() === "true"
                          ? ApprovedStatus("approved")
                          : item.STATUS.toLowerCase() === "false"
                            ? RejectedStatus("rejected")
                            : item.STATUS.toLowerCase() === "completed"
                              ? CompletedStatus(item.STATUS)
                              : item.STATUS.toLowerCase() === "failed"
                                ? FailedStatus(item.STATUS)
                                : item.STATUS.toLowerCase() === "waiting for approval"
                                  ? WaitingStatus(item.STATUS)
                                  : OtherStatus(item.STATUS)}
                      </span>
                    </div>
                    <div className="p-4 text-sm">
                      <div className="flex items-center">
                        <img className="h-7 w-7" src={Match_Rate_Card} alt="" />
                        <div className="mx-4 block">
                          <div class="text-sm text-deep-navy dark:text-white">
                            <strong>Request Id - </strong>
                            {item?.RUN_ID}
                          </div>
                          <span class="text-sm text-deep-navy dark:text-white">
                            <strong>
                              {item?.PROVIDER_ORG_NAME?.toUpperCase()}{" "}
                              <span className="text-base"> | </span>{" "}
                              {item?.CONSUMER_ORG_NAME?.toUpperCase()}
                            </strong>
                          </span>
                        </div>
                      </div>

                      <hr className="my-2 border-slate-300" />
                      <div class="my-2 flex justify-between text-deep-navy">
                        <span className="font-bold">Identifier Type</span>
                        <span>{item?.IDENTIFIER_TYPE}</span>
                      </div>
                      <hr className="my-2 border-slate-300" />
                      <div class="my-2 flex justify-between text-deep-navy">
                        <span className="font-bold">Match Attribute</span>
                        <span>{item?.ATTRIBUTE}</span>
                      </div>
                      <hr className="my-2 border-slate-300" />
                      <div class="my-2 flex justify-between text-deep-navy">
                        <span className="font-bold">Match Percentage</span>
                        <span>
                          {(
                            (item?.MATCH_COUNT * 100) /
                            item?.CONSUMER_RECORD_COUNT
                          )?.toFixed(2)}
                        </span>
                      </div>
                      <hr className="my-2 border-slate-300" />
                      <div class="my-2 flex justify-between text-deep-navy">
                        <span className="font-bold">Requested By</span>
                        <span>{item?.USER_NAME}</span>
                      </div>
                      <hr className="my-2 border-slate-300" />
                      <div class="my-2 flex justify-between text-deep-navy">
                        <span className="font-bold">Requested On</span>
                        <span>{handleDate(item?.REQUEST_TS)}</span>
                        {/* <span>{item?.REQUEST_TS}</span> */}
                      </div>
                      <hr className="my-2 border-slate-300" />
                      <div class="my-2 flex text-deep-navy justify-around items-center">
                                               {item?.STATUS.toLowerCase() === "failed" ||
                          item?.STATUS.toLowerCase() === "false" ? (
                          <div className="flex flex-row items-center justify-center">
                            <button
                              onClick={() =>
                                setRequestFailedReason({
                                  ...requestFailedReason,
                                  openModal: true,
                                  message: item?.ERROR_FOR_WEBUI ? item?.ERROR_FOR_WEBUI :"Oops!Something went wrong.Please try again later." ,
                                })
                              }
                              className="flex flex-row px-2 text-red-600"
                              title="Request Error"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-5 h-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                                />
                              </svg>
                              <span className="pl-2 underline">
                                Request Error
                              </span>
                            </button>
                          </div>
                        ) : (
                          <div className="flex justify-center">
                            <button
                              onClick={() =>
                                fetchcsvTableData(
                                  item?.TEMPLATE_NAME,
                                  item?.RUN_ID
                                )
                              }
                              className={`${item?.STATUS.toLowerCase() === "completed"
                                ? "text-deep-navy"
                                : "text-electric-green/50"
                                } flex flex-row items-center px-2 justify-center`}
                              disabled={
                                item?.STATUS.toLowerCase() !== "completed"
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                              <span className="pl-2 underline">View</span>
                            </button>

                            {/* Added button for Integration */}
                            <button
                              onClick={() => {
                                const confirmationMessage = `Are you sure to integrate ${item.PROVIDER_NAME} with ${item.CONSUMER_NAME}?`;

                                if (window.confirm(confirmationMessage)) {
                                 // handelFlag(item.PROVIDER_NAME, item.CONSUMER_NAME);
                                  // setLoader(true);
                                  sendEmail(item.PROVIDER_NAME, item.CONSUMER_NAME)
                                }
                              }}
                              disabled={item.INTEGRATION === "TRUE"}
                              className={`${item.INTEGRATION === "TRUE" && "opacity-50"} flex flex-row items-center justify-center`}
                              title={item.INTEGRATION === "TRUE" ? "Integrated" : "Integrate"}
                            >
                              {loadIntegrate ?
                                (<div className="flex justify-center px-8">
                                  <CircularProgress
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      color: "amaranth-600",
                                    }}
                                    title="Wait integrating is going on"
                                  />
                                </div>) : (<>
                                  <img className="w-6 h-6" src={IntegrationImage} alt="IntegrationIcon" />
                                  <span className="pl-1 underline">
                                    {item.INTEGRATION === "TRUE" ? "Integrated" : "Integrate"}
                                  </span>
                                </>
                                )}
                            </button>

                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              <span className="text-deep-navy font-bold mt-4">
                There is no recent queries are available. Please submit a
                request to see queries!!
              </span>
            </div>
          )}
        </div>
      ) : (
        <div className="flex justify-center mt-8">
          <Spinner />
        </div>
      )}

      <CustomTable
        id={requestId}
        head={tableHead}
        rows={tableRows}
        pagination={"none"}
        open={viewActionModal}
        handleClose={() => {
          setViewActionModal(false);
          dispatch(
            actions.PublisherForm({
              TableData: { head: [], rows: [] },
            })
          );
        }}
        title={"Request Output"}
        overflow={true}
      />

      {requestFailedReason.openModal ? (
        <CommonModal
          open={requestFailedReason.openModal}
          handleClose={() =>
            setRequestFailedReason({ ...requestFailedReason, openModal: false })
          }
          title={"Request Error"}
          message={requestFailedReason.message}
          buttons={false}
          textColor={"text-red-600"}
          svg={true}
        />
      ) : null}
    </div>
  );
};

export default MatchRate;