import React, { useEffect, useState } from "react";
import { Box, Modal } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";

import { CircularProgress } from "@mui/material";
import Papa from "papaparse";
import { read, utils } from "xlsx";
import TemplateCatalogFile from "../../../Assets/CSVTemplates/Template-Catalog-File.xlsx";
import API from "../../../apiServices/api";

const redirectionUser = process.env.REACT_APP_REDIRECTION_URL;
const nodeURL = process.env.REACT_APP_NODE_URL;

const CSVFileColumns = [
  "Entity",
  "Category",
  "Sub Category1",
  "Sub Category2",
  "Attributes",
  "Description",
];

// Modal style
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  p: 4,
  borderRadius: 5,
};

const NewCatalogUploadModal = ({ open, close, user, setNewCatUploaded }) => {
  let [parsedData, setParsedData] = useState([]);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileError, setFileError] = useState("");

  // const [categoryList, setCategoryList] = useState([]);
  // const [subCategoryList, setSubCategoryList] = useState([]);

  const [file, setFile] = useState("");

  const [loader, setLoader] = useState(false);

  const downloadNewFile = () => {
    const link = document.createElement("a");
    link.href = TemplateCatalogFile;
    link.download = "Template-Catalog-File.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toast.success(`Template Catalog File has been downloaded...`);
  };

  //useEffect for fetching Category and Sub category...
  useEffect(() => {
    const getSubCategories = async () => {
      const payload = {
        account_name: redirectionUser,
      };
      try {
        const response = await API.getSubCategories(payload);
        if (response.status === 200 && response?.data?.data) {
          let data = response?.data?.data;
          if (data?.length > 0) {
            const cat_list = [];
            const sub_cat_list = [];
            response?.data?.data?.forEach((obj) => {
              cat_list.push(obj.CATEGORY);
              sub_cat_list.push(obj.SUB_CATEGORY);
            });
            // setCategoryList([...new Set(cat_list)]);
            // setSubCategoryList([...new Set(sub_cat_list)]);
          } else {
            // setCategoryList([]);
            // setSubCategoryList([]);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getSubCategories();
  }, []);

  const handleValidFileValidations = (rows) => {
    if (rows?.length > CSVFileColumns?.length) {
      setFileError("You have added more columns than the specified one");
    } else if (rows?.length < CSVFileColumns?.length) {
      setFileError("You have added less columns than the specified one");
    } else if (rows?.length > 0) {
      const equalColumns =
        JSON.stringify(CSVFileColumns) === JSON.stringify(rows);
      equalColumns
        ? setFileError("")
        : setFileError("Please add proper template file");
    } else {
      setFileError("Please Upload correct CSV/XLSX File");
    }
  };

  const uploadFile = (event) => {
    var fileInput = document.getElementById("myFileInput");
    var file = fileInput?.files[0];
    setFile(file);
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (fileExtension === "csv") {
        Papa.parse(file, {
          complete: function (results) {
            const jsonData = results?.data;
            // Assuming the first row contains the column names
            // 2 means the third row in the file(Column Names)
            const headers = jsonData[2];

            // Transform jsonData into an array of objects with key-value pairs
            // slice first 3 elements and get the data
            const parsedData = jsonData
              ?.slice(3)
              .map((row) =>
                row.reduce((obj, value, columnIndex) => {
                  obj[headers[columnIndex]] = value;
                  return obj;
                }, {})
              )
              ?.map((obj) => {
                if (!obj.hasOwnProperty("Sub Category2")) {
                  obj["Sub Category2"] = null;
                }
                return obj;
              });

            handleValidFileValidations(headers);
            setFileUploaded(true);
            let fileErrorPresent = false;
            if (parsedData?.length > 0) {
              parsedData?.forEach((obj) => {
                // Code for checking Categories and Sub Catagories...
                // if (!categoryList?.includes(obj?.Category)) {
                //   setFileError(
                //     "The Category you have entered which is not present. Please download the template file."
                //   );
                //   fileErrorPresent = true;
                // } else if (!subCategoryList?.includes(obj["Sub Category"])) {
                //   setFileError(
                //     "The Sub Category you have entered which is not present. Please download the template file."
                //   );
                //   fileErrorPresent = true;
                // }
                // code for checking the extra or less values in columns rows...
                if (Object.keys(obj).length < CSVFileColumns?.length) {
                  setFileError(
                    "The file which you have uploaded doesn't have the values in some Columns"
                  );
                  fileErrorPresent = true;
                } else if (Object.keys(obj).length > CSVFileColumns?.length) {
                  setFileError(
                    "You have added more column values than the specified one"
                  );
                  fileErrorPresent = true;
                }
              });

              if (!fileErrorPresent) {
                setParsedData(parsedData);
              }
            } else {
              setFileError(
                "The file which you have uploaded doesn't have the data"
              );
            }
          },
        });
      } else if (fileExtension === "xlsx") {
        // Handle XLSX file
        const reader = new FileReader();
        reader.onload = () => {
          const arrayBuffer = reader.result;
          const workbook = read(arrayBuffer, { type: "array" });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = utils
            .sheet_to_json(worksheet, { header: 1, raw: true })
            ?.filter((row) => Object.keys(row).length > 0);

          // Assuming the first row contains the column names
          // 2 means the third row in the file(Column Names)
          const headers = jsonData[2];

          // Transform jsonData into an array of objects with key-value pairs
          // slice first 3 elements and get the data
          let parsedData = jsonData
            ?.slice(3)
            .map((row) =>
              row.reduce((obj, value, columnIndex) => {
                obj[headers[columnIndex]] = value;
                return obj;
              }, {})
            )
            ?.map((obj) => {
              if (!obj.hasOwnProperty("Sub Category2")) {
                obj["Sub Category2"] = null;
              }
              return obj;
            });

          handleValidFileValidations(headers);
          setFileUploaded(true);
          let fileErrorPresent = false;
          if (parsedData?.length > 0) {
            parsedData?.forEach((obj) => {
              // Code for checking Categories and Sub Catagories...
              // if (!categoryList?.includes(obj?.Category)) {
              //   setFileError(
              //     "The Category you have entered which is not present. Please download the template file."
              //   );
              //   fileErrorPresent = true;
              // } else if (!subCategoryList?.includes(obj["Sub Category"])) {
              //   setFileError(
              //     "The Sub Category you have entered which is not present. Please download the template file."
              //   );
              //   fileErrorPresent = true;
              // }
              // code for checking the extra or less values in columns rows...

              if (Object.keys(obj).length < CSVFileColumns?.length) {
                setFileError(
                  "The file which you have uploaded doesn't have the values in some Columns"
                );
                fileErrorPresent = true;
              } else if (Object.keys(obj).length > CSVFileColumns?.length) {
                setFileError(
                  "You have added more column values than the specified one"
                );
                fileErrorPresent = true;
              }
            });

            if (!fileErrorPresent) {
              setParsedData(parsedData);
            }
          } else {
            setFileError(
              "The file which you have uploaded doesn't have the data"
            );
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        setFileUploaded(true);
        setFileError("Invalid file type. Only CSV and XLSX files are allowed.");
      }
    }
  };

  const handleSubmit = async () => {
    if (!fileUploaded) {
      setFileError("Please upload a file...");
      return;
    } else if (fileError !== "") {
      return;
    } else {
      setLoader(true);

      // Upload file in Local uploadedFiles folder..
      const run_id = Date.now();
      const fileName = `${
        run_id + "_" + user?.account_name + "." + file?.name?.split(".")[1]
      }`;
      const modifiedFile = new File([file], fileName, {
        type: file?.type,
      });
      setFile(modifiedFile);
      const localFile = new FormData();

      localFile.append("myFile", modifiedFile);

      axios
        .post(`${nodeURL}/api/localFileUpload`, localFile, {
          headers: {
            "content-type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then(async (response) => {
          if (parseInt(response?.status) === 200) {
            const payload = {
              account_name: redirectionUser,
              file_name: fileName,
            };
            try {
              const response = await API.ValidateCatalogue(payload);
              if (response.status === 200 && response?.data?.data === "TRUE") {
                let EntityArray = [];
                let finalObject = {};
                parsedData = parsedData?.map((item) => {
                  return { ...item, tag: "insert" };
                });
                let entities = parsedData?.map((item) => {
                  return item.Entity;
                });
                entities = [...new Set(entities)];
                entities.map((item) => {
                  let attributes_temp = [];
                  let obj = {};
                  parsedData.filter((obj1) => {
                    if (item === obj1.Entity) {
                      attributes_temp.push(obj1);
                    }
                    return null;
                  });
                  attributes_temp?.map((obj) => {
                    return delete obj.Entity;
                  });
                  obj.attributes = attributes_temp;
                  obj.name = item;
                  EntityArray.push(obj);
                  return null;
                });
                finalObject.entity = EntityArray;
                finalObject.name = user?.account_name;
                let result = JSON.stringify(finalObject);
                const payload = {
                  account_name: redirectionUser,
                  result: result,
                };
                try {
                  const response = await API.insertUpdateCatalogue(payload);
                  if (response.status === 200) {
                    callProcedure();
                  } else {
                    setLoader(false);
                  }
                } catch (error) {
                  setLoader(false);
                  console.error("Error fetching data:", error);
                }
              } else {
                setLoader(false);
                setFileError(
                  "Something is wrong in your file. Please check Sub Category1 and Sub Category2 again"
                );
              }
            } catch (error) {
              setLoader(false);
              console.error("Error fetching data:", error);
            }
          } else {
            toast.error("Something went wrong, please try again later !!!");
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.error("Something went wrong, please try again later !!!");
          // setErrorMessage("Something went wrong, please try again later !!!");
          console.error("Error fetching data:", error);
        });
    }
  };

  const callProcedure = async () => {
    setNewCatUploaded(false);

    const payload = {
      account_name: redirectionUser,
    };
    try {
      const response = await API.procedureInsertCatalogue(payload);
      if (response.status === 200) {
        setLoader(false);
        close();
        toast.success("File Uploaded successfully");
        setNewCatUploaded(true);
      } else {
        setLoader(false);
        close();
        toast.error("It seems that there is an issue while uploading a file");
      }
    } catch (error) {
      setLoader(false);
      close();
      toast.error("It seems that there is an issue while uploading a file");
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        className="bg-white bg-opacity-75 backdrop-filter backdrop-blur-lg "
      >
        <div className="flex flex-col w-full">
          <div className="flex flex-row items-center justify-between sticky z-30 py-2 top-0 w-full">
            <h3 className="text-lg font-bold text-deep-navy">
              Create New Catalog
            </h3>
            <button onClick={close}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
              </svg>
            </button>
          </div>
          <div className="flex flex-col flex-shrink h-auto text-deep-navy">
            <div
              className=" border border-gray-400 rounded my-4 px-4 py-2 h-auto w-[32rem] max-w-3xl"
              name="myForm"
            >
              <div className="mt-2 pb-21 flex flex-col">
                <label>Download New Template File</label>
                <button
                  onClick={downloadNewFile}
                  className="flex flex-row text-[#0000FF]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                    />
                  </svg>
                  <span className="pl-2 underline">Download</span>
                </button>
              </div>

              <div className="mt-2 pb-21 flex flex-col">
                <label>Upload File</label>
                <input
                  className="w-3/5 "
                  type="file"
                  id="myFileInput"
                  onChange={uploadFile}
                  required
                />
              </div>
              {fileError !== "" ? (
                <div className="mt-2 pb-21 flex flex-col text-sm text-[red]">
                  <label>{fileError}</label>
                </div>
              ) : null}
              <div className="flex justify-center">
                {loader ? (
                  <div className="my-2 flex w-3/5 justify-center rounded-md bg-deep-navy px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-electric-green hover:text-deep-navy focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-green">
                    <CircularProgress
                      style={{
                        width: "24px",
                        height: "24px",
                        color: "#FFFFFF",
                      }}
                    />
                  </div>
                ) : (
                  <button
                    onClick={handleSubmit}
                    type="submit"
                    className="my-2 flex w-3/5 justify-center rounded-md bg-deep-navy px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-electric-green hover:text-deep-navy focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-green"
                  >
                    Submit New Catalog
                  </button>
                )}
              </div>
            </div>
            <div className="text-red-600 text-sm">
              * Please do not perform any format changes in the template!!!
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default NewCatalogUploadModal;
