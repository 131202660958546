export const account_type_list = [
  {
    name: "Admin",
    value: "dataexadmin",
  },
  {
    name: "Data Partner",
    value: "Provider",
  },
  {
    name: "Advertiser",
    value: "Consumer",
  },
];

//
export const account_roles = {
  Consumer: [
    {
      name: "Basic Advertiser",
      value: "Publisher",
    },
    {
      name: "Advanced Advertiser",
      value: "Consumer",
    },
  ],
  Provider: [
    {
      name: "Data Partner",
      value: "Provider",
    },
  ],
  dataexadmin: [
    {
      name: "Dataexadmin",
      value: "Dataexadmin",
    },
  ],
};

// Tabs for Admin Console for Consumer...

export const adminConsumerConsoleTabs = [
  { name: "profile", tabTitle: "PROFILES" },
  {
    name: "itemised_bills",
    tabTitle: "ITEMISED BILLS",
  },
];

export const adminConsumerPublisherConsoleTabs = [
  { name: "profile", tabTitle: "PROFILES" },
];

// Tabs for Admin Console for Provider...

export const adminProviderConsoleTabs = [
  { name: "profile", tabTitle: "PROFILES" },
  {
    name: "query_template",
    tabTitle: "CONFIGURE QUERY TEMPLATES",
  },
  {
    name: "allowed_columns",
    tabTitle: "CONFIGURE ALLOWED COLUMNS",
  },
  {
    name: "itemised_bills",
    tabTitle: "ITEMISED BILLS",
  },
];

export const latestPartners = [
  {
    title: "Acme Media Ltd.",
    recordMatched: "1,00,000 records matched",
    role: "Provider",
    templates: ["Match rate", "Enrichment"],
  },
  {
    title: "Acme Pvt Ltd.",
    recordMatched: "1,34,000 records matched",
    role: "Consumer",
    templates: ["Enrichment"],
  },
  {
    title: "Acme Solutions",
    recordMatched: "1,23,000 records matched",
    role: "Provider",
    templates: ["Match rate"],
  },
  {
    title: "Hoonartek Solutions",
    recordMatched: "1,89,000 records matched",
    role: "Admin",
    templates: ["Match rate", "Enrichment", "Console"],
  },
  {
    title: "Acme Solutions",
    recordMatched: "1,23,000 records matched",
    role: "Provider",
    templates: ["Match rate", "Enrichment", "Search Catalog", "Admin Console"],
  },
  {
    title: "Acme Pvt Ltd.",
    recordMatched: "1,34,000 records matched",
    role: "Consumer",
    templates: ["Enrichment"],
  },
];
