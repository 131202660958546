
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGOUT_USER = 'LOGOUT_USER';
export const HOME = 'HOME';

export const CONSUMER_FORM_TABLE_DATA = 'CONSUMER_FORM_TABLE_DATA';

export const PUBLISHER_FORM_TABLE_DATA = 'PUBLISHER_FORM_TABLE_DATA';


