import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

import CommonModal from "../../CommonComponent/Modal";
import Allowed_Columns_Image from "../../../Assets/AdminConsole/admin_console_allowed_columns.svg";
import API from "../../../apiServices/api";

const AllowedColumns = ({ user, handleToggleDrawer }) => {
  const [publisherData, setPublisherData] = useState({
    consumer: "",
    template: "",
    column_name: "",
    status: "",
  });

  const [consumers, setConsumers] = useState([]);
  const [templateNames, setTemplateNames] = useState([]);
  const [allColumns, setAllColumns] = useState([]);
  const [allowedColumns, setAllowedColumns] = useState([]);

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(!openModal);
  };


  useEffect(() => {
    const getConsumerName = async () => {
      const payload = {
        account_name: user?.account_name,
        provider_database_name: user?.providerDBName,
      };
      try {
        const response = await API.getConsumerName(payload);
        if (response.status === 200 && response?.data?.data) {
          setConsumers(response?.data?.data);
        } else {
          setConsumers([]);
        }
      } catch (error) {
        setConsumers([]);
        console.error("Error fetching data:", error);
      }
    };
    getConsumerName();
  }, [user]);

  //   UseEffect for Template List....

  useEffect(() => {
    if (publisherData?.consumer !== "") {
      const getTemplates = async () => {
        const payload = {
          account_name: user?.account_name,
          provider_database_name: user?.providerDBName,
          user: publisherData?.consumer,
        };
        try {
          const response = await API.getTemplates(payload);
          if (response.status === 200 && response?.data?.data) {
            setTemplateNames(response?.data?.data);
          } else {
            setTemplateNames([]);
          }
        } catch (error) {
          setTemplateNames([]);
          console.error("Error fetching data:", error);
        }
      };
      getTemplates();
    }
  }, [user, publisherData?.consumer]);

  //   UseEffect for All Columns and Allowed columns list....

  useEffect(() => {
    if (publisherData.consumer !== "" && publisherData.template !== "") {
      const getAllowedColumns = async () => {
        const payload = {
          account_name: user?.account_name,
          provider_database_name: user?.providerDBName,
          user: publisherData?.consumer,
          template_name: publisherData.template,
        };
        // For Allowed columns
        try {
          const response = await API.getAllowedColumnsConsole(payload);
          if (response.status === 200 && response?.data?.data) {
            let data = response?.data?.data;
            let allowed_columns = data[0]?.ALLOWED_COLUMNS?.split("|");
            allowed_columns = allowed_columns?.map((item) => {
              return item?.split(".")[1];
            });
            setAllowedColumns(allowed_columns);
          } else {
            setAllowedColumns([]);
          }
        } catch (error) {
          setAllowedColumns([]);
          console.error("Error fetching data:", error);
        }
        // For All columns
        try {
          const response = await API.getAllColumnsConsole(payload);
          if (response.status === 200 && response?.data?.data) {
            let data = response?.data?.data;
            let all_columns = data[0]?.ALL_COLUMNS?.split(",");
            all_columns = all_columns?.map((item) => {
              return item;
            });
            setAllColumns(all_columns);
          } else {
            setAllColumns([]);
          }
        } catch (error) {
          setAllColumns([]);
          console.error("Error fetching data:", error);
        }
      };
      getAllowedColumns();
    }
  }, [
    publisherData.consumer,
    publisherData.template,
    user?.account_name,
    user?.providerDBName,
  ]);

  //   useEffect for Status buttton Enable/Disable....
  useEffect(() => {
    if (
      publisherData.consumer !== "" &&
      publisherData.template !== "" &&
      publisherData.column_name !== ""
    ) {
      const getButtonStatus = async () => {
        const payload = {
          account_name: user?.account_name,
          provider_database_name: user?.providerDBName,
          user: publisherData?.consumer,
          template_name: publisherData.template,
          column_name: publisherData.column_name,
        };
        try {
          const response = await API.fetchAllowedColumnStatus(payload);
          if (response.status === 200 && response?.data?.data) {
            let data = response?.data?.data;
            setPublisherData({
              ...publisherData,
              status: parseInt(Object.values(data[0])) === 1 ? true : false,
            });
          } else {
            setPublisherData({ ...publisherData, status: "" });
          }
        } catch (error) {
          setPublisherData({ ...publisherData, status: "" });
          console.error("Error fetching data:", error);
        }
      };
      getButtonStatus();
    } else {
      setPublisherData({ ...publisherData, status: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    publisherData.consumer,
    publisherData.template,
    publisherData.column_name,
  ]);

  const handleClickYes = async () => {
    setOpenModal(!openModal);
    setLoading(true);
    const payload = {
      account_name: user?.account_name,
      db_name: user?.providerDBName,
      result: JSON.stringify({
        Consumer_Name: publisherData?.consumer,
        Template_Name: publisherData?.template,
        column_name: publisherData?.column_name,
        Tag: publisherData?.status === true ? "remove" : "add",
      }),
    };
    try {
      const response = await API.updateAllowedColumns(payload);
      if (response.status === 200) {
        callByPass();
      } else {
        toast.error("We are fetching some error for Updating columns status!!");
      }
    } catch (error) {
      toast.error("We are fetching some error for Updating columns status!!");
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = () => {
    if (
      publisherData.consumer === "" ||
      publisherData.template === "" ||
      publisherData.column_name === ""
    ) {
      return;
    } else {
      setMessage(
        `Are you sure, you want to ${
          publisherData.status === true ? "Remove" : "Add"
        } this Column?`
      );
      setOpenModal(!openModal);
    }
  };

  const callByPass = async () => {
    setTimeout(async () => {
      const payload = {
        account_name: user?.account_name,
        provider_database_name: user?.providerDBName,
      };
      try {
        const response = await API.procedureUpdateAllowedColumns(payload);
        if (response.status === 200) {
          setLoading(false);
          setPublisherData({
            consumer: "",
            template: "",
            column_name: "",
            status: "",
          });
          toast.success("Successfully updated column");
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    }, 2000);
  };

  return (
    <div className="w-96">
      <div className="pt-8 bg-opacity-75 backdrop-filter backdrop-blur-lg">
        <div className="flex flex-row items-start text-electric-green">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 mt-1 mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
            />
          </svg>
          <div className="flex flex-col w-full">
            <div className="flex justify-between">
              <h3 className="text-lg font-bold text-electric-green lowercase">
                CONFIGURE ATTRIBUTES
              </h3>
              <button onClick={handleToggleDrawer("right", false, "")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <span className="text-sm mb-4 font-light text-electric-green">
              Add/Remove Attributes for particular advertiser.
            </span>
          </div>
        </div>
      </div>

      <img
        className="absolute w-96 h-48 bottom-5 opacity-90 z-10 right-0 text-amarant-400"
        src={Allowed_Columns_Image}
        alt=""
      />
      <div className="pl-8 relative z-20">
        <div className="my-4 flex flex-col">
          <label className="block text-sm font-medium leading-6 text-electric-green ">
            Advertiser Name
          </label>
          <select
            value={publisherData.consumer}
            onChange={(e) =>
              setPublisherData({
                ...publisherData,
                consumer: e.target.value,
              })
            }
            required
            className="bg-deep-navy block w-full rounded-md border-0 py-1.5 text-[#FFFFFF] shadow-sm ring-1 ring-inset ring-[#00FFB4]  placeholder:text-electric-green  focus:ring-2 focus:ring-inset focus:ring-electric-green  sm:text-sm sm:leading-6"
          >
            <option value="">please select</option>
            {consumers?.map((consumer, index) => (
              <option key={index} value={consumer?.CONSUMER_NAME}>
                {consumer?.CONSUMER_NAME}
              </option>
            ))}
          </select>
        </div>

        <div className="my-4 flex flex-col">
          <label className="block text-sm font-medium leading-6 text-electric-green ">
            template name
          </label>
          <select
            value={publisherData.template}
            onChange={(e) =>
              setPublisherData({
                ...publisherData,
                template: e.target.value,
              })
            }
            required
            className="bg-deep-navy block w-full rounded-md border-0 py-1.5 text-[#FFFFFF] shadow-sm ring-1 ring-inset ring-[#00FFB4]  placeholder:text-electric-green  focus:ring-2 focus:ring-inset focus:ring-electric-green  sm:text-sm sm:leading-6"
          >
            <option value="">please select</option>
            {templateNames?.map((template, index) => (
              <option key={index} value={template?.TEMPLATE_NAME}>
                {template?.TEMPLATE_NAME}
              </option>
            ))}
          </select>
        </div>

        <div className="my-4 flex flex-col">
          <label className="block text-sm font-medium leading-6 text-electric-green ">
            Attributes
          </label>
          <select
            value={publisherData.column_name}
            onChange={(e) =>
              setPublisherData({
                ...publisherData,
                column_name: e.target.value,
              })
            }
            required
            className="bg-deep-navy block w-full rounded-md border-0 py-1.5 text-[#FFFFFF] shadow-sm ring-1 ring-inset ring-[#00FFB4]  placeholder:text-electric-green  focus:ring-2 focus:ring-inset focus:ring-electric-green  sm:text-sm sm:leading-6"
          >
            <option value="">please select</option>
            {allColumns?.map((column, index) => (
              <option key={index} value={column}>
                {column.toUpperCase()}
                {"       "}
                {allowedColumns.includes(column) ? "✓" : "✗"}
              </option>
            ))}
          </select>
        </div>

        {publisherData.status !== "" ? (
          <div className="flex justify-end">
            {loading ? (
              <div className="flex w-full justify-center rounded-md bg-electric-green px-3 py-1.5 text-sm font-semibold leading-6 text-deep-navy shadow-sm hover:bg-true-teal focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-green my-4">
                <CircularProgress
                  style={{
                    width: "24px",
                    height: "24px",
                    color: "#000000",
                  }}
                />
              </div>
            ) : (
              <button
                className="flex w-full justify-center rounded-md bg-electric-green px-3 py-1.5 text-sm font-semibold leading-6 text-deep-navy shadow-sm hover:bg-true-teal focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-green mt-4"
                type="submit"
                onClick={handleSubmit}
              >
                {`${publisherData.status === true ? "remove" : "add"}`}
              </button>
            )}
          </div>
        ) : null}
        <CommonModal
          open={openModal}
          handleClose={handleCloseModal}
          handleClickYes={handleClickYes}
          message={message}
          buttons={true}
        />
      </div>
    </div>
  );
};

export default AllowedColumns;
