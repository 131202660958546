import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { CircularProgress, SwipeableDrawer } from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
} from "@mui/material";

import CommonTable from "./CommonComponent/Table";
import SelectDropdown from "./CommonComponent/SelectDropdown";
import searchillustration from "../Assets/search_illustration.svg";
import Spinner from "./CommonComponent/Spinner";
// import IntegrationImage from "../Assets/integration.png";
import API from "../apiServices/api";

const redirectionUser = process.env.REACT_APP_REDIRECTION_URL;

const initialFilters = {
  category: [],
  subCategory1: [],
  subCategory2: [],
  provider: [],
};

const SearchCatalog = () => {
  const state = useSelector((state) => state);
  const user = state && state.user;
  const location = useLocation();
  const provider_name =
    new URLSearchParams(location.search)?.get("ProviderName") || null;

  // const [consumerSorceTable, setConsumerSorceTable] = useState("");

  const [selectedValues, setSelectedValues] = useState(initialFilters);

  const [loader, setLoader] = useState(false);
  // const [integrating, setIntegrating] = useState(false);
  // const [integratingRows, setIntegratingRows] = useState([]);
  // const [integratedProviders, setIntegratedProviders] = useState([]);

  const [loadingTable, setLoadingTable] = useState(true);

  const [data, setData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategory1List, setSubCategory1List] = useState([]);
  const [subCategory2List, setSubCategory2List] = useState([]);

  const [providerList, setProviderList] = useState([]);
  const [viewTable, setViewTable] = useState({
    head: [],
    rows: [],
  });
  const [resetFilter, setResetFilter] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [sortOrder, setSortOrder] = useState("asc"); // 'asc' or 'desc'
  const [sortedColumn, setSortedColumn] = useState(null);

  // result model
  const [isResultModalOpen, toggleResultModal] = React.useState(false);
  const handleResultModalClose = () => toggleResultModal(false);

  const [errors, setError] = useState({
    category: null,
    subCategory1: null,
    subCategory2: null,
    provider: null,
  });

  const [toggleDrawerPosition, setToggleDrawerPosition] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    search: false,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setToggleDrawerPosition({ ...toggleDrawerPosition, [anchor]: open });
  };

  const handleResetFilter = () => {
    getIntegratedProviders();
    setLoadingTable(true);
    setResetFilter(false);
    setSelectedValues(initialFilters);
  };

  const getIntegratedProviders = async () => {
    const payload = {
      account_name: redirectionUser,
      CONSUMER_NAME: user?.account_name,
    };
    try {
      const response = await API.getAllCatalog(payload);
      if (response.status === 200 && response?.data?.data) {
        let data = response?.data?.data;
        if (provider_name !== null) {
          let filteredData = data?.filter(
            (item) => item.PROVIDER_NAME === provider_name
          );
          setData(filteredData);
        } else {
          setData(data);
        }
        setLoadingTable(false);

        // try {
        //   const response = await API.getIntegratedProviders(payload);
        //   if (response.status === 200 && response?.data?.data) {
        //     setIntegratedProviders(response?.data?.data[0]?.PROVIDER_NAME);
        //   }
        // } catch (error) {
        //   console.error("Error fetching data:", error);
        // }
      } else {
        setLoadingTable(false);
      }
    } catch (error) {
      setLoadingTable(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLoadingTable(true);
    getIntegratedProviders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.name]);

  // //useEffect for finding the Consumers Source Table...
  // useEffect(() => {
  //   const getConsumerSourceTable = async () => {
  //     const payload = {
  //       account_name: user?.account_name,
  //       db_name: user?.consumerDBName,
  //     };
  //     try {
  //       const response = await API.getConsumerSourceTable(payload);
  //       if (response.status === 200 && response?.data?.data) {
  //         let data = response?.data?.data;
  //         setConsumerSorceTable(data[0]?.SETTING_VALUE);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  //   getConsumerSourceTable();
  // }, [user?.consumerDBName, user?.account_name]);

  // useEffect for fetching a Category or Provider
  useEffect(() => {
    const fetchCategories = () => {
      const cat_list = [
        { value: "all", name: "All" },
        ...[...new Set(data.map((item) => item.CATEGORY))].map((category) => ({
          value: category,
          name: category,
        })),
      ];
      setCategoryList(cat_list);

      const prov_list = [
        { value: "all", name: "All" },
        ...[...new Set(data.map((item) => item.PROVIDER_NAME))].map(
          (provider) => ({
            value: provider,
            name: provider,
          })
        ),
      ];
      setProviderList(prov_list);
    };

    if (toggleDrawerPosition.right) {
      fetchCategories();
    }
  }, [toggleDrawerPosition.right, data]);
  // useEffect for fetching a Sub_category1
  useEffect(() => {
    if (selectedValues.category?.length > 0 && data?.length > 0) {
      const sub_cat_list1 = [{ value: "all", name: "All" }];
      const allCategoriesSelected = selectedValues.category.includes("all");
      data.forEach((obj) => {
        if (
          allCategoriesSelected ||
          selectedValues.category.includes(obj.CATEGORY)
        ) {
          if (obj.SUB_CATEGORY1 !== null && obj.SUB_CATEGORY1 !== "") {
            sub_cat_list1.push({
              value: obj.SUB_CATEGORY1,
              name: obj.SUB_CATEGORY1,
            });
          }
        }
      });

      const seen = [];
      setSubCategory1List(
        sub_cat_list1?.filter((obj) => {
          const key = JSON.stringify(obj);
          const isNew = !seen.includes(key);
          if (isNew) {
            seen.push(key);
          }
          return isNew;
        })
      );
    } else {
      setSubCategory1List([]);
    }
  }, [selectedValues.category, data]);

  // useEffect for fetching a Sub_category2

  useEffect(() => {
    if (selectedValues.subCategory1?.length > 0 && data?.length > 0) {
      const allCategoriesSelected = selectedValues.category.includes("all");
      const allSubCategory1Selected =
        selectedValues.subCategory1.includes("all");
      const filteredData = data.filter((obj) => {
        const isCategoryMatch =
          allCategoriesSelected ||
          selectedValues.category.includes(obj.CATEGORY);
        const isSubCategory1Match =
          allSubCategory1Selected ||
          selectedValues.subCategory1.includes(obj.SUB_CATEGORY1);
        return isCategoryMatch && isSubCategory1Match;
      });
      // Initialize sub_cat_list2 with the "All" option
      const sub_cat_list2 = [
        { value: "all", name: "All" },
        ...filteredData
          .filter(
            (obj) => obj.SUB_CATEGORY2 !== null && obj.SUB_CATEGORY2 !== ""
          )
          .map((obj) => ({
            value: obj.SUB_CATEGORY2,
            name: obj.SUB_CATEGORY2,
          })),
      ];

      const seen2 = [];
      setSubCategory2List(
        sub_cat_list2?.filter((obj) => {
          const key = JSON.stringify(obj);
          const isNew = !seen2.includes(key);
          if (isNew) {
            seen2.push(key);
          }
          return isNew;
        })
      );
    } else {
      setSubCategory2List([]);
    }
  }, [selectedValues.category, selectedValues.subCategory1, data]);

  // useEffect(() => {
  //   if (toggleDrawerPosition.right) {
  //     const getInfo = async () => {
  //       const payload = {
  //         account_name: redirectionUser,
  //       };
  //       // select distinct * from DATAEXCHANGEDB.DATACATALOG.CATEGORY_LIST
  //       try {
  //         const response = await API.getCategoriesList(payload);
  //         if (response.status === 200 && response?.data?.data) {
  //           const cat_list = [{ value: "all", name: "All" }];
  //           response?.data?.data?.forEach((obj) => {
  //             cat_list.push({ value: obj.CATEGORY, name: obj.CATEGORY });
  //           });
  //           setCategoryList(cat_list);
  //         }
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //       // SELECT distinct provider_name from DATAEXCHANGEDB.DATACATALOG.PROVIDER_VW;
  //       try {
  //         const response = await API.getProviderNameSearch(payload);
  //         if (response.status === 200 && response?.data?.data) {
  //           const prov_list = [{ value: "all", name: "All" }];
  //           response?.data?.data?.forEach((obj) => {
  //             prov_list.push({
  //               value: obj.PROVIDER_NAME,
  //               name: obj.PROVIDER_NAME,
  //             });
  //           });
  //           setProviderList(prov_list);
  //         }
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     };
  //     getInfo();
  //   }
  // }, [toggleDrawerPosition.right]);

  // useEffect(() => {
  //   if (selectedValues.category?.length > 0) {
  //     const getAllSubcategories = async () => {
  //       const payload = {
  //         account_name: redirectionUser,
  //         categories: selectedValues?.category?.join(","),
  //       };
  //       try {
  //         const response = await API.getSubCategoriesSearch(payload);
  //         if (response.status === 200 && response?.data?.data) {
  //           let data = response?.data?.data;
  //           if (data?.length > 0) {
  //             const sub_cat_list1 = [{ value: "all", name: "All" }];
  //             const sub_cat_list2 = [{ value: "all", name: "All" }];

  //             response?.data?.data?.forEach((obj) => {
  //               if (obj.SUB_CATEGORY1 !== null) {
  //                 sub_cat_list1.push({
  //                   value: obj.SUB_CATEGORY1,
  //                   name: obj.SUB_CATEGORY1,
  //                 });
  //               }
  //               if (obj.SUB_CATEGORY2 !== null) {
  //                 sub_cat_list2.push({
  //                   value: obj.SUB_CATEGORY2,
  //                   name: obj.SUB_CATEGORY2,
  //                 });
  //               }
  //             });
  //             const seen = new Set();

  //             setSubCategory1List(
  //               sub_cat_list1?.filter((obj) => {
  //                 const key = JSON.stringify(obj);
  //                 const isNew = !seen.has(key);
  //                 if (isNew) {
  //                   seen.add(key);
  //                 }
  //                 return isNew;
  //               })
  //             );
  //             setSubCategory2List(sub_cat_list2);
  //           } else {
  //             setSubCategory1List([]);
  //             setSubCategory2List([]);
  //           }
  //         } else {
  //           setSubCategory1List([]);
  //           setSubCategory2List([]);
  //         }
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     };
  //     getAllSubcategories();
  //   }
  // }, [selectedValues.category]);

  const handleChange = (event, name) => {
    if (name === "category") {
      setError({ ...errors, category: null });
    } else if (name === "subCategory1") {
      setError({ ...errors, subCategory1: null });
    } else if (name === "subCategory2") {
      setError({ ...errors, subCategory2: null });
    } else if (name === "provider") {
      setError({ ...errors, provider: null });
    }
    if (name === "category") {
      setSelectedValues({
        ...selectedValues,
        [name]: event,
        subCategory1: [],
      });
    } else if (name === "subCategory1") {
      setSelectedValues({
        ...selectedValues,
        [name]: event,
        subCategory2: [],
      });
    } else {
      setSelectedValues({
        ...selectedValues,
        [name]: event,
      });
    }
  };

  const handleSubmit = async (anchor) => {
    if (selectedValues.category?.length === 0) {
      setError({ ...errors, category: "please select Category" });
      return;
    } else if (selectedValues.subCategory1?.length === 0) {
      setError({ ...errors, subCategory1: "please select Sub Category1" });
      return;
    } else if (selectedValues.subCategory2?.length === 0) {
      setError({ ...errors, subCategory2: "please select Sub Category2" });
      return;
    } else if (selectedValues.provider?.length === 0) {
      setError({ ...errors, provider: "please select Provider" });
      return;
    }

    setLoadingTable(true);
    setToggleDrawerPosition({ ...toggleDrawerPosition, [anchor]: false });
    setPage(0);

    const payload = {
      account_name: redirectionUser,
      categories: selectedValues?.category?.join(","),
      subCategories1: selectedValues?.subCategory1?.join(","),
      subCategories2: selectedValues?.subCategory2?.join(","),
      providers: selectedValues?.provider?.join(","),
    };
    try {
      const response = await API.filterSearchTable(payload);
      if (response.status === 200 && response?.data?.data) {
        setData(response?.data?.data);
        setLoadingTable(false);
        setResetFilter(true);
      } else {
        setLoadingTable(false);
      }
    } catch (error) {
      setLoadingTable(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchcsvTableData = async (providerName, entity) => {
    setViewTable({ ...viewTable, head: [], rows: [] });
    toggleResultModal(true);

    const payload = {
      account_name: redirectionUser,
      provider_name: providerName,
      entity_name: entity,
    };
    try {
      const response = await API.viewProvidersCatalogue(payload);
      if (response.status === 200 && response?.data?.data) {
        fetchTable(response?.data?.data);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchTable = (data) => {
    let head = [
      "PROVIDER",
      "ENTITY",
      "ATTRIBUTE",
      "CATEGORY",
      "SUBCATEGORY1",
      "SUBCATEGORY2",
      "DESCRIPTION",
    ];
    // let head = [];
    let row = [];
    if (data?.length > 0) {
      // head = data && Object.keys(data[0]);
      data?.map((obj) => {
        return row.push(head?.map((key) => obj[key]));
      });
    }
    setViewTable({ ...viewTable, head: head, rows: row });
  };

  // const fetchSampleProviderData = async (providerName, entity) => {
  //   setViewTable({ ...viewTable, head: [], rows: [] });
  //   toggleResultModal(true);

  //   try {
  //     const payload = {
  //       account_name: user?.account_name,
  //       db_name: user?.consumerDBName,
  //       provider_name: providerName,
  //     };

  //     const response = await API.getProviderAccount(payload);
  //     if (
  //       response.status === 200 &&
  //       response?.data?.data?.length > 0 &&
  //       response?.data?.data[0]?.PROV_ACCT_IDENTIFIER
  //     ) {
  //       let providerAccIdentifier =
  //         response?.data?.data[0]?.PROV_ACCT_IDENTIFIER;

  //       const payload = {
  //         account_name: providerName,
  //         provider_name: `DCR_${providerAccIdentifier}_PROVIDER_DB`,
  //         entity_name: entity,
  //       };

  //       try {
  //         const response = await API.sampleProviderData(payload);
  //         if (response.status === 200 && response?.data?.data) {
  //           let result = response?.data?.data;
  //           let row = [];
  //           if (result?.length > 0) {
  //             const allKeys = result?.reduce((keys, obj) => {
  //               return keys.concat(Object.keys(obj));
  //             }, []);
  //             const head = Array.from(new Set(allKeys));
  //             result?.forEach((obj) => {
  //               return row.push(head.map((key) => obj[key]));
  //             });
  //             setViewTable({ ...viewTable, head: head, rows: row });
  //           }
  //         } else {
  //           setLoader(false);
  //         }
  //       } catch (error) {
  //         setLoader(false);
  //         console.error("Error fetching data:", error);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // const handleIntegration = async (provider_name, entity_name) => {
  //   setIntegratingRows((prevRows) => [
  //     ...prevRows,
  //     `${provider_name}-${entity_name}`,
  //   ]);
  //   // setIntegrating(true);

  //   const payload = {
  //     account_name: redirectionUser,
  //     CONSUMER_NAME: user?.account_name,
  //     provider_name: provider_name,
  //     CONSUMER_ACCOUNT: user?.ConsumerPartyAccount,
  //     PROVIDER_ACCOUNT: null,
  //     provider_source_table: entity_name,
  //     consumer_source_table: consumerSorceTable,
  //   };

  //   try {
  //     const response = await API.getPartyAccount(payload);
  //     if (response.status === 200 && response?.data?.data) {
  //       let data = response?.data?.data;
  //       let providerPartyAccount = data[0]?.PARTY_ACCOUNT;

  //       payload.PROVIDER_ACCOUNT = providerPartyAccount;

  //       try {
  //         const response = API.integrateConsumer(payload);
  //         if (response.status === 200) {
  //           try {
  //             const response = API.insertIntegrateConsumer(payload);
  //             if (response.status === 200 && response?.data?.data) {
  //               console.log("response?.data?.data", response?.data?.data);
  //               // setIntegrating(false);
  //             }
  //           } catch (error) {
  //             setLoader(false);
  //             console.error("Error fetching data:", error);
  //           }
  //         }
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     } else {
  //       setLoader(false);
  //     }
  //   } catch (error) {
  //     setLoader(false);
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const handleSort = (columnKey) => {
    if (sortedColumn === columnKey) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortOrder("asc");
      setSortedColumn(columnKey);
    }
  };

  const sortedData = [...data].sort((a, b) => {
    const aValue = a[sortedColumn];
    const bValue = b[sortedColumn];

    if (!aValue || !bValue) return 0;

    if (sortOrder === "asc") {
      return String(aValue).localeCompare(String(bValue));
    } else {
      return String(bValue).localeCompare(String(aValue));
    }
  });

  return (
    <div className="flex flex-col w-full px-4">
      <div className="flex flex-row justify-between items-center w-full mt-2">
        <div>
          <h3 className="text-xl font-bold text-deep-navy mr-2">
            Catalogs Details
          </h3>
        </div>

        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            <div className="flex">
              {resetFilter && (
                <button
                  className="my-2 mr-4 flex items-center justify-center rounded-md bg-deep-navy px-4 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-electric-green hover:text-deep-navy focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-green"
                  onClick={handleResetFilter}
                >
                  <RotateLeftIcon />
                  Reset Filter
                </button>
              )}
              <button
                className="my-2 flex items-center justify-center rounded-md bg-deep-navy px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#00FFFF] hover:text-deep-navy focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-green"
                onClick={toggleDrawer(anchor, true)}
              >
                <svg
                  className="w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                  />
                </svg>
                filter
              </button>
            </div>
            {toggleDrawerPosition[anchor] && (
              <SwipeableDrawer
                anchor={anchor}
                open={toggleDrawerPosition[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                <div className="flex flex-col flex-shrink w-full h-full px-4 bg-deep-navy text-electric-green">
                  <img
                    className="absolute  w-96  bottom-1 opacity-90 z-0 right-0 text-amarant-400"
                    src={searchillustration}
                    alt=""
                  />
                  <div
                    className=" border-0 border-gray-400  mt-2 px-4 py-2 h-auto w-96 z-10  bg-deep-navy/50"
                    name="myForm"
                  >
                    <div className="flex flex-row justify-between">
                      <h3 className="text-xl font-semibold">
                        Filter a Catalog
                      </h3>
                      <button onClick={toggleDrawer("right", false)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="mt-4 pb-2 flex flex-col gap-3">
                      <div className="w-full mt-2">
                        <SelectDropdown
                          title="select Category"
                          mode="multiple"
                          name="category"
                          value={selectedValues?.category}
                          placeholder={<span style={{ color: 'white' }}>please select</span>}
                          data={categoryList}
                          setValue={(e, value) => {
                            handleChange(e, value);
                          }}
                        />
                        {errors.category !== null ? (
                          <span className="text-[#f44336] text-sm">
                            {errors.category}
                          </span>
                        ) : null}
                      </div>
                      <div className="w-full mt-2">
                        <SelectDropdown
                          title="select Sub Category1"
                          name="subCategory1"
                          mode="multiple"
                          value={selectedValues?.subCategory1}
                          placeholder={<span style={{ color: 'white' }}>please select</span>}
                          data={subCategory1List}
                          setValue={(e, value) => {
                            handleChange(e, value);
                          }}
                        />
                        {errors.subCategory1 !== null ? (
                          <span className="text-[#f44336] text-sm">
                            {errors.subCategory1}
                          </span>
                        ) : null}
                      </div>

                      <div className="w-full mt-2">
                        <SelectDropdown
                          title="select Sub Category2"
                          name="subCategory2"
                          mode="multiple"
                          value={selectedValues?.subCategory2}
                          placeholder={<span style={{ color: 'white' }}>please select</span>}
                          data={subCategory2List}
                          setValue={(e, value) => {
                            handleChange(e, value);
                          }}
                        />
                        {errors.subCategory2 !== null ? (
                          <span className="text-[#f44336] text-sm">
                            {errors.subCategory2}
                          </span>
                        ) : null}
                      </div>

                      <div className="w-full mt-2">
                        <SelectDropdown
                          title="select data partners"
                          name="provider"
                          mode="multiple"
                          value={selectedValues?.provider}
                          placeholder={<span style={{ color: 'white' }}>please select</span>}
                          data={providerList}
                          setValue={(e, value) => {
                            handleChange(e, value);
                          }}
                        />
                        {errors.provider !== null ? (
                          <span className="text-[#f44336] text-sm">
                            {errors.provider}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="flex justify-end">
                      {!loader ? (
                        <button
                          className="flex w-full justify-center rounded-md bg-electric-green px-3 py-1.5 text-sm font-semibold leading-6 text-deep-navy shadow-sm hover:bg-true-teal focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-green mt-4"
                          type="submit"
                          onClick={() => handleSubmit(anchor)}
                        >
                          apply filter
                        </button>
                      ) : (
                        <div className="flex w-full justify-center rounded-md bg-electric-green px-3 py-1.5 text-sm font-semibold leading-6 text-deep-navy shadow-sm hover:bg-true-teal focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-green mt-4">
                          <CircularProgress
                            style={{
                              width: "24px",
                              height: "24px",
                              color: "#FFFFFF",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </SwipeableDrawer>
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="items-center w-full mt-2 mb-4 text-deep-navy">
        <p>
          Refine your product exploration with our advanced catalog search,
          allowing you to filter results seamlessly by data partner, category,
          and two levels of sub-categories for facilitating a precise and
          organized approach to accessing relevant information.
        </p>
      </div>

      {!loadingTable ? (
        sortedData?.length > 0 ? (
          <div className="flex flex-col w-full">
            <TableContainer>
              <Table
                sx={{ minWidth: 650, borderRadius: 0 }}
                stickyHeader
                size="small"
                classes={{ root: "w-100" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        fontSize: "0.9rem",
                        fontWeight: 900,
                        color: "#0A2756",
                        backgroundColor: "#e8effb",
                        borderRadius: 0,
                        borderTop: 1,
                        borderLeft: 1,
                        borderColor: "#d6d3d1",
                      },
                      "& th:first-of-type": {
                        borderLeft: 1,
                        borderColor: "#d6d3d1",
                      },
                      "& th:last-child": {
                        borderRight: 1,
                        borderColor: "#d6d3d1",
                      },
                    }}
                  >
                    <TableCell key={0} align="center">
                      <TableSortLabel
                        active={sortedColumn === "PROVIDER_NAME"}
                        direction={sortOrder}
                        onClick={() => handleSort("PROVIDER_NAME")}
                      >
                        data partners
                      </TableSortLabel>
                    </TableCell>
                    <TableCell key={5} align="center">
                      <TableSortLabel
                        active={sortedColumn === "ENTITY_NAME"}
                        direction={sortOrder}
                        onClick={() => handleSort("ENTITY_NAME")}
                      >
                        Entity Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell key={1} align="center">
                      <TableSortLabel
                        active={sortedColumn === "ATTRIBUTE_NAME"}
                        direction={sortOrder}
                        onClick={() => handleSort("ATTRIBUTE_NAME")}
                      >
                        Attribute Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell key={2} align="center">
                      <TableSortLabel
                        active={sortedColumn === "CATEGORY"}
                        direction={sortOrder}
                        onClick={() => handleSort("CATEGORY")}
                      >
                        Category
                      </TableSortLabel>
                    </TableCell>
                    <TableCell key={3} align="center">
                      <TableSortLabel
                        active={sortedColumn === "SUB_CATEGORY1"}
                        direction={sortOrder}
                        onClick={() => handleSort("SUB_CATEGORY1")}
                      >
                        Sub Category1
                      </TableSortLabel>
                    </TableCell>
                    <TableCell key={8} align="center">
                      <TableSortLabel
                        active={sortedColumn === "SUB_CATEGORY2"}
                        direction={sortOrder}
                        onClick={() => handleSort("SUB_CATEGORY2")}
                      >
                        Sub Category2
                      </TableSortLabel>
                    </TableCell>
                    <TableCell key={4} align="center">
                      <TableSortLabel
                        active={sortedColumn === "DESCRIPTION"}
                        direction={sortOrder}
                        onClick={() => handleSort("DESCRIPTION")}
                      >
                        Description
                      </TableSortLabel>
                    </TableCell>
                    <TableCell key={7} align="center">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      // const isIntegrating = integratingRows?.includes(
                      //   `${row.PROVIDER_NAME}-${row.ENTITY_NAME}`
                      // );
                      // const isIntegrated = integratedProviders?.includes(
                      //   `${row.PROVIDER_NAME}`
                      // );
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            "& td:last-child": {
                              borderRight: 1,
                              borderColor: "#d6d3d1",
                            },
                            "& td": {
                              borderLeft: 1,
                              borderColor: "#d6d3d1",
                              color: "#0A2756",
                            },
                          }}
                        >
                          <TableCell align="center">
                            {row.PROVIDER_NAME}
                          </TableCell>
                          <TableCell align="center">
                            {row.ENTITY_NAME}
                          </TableCell>
                          <TableCell align="center">
                            {row.ATTRIBUTE_NAME}
                          </TableCell>
                          <TableCell align="center">{row.CATEGORY}</TableCell>
                          <TableCell align="center">
                            {row.SUB_CATEGORY1}
                          </TableCell>
                          <TableCell align="center">
                            {row.SUB_CATEGORY2}
                          </TableCell>
                          <TableCell align="center">
                            {row.DESCRIPTION}
                          </TableCell>

                          <TableCell align="center">
                            <div className="flex justify-between">
                              <button
                                onClick={() =>
                                  fetchcsvTableData(
                                    row.PROVIDER_NAME,
                                    row.ENTITY_NAME
                                  )
                                }
                                className="flex flex-row items-center px-2 justify-center"
                                title="View"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-5 h-5"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                                </svg>
                                <span className="pl-1 underline">View</span>
                              </button>
                              {/* <button
                                onClick={() =>
                                  fetchSampleProviderData(
                                    row.PROVIDER_NAME,
                                    row.ENTITY_NAME
                                  )
                                }
                                className="flex flex-row items-center px-2 justify-center"
                                title="Sample"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-5 h-5"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                                </svg>
                                <span className="pl-1 underline">Sample</span>
                              </button> */}
                              {/* {isIntegrating ? (
                                <div className="flex justify-center px-8">
                                  <CircularProgress
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      color: "amaranth-600",
                                    }}
                                    title="Wait integrating is going on"
                                  />
                                </div>
                              ) : (
                                <button
                                  onClick={() =>
                                    handleIntegration(
                                      row.PROVIDER_NAME,
                                      row.ENTITY_NAME
                                    )
                                  }
                                  disabled={isIntegrated}
                                  className={`${
                                    isIntegrated && "opacity-50"
                                  } flex flex-row items-center px-6 justify-center`}
                                  title={
                                    isIntegrated ? "Integrated" : "Integrate"
                                  }
                                >
                                  <img
                                    className="w-6 h-6"
                                    src={IntegrationImage}
                                    alt="IntegrationIcon"
                                  />
                                  <span className="pl-1 underline">
                                    {isIntegrated ? "Integrated" : "Integrate"}
                                  </span>
                                </button>
                              )} */}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={data?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        ) : (
          <div className="flex flex-col w-full">
            <span className="text-deep-navy font-bold mt-4">
              We currently don't have data to display. Please apply some other
              filter!!.
            </span>
          </div>
        )
      ) : (
        <div className="flex justify-center mt-8">
          <Spinner />
        </div>
      )}

      <CommonTable
        head={viewTable?.head}
        rows={viewTable?.rows}
        open={isResultModalOpen}
        handleClose={handleResultModalClose}
        title={"Search Result"}
      />
    </div>
  );
};

export default SearchCatalog;
