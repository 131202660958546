import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";

import {
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import searchillustration from "../Assets/search_illustration.svg";
import {
  // jsonToCsv,
  handleDate,
  // downloadFileInCSV,
  removeDuplicateObjects,
} from "../utils/commonFunctions";
import CustomTable from "./CommonComponent/Table";
import CommonModal from "./CommonComponent/Modal";

import {
  ApprovedStatus,
  CompletedStatus,
  RejectedStatus,
  FailedStatus,
  WaitingStatus,
  OtherStatus,
} from "./CommonComponent/StatusColumn";

import SelectDropdown from "./CommonComponent/SelectDropdown";
import Spinner from "./CommonComponent/Spinner";

import "react-datepicker/dist/react-datepicker.css";

import "./styles.css";
import "./pure-react.css";
import API from "../apiServices/api";
import { toast } from "react-toastify";

const initialFilters = {
  providerName: [],
  requestedBy: [],
  templateName: [],
  status: [],
  date: "",
};

const QueryStatus = () => {
  const state = useSelector((state) => state);
  const user = state && state.user;

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState("asc"); // 'asc' or 'desc'
  const [sortedColumn, setSortedColumn] = useState(null);
  const [resetFilter, setResetFilter] = useState(false);
  const [loader, setLoader] = useState(false);

  const [viewTable, setViewTable] = useState({
    head: [],
    rows: [],
    runId: "",
  });

  const [filteredList, setFilteredList] = useState({
    providersList: [],
    usersList: [],
    templatesList: [],
    statusList: [],
  });

  const [filteredData, setFilteredData] = useState(initialFilters);

  const [viewTemplate, setViewTemplate] = React.useState({
    openModal: false,
    queryName: "",
  });

  const handleResultModalClose = () =>
    setViewTemplate({
      ...viewTemplate,
      openModal: false,
      queryName: "",
    });

  const [requestFailedReason, setRequestFailedReason] = React.useState({
    openModal: false,
    message: "",
  });

  const [toggleDrawerPosition, setToggleDrawerPosition] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    search: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setToggleDrawerPosition({ ...toggleDrawerPosition, [anchor]: open });
  };

  useEffect(() => {
    setLoader(true);
    fetchMainTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMainTable = async () => {
    const payload = {
      account_name: user?.account_name,
      db_name: user?.consumerDBName,
      role: user?.role?.includes("Consumer_Admin") ? "ADMIN" : "USER",
      user_name: user?.name,
    };
    try {
      const response = await API.getAllRequestData(payload);
      if (response.status === 200 && response?.data?.data) {
        setLoader(false);
        let data = response?.data?.data;
        setData(data);

        let providerList = [{ value: "all", name: "All" }];
        let userList = [{ value: "all", name: "All" }];
        let templateList = [{ value: "all", name: "All" }];
        let statuses = [{ value: "all", name: "All" }];

        data?.map((value) => {
          providerList.push({
            value: value.PROVIDER_NAME,
            name: value.PROVIDER_NAME,
          });
          userList.push({
            value: value.USER_NAME,
            name: value.USER_NAME,
          });
          templateList.push({
            value: value.TEMPLATE_NAME,
            name: value.TEMPLATE_NAME,
          });
          statuses.push({
            value: value.STATUS,
            name:
              value.STATUS.toLowerCase() === "false"
                ? "Rejected"
                : value.STATUS.toLowerCase() === "true"
                  ? "Approved"
                  : value.STATUS,
          });
          return null;
        });
        setFilteredList({
          ...filteredList,
          providersList: removeDuplicateObjects(providerList),
          usersList: removeDuplicateObjects(userList),
          templatesList: removeDuplicateObjects(templateList),
          statusList: removeDuplicateObjects(statuses),
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event, name) => {
    if (event?.includes("all")) {
      setFilteredData({
        ...filteredData,
        [name]: ["all"],
      });
    } else {
      setFilteredData({
        ...filteredData,
        [name]: event,
      });
    }
  };

  // const downloadFile = async (TEMPLATE_NAME, RUN_ID) => {
  //   TEMPLATE_NAME = TEMPLATE_NAME.replace(/\s/g, "_");

  //   const payload = {
  //     account_name: user?.account_name,
  //     db_name: user?.consumerDBName,
  //     templateName: TEMPLATE_NAME,
  //     run_id: RUN_ID,
  //   };
  //   try {
  //     const response = await API.downloadFileAPI(payload);
  //     if (response.status === 200 && response?.data) {
  //       const csvData = jsonToCsv(response?.data); // Create a Blob from the CSV data
  //       downloadFileInCSV(csvData, TEMPLATE_NAME, RUN_ID);
  //     } else {
  //       toast.error("File cannnot be downloaded...");
  //     }
  //   } catch (error) {
  //     toast.error("File cannnot be downloaded...");
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const fetchcsvTableData = async (templateName, runId) => {
    templateName = templateName.replace(/\s/g, "_");
    setViewTemplate({
      ...viewTemplate,
      openModal: true,
      queryName: templateName,
    });
    setViewTable({ head: [], rows: [], runId: "" });

    const payload = {
      account_name: user?.account_name,
      db_name: user?.consumerDBName,
      templateName: templateName,
      run_id: runId,
    };
    try {
      const response = await API.viewSampleData(payload);
      if (response.status === 200 && response?.data?.data) {
        let data = response?.data?.data;
        let head = [];
        let row = [];
        if (data?.length > 0) {
          head = data && Object.keys(data[0]);
          data?.map((obj) => {
            return row.push(head?.map((key) => obj[key]));
          });
        }
        setViewTable({ head: head, rows: row, runId: runId });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

  };

  const fetchcsvTableData1 = async (templateName, runId, output_table_name) => {
    templateName = templateName.replace(/\s/g, "_");
    setViewTemplate({
      ...viewTemplate,
      openModal: true,
      queryName: templateName,
    });
    setViewTable({ head: [], rows: [], runId: "" });
    const payload = {
      account_name: user?.account_name,
      db_name: user?.consumerDBName,
      template_name: templateName,
      role: user?.role?.includes("Consumer_Admin") ? "ADMIN" : "USER",
      user_name: user?.name,
    };
    try {
      const response = await API.getAllRequestData(payload);
      if (response.status === 200 && response?.data?.data) {
        let data = response?.data?.data;
        let head = ["Total Count", "Enrichment Count", "Output Table"];
        let row = [];
        const filteredData = data.find((record) => record.RUN_ID === runId);
        console.log('FilterData : ', filteredData);
        if (filteredData) {
          row = [filteredData?.CONSUMER_RECORD_COUNT, filteredData?.MATCH_COUNT, output_table_name];
        }
        setViewTable({ head: head, rows: [row], runId: runId });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }


  };




  const handleFilter = async (anchor) => {
    setLoader(true);

    setToggleDrawerPosition({ ...toggleDrawerPosition, [anchor]: false });
    setData([]);
    setPage(0);

    let finalDate = "all";
    if (filteredData?.date) {
      const dateObj = new Date(parseInt((filteredData?.date).getTime()));
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1;
      const day = dateObj.getDate().toString().padStart(2, "0");
      finalDate = year + "-" + month + "-" + day;
    }

    const payload = {
      account_name: user?.account_name,
      db_name: user?.consumerDBName,
      providerNames:
        filteredData?.providerName?.length > 0
          ? filteredData?.providerName?.join(",")
          : "all",
      requestedBy: user?.role?.includes("Consumer_Admin")
        ? filteredData?.requestedBy?.length > 0
          ? filteredData?.requestedBy?.join(",")
          : "all"
        : user?.name,
      templateNames:
        filteredData?.templateName?.length > 0
          ? filteredData?.templateName?.join(",")
          : "all",
      statuses:
        filteredData?.status?.length > 0
          ? filteredData?.status?.join(",")
          : "all",
      date: finalDate,
    };
    try {
      const response = await API.filterStatusTable(payload);
      if (response.status === 200 && response?.data?.data) {
        setLoader(false);
        let data = response?.data?.data;
        setData(data);
        setResetFilter(true);
      } else {
        setLoader(false);
        setResetFilter(true);
      }
    } catch (error) {
      setLoader(true);
      toast.error("We are fetching some issue while filter the table..");
      console.error("Error fetching data:", error);
    }
  };

  const handleResetFilter = () => {
    fetchMainTable();
    setLoader(true);
    setResetFilter(false);
    setFilteredData(initialFilters);
  };

  // Functions for handle sort for columns...
  const handleSort = (columnKey) => {
    if (sortedColumn === columnKey) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortOrder("asc");
      setSortedColumn(columnKey);
    }
  };

  const sortedData = [...data].sort((a, b) => {
    const aValue = a[sortedColumn];
    const bValue = b[sortedColumn];

    if (!aValue || !bValue) return 0;

    if (sortOrder === "asc") {
      return String(aValue).localeCompare(String(bValue));
    } else {
      return String(bValue).localeCompare(String(aValue));
    }
  });

  return (
    <div className="flex flex-col w-full px-4">
      <div className="flex flex-row justify-between items-center w-full mt-2 mb-4">
        <div>
          <h3 className="text-xl font-bold text-deep-navy mr-2">
            Status
          </h3>
        </div>
        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            <div className="flex">
              {resetFilter && (
                <button
                  className="my-2 mr-4 flex items-center justify-center rounded-md bg-deep-navy px-4 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-electric-green hover:text-deep-navy focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-green"
                  onClick={handleResetFilter}
                >
                  <RotateLeftIcon />
                  Reset Filter
                </button>
              )}
              <button
              // className="my-2 pr-4 flex items-center justify-center rounded-md bg-deep-navy px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#00FFFF] hover:text-deep-navy focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-green"
              onClick={toggleDrawer(anchor, true)}
              className="my-2 flex items-center rounded-md bg-deep-navy px-3 py-1.5 text-sm font-semibold text-white hover:bg-[#00FFFF] hover:text-deep-navy"
              >
                <FilterAltIcon />
                filter
              </button>
            </div>
            {toggleDrawerPosition[anchor] && (
              <SwipeableDrawer
                anchor={anchor}
                open={toggleDrawerPosition[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                <div className="flex flex-col flex-shrink w-full h-full px-4 bg-deep-navy text-electric-green">
                  <img
                    className="absolute w-80  bottom-0 opacity-90 z-0 right-0 text-amarant-400"
                    src={searchillustration}
                    alt=""
                  />
                  <div
                    className=" border-0 border-gray-400  mt-2 px-4 py-2 h-auto w-96 z-10  bg-deep-navy/50"
                    name="myForm"
                  >
                    <div className="flex flex-row justify-between">
                      <h3 className="text-xl font-semibold">Filter Explorer</h3>
                      <button onClick={toggleDrawer("right", false)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="mt-4 pb-2 flex flex-col gap-3">
                      <div className="w-full">
                        <SelectDropdown
                          title="select data partner"
                          mode="multiple"
                          name="providerName"
                          placeholder={<span style={{ color: 'white' }}>please select</span>}
                          value={filteredData?.providerName}
                          data={filteredList.providersList}
                          setValue={(e, value) => {
                            handleChange(e, value);
                          }}
                        />
                      </div>
                    </div>
                    {user?.role.includes("Consumer_Admin") && (
                      <div className="mt-4 pb-2 flex flex-col gap-3">
                        <div className="w-full">
                          <SelectDropdown
                            title="select User"
                            mode="multiple"
                            name="requestedBy"
                            placeholder={<span style={{ color: 'white' }}>please select</span>}
                            value={filteredData?.requestedBy}
                            data={filteredList.usersList}
                            setValue={(e, value) => {
                              handleChange(e, value);
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <div className="mt-4 pb-2 flex flex-col gap-3">
                      <div className="w-full">
                        <SelectDropdown
                          title="select template"
                          mode="multiple"
                          name="templateName"
                          placeholder={<span style={{ color: 'white' }}>please select</span>}
                          value={filteredData?.templateName}
                          data={filteredList.templatesList}
                          setValue={(e, value) => {
                            handleChange(e, value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-4 pb-2 flex flex-col gap-3">
                      <div className="w-full">
                        <SelectDropdown
                          title="select Status"
                          mode="multiple"
                          name="status"
                          placeholder={<span style={{ color: 'white' }}>please select</span>}
                          value={filteredData?.status}
                          data={filteredList.statusList}
                          setValue={(e, value) => {
                            handleChange(e, value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-4 pb-2 flex flex-col gap-1">
                      <label className="ml-1 montserrat">select Date</label>
                      <div className="w-full">
                        <DatePicker
                          showIcon
                          selected={filteredData?.date}
                          onChange={(date) =>
                            setFilteredData({ ...filteredData, date: date })
                          }
                          isClearable
                          placeholderText="please select a date"
                          className="rounded-md pl-8 w-full bg-deep-navy ring-1  ring-electric-green placeholder:text-[#FFFFFF]  focus:ring-2 focus:ring-inset focus:ring-electric-green  sm:text-sm sm:leading-6 text-electric-green"
                        />
                      </div>
                    </div>
                    <div className="flex justify-end">
                      <button
                        className="flex w-full justify-center rounded-md bg-electric-green px-3 py-1.5 text-sm font-semibold leading-6 text-deep-navy shadow-sm hover:bg-true-teal focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-green mt-4"
                        type="submit"
                        onClick={() => handleFilter(anchor)}
                      >
                        apply filter
                      </button>
                    </div>
                  </div>
                </div>
              </SwipeableDrawer>
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="w-full mt-2 mb-4 text-deep-navy">
        <p className="">
          {/* Access all match rate and enrichment past requests effortlessly, for
          comprehensive query management. Effortlessly refine your views by
          filtering data based on specific criteria, ensuring a tailored and
          focused perspective on requests generated through match rate and
          enrichment processes. */}
          Access and filter all past queries effortlessly, for comprehensive query management.
        </p>
      </div>
      {!loader ? (
        <div>
          <TableContainer>
            <Table
              sx={{ minWidth: 650, borderRadius: 0 }}
              stickyHeader
              size="small"
              classes={{ root: "w-100" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow
                  sx={{
                    "& th": {
                      fontSize: "0.9rem",
                      fontWeight: 900,
                      color: "#0A2756",
                      backgroundColor: "#e8effb",
                      borderRadius: 0,
                      borderTop: 1,
                      borderLeft: 1,
                      borderColor: "#d6d3d1",
                    },
                    "& th:first-of-type": {
                      borderLeft: 1,
                      borderColor: "#d6d3d1",
                    },
                    "& th:last-child": {
                      borderRight: 1,
                      borderColor: "#d6d3d1",
                    },
                  }}
                >
                  <TableCell key={0} align="center">
                    <TableSortLabel
                      active={sortedColumn === "RUN_ID"}
                      direction={sortOrder}
                      onClick={() => handleSort("RUN_ID")}
                    >
                      Request ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell key={2} align="center">
                    <TableSortLabel
                      active={sortedColumn === "PROVIDER_NAME"}
                      direction={sortOrder}
                      onClick={() => handleSort("PROVIDER_NAME")}
                    >
                      data partners
                    </TableSortLabel>
                  </TableCell>
                  <TableCell key={1} align="center">
                    <TableSortLabel
                      active={sortedColumn === "TEMPLATE_NAME"}
                      direction={sortOrder}
                      onClick={() => handleSort("TEMPLATE_NAME")}
                    >
                      template name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell key={3} align="center" style={{ width: "200px" }}>
                    <TableSortLabel
                      active={sortedColumn === "COLOUMNS"}
                      direction={sortOrder}
                      onClick={() => handleSort("COLOUMNS")}
                    >
                      Attributes / Identifier Types
                    </TableSortLabel>
                  </TableCell>
                  <TableCell key={4} align="center">
                    <TableSortLabel
                      active={sortedColumn === "STATUS"}
                      direction={sortOrder}
                      onClick={() => handleSort("STATUS")}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell key={5} align="center">
                    Actions
                  </TableCell>
                  <TableCell key={6} align="center">
                    <TableSortLabel
                      active={sortedColumn === "USER_NAME"}
                      direction={sortOrder}
                      onClick={() => handleSort("USER_NAME")}
                    >
                      Requested By
                    </TableSortLabel>
                  </TableCell>
                  <TableCell key={7} align="center">
                    <TableSortLabel
                      active={sortedColumn === "RUN_ID"}
                      direction={sortOrder}
                      onClick={() => handleSort("RUN_ID")}
                    >
                      Requested On
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              {sortedData && sortedData?.length > 0 ? (
                <TableBody>
                  {sortedData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => {
                      const output_table_name = `${row?.TEMPLATE_NAME?.replace(
                        / /g,
                        "_"
                      )}_${row?.RUN_ID}`;
                      const columns = row.COLOUMNS?.split(",");
                      const firstTwoColumns = row.COLOUMNS?.split(",").slice(
                        0,
                        2
                      );
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            "& td": {
                              borderLeft: 1,
                              borderColor: "#d6d3d1",
                              color: "#0A2756",
                            },
                            "& td:last-child": {
                              borderRight: 1,
                              borderColor: "#d6d3d1",
                            },
                          }}
                        >
                          <TableCell align="center">{row.RUN_ID}</TableCell>
                          <TableCell align="center">
                            {row.PROVIDER_NAME}
                          </TableCell>
                          <TableCell align="center">
                            {row.TEMPLATE_NAME}
                          </TableCell>
                          {/* <TableCell
                            align="center"
                            style={{ width: "200px" }}
                            className={columns?.length > 2 && "cursor-pointer"}
                            title={columns?.length > 2 && columns?.join(", ")}
                          >
                            {firstTwoColumns?.map((item, index) => (
                              <span key={index}>
                                {item}
                                {index !== firstTwoColumns.length - 1 && ", "}
                              </span>
                            ))}
                            {columns.length > 2 && (
                              <button>
                                <span className="pl-2 font-bold">
                                  +{columns.length - 2}
                                </span>
                              </button>
                            )}
                          </TableCell> */}
                           <TableCell
                            align="center"
                            style={{ width: "200px" }}
                            className={columns?.length > 2 && "cursor-pointer"}
                            title={columns?.length > 2 && columns?.join(", ")}
                          >
                            {row?.TEMPLATE_NAME?.includes(
                                  "CUSTOMER ENRICHMENT"
                                ) ? (
                              <>
                                <span>ATTRIBUTES: </span>
                                {firstTwoColumns?.map((item, index) => (
                                  <span key={index}>
                                    {item}
                                    {index !== firstTwoColumns.length - 1 && ", "}
                                  </span>
                                ))}
                                {columns.length > 2 && (
                                  <button>
                                    <span className="pl-2 font-bold">
                                      +{columns.length - 2}
                                    </span>
                                  </button>
                                )}
                              </>
                            ) : row?.TEMPLATE_NAME?.includes(
                                  "ADVERTISER MATCH"
                                ) ? (
                              firstTwoColumns?.join(", ")
                            ) : null}
                          </TableCell>


                          <TableCell align="center">
                            <span>
                              {row.STATUS.toLowerCase() === "true"
                                ? ApprovedStatus("approved")
                                : row.STATUS.toLowerCase() === "false"
                                  ? RejectedStatus("rejected")
                                  : row.STATUS.toLowerCase() === "completed"
                                    ? CompletedStatus(row.STATUS)
                                    : row.STATUS.toLowerCase() === "failed"
                                      ? FailedStatus(row.STATUS)
                                      : row.STATUS.toLowerCase() ===
                                        "waiting for approval"
                                        ? WaitingStatus(row.STATUS)
                                        : OtherStatus(row.STATUS)}
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            {row.STATUS.toLowerCase() === "failed" ||
                              row.STATUS.toLowerCase() === "false" ? (
                              <div className="flex flex-row items-center justify-center">
                                <button
                                  onClick={() =>
                                    setRequestFailedReason({
                                      ...requestFailedReason,
                                      openModal: true,
                                      // message: row.ERROR_FOR_WEBUI,
                                      message:  row.ERROR_FOR_WEBUI?  row.ERROR_FOR_WEBUI:"Oops!Something went wrong.Please try again later.",
                                    })
                                  }
                                  className="flex flex-row px-2 text-red-600"
                                  title="Request Error"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 text-red-600"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                                    />
                                  </svg>
                                  <span className="pl-2 underline">
                                    Request Error
                                  </span>
                                </button>
                              </div>
                            ) : (
                              <div className="flex justify-center">
                                {row?.TEMPLATE_NAME?.includes(
                                  "ADVERTISER MATCH"
                                ) && (
                                    <button
                                      onClick={() =>
                                        fetchcsvTableData(
                                          row.TEMPLATE_NAME,
                                          row.RUN_ID
                                        )
                                      }
                                      className={`${row.STATUS.toLowerCase() === "completed"
                                        ? "text-deep-navy"
                                        : "text-electric-green/50"
                                        } flex flex-row items-center px-2 justify-center`}
                                      disabled={
                                        row.STATUS.toLowerCase() !== "completed"
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-5 h-5"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                        />
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                      </svg>
                                      <span className="pl-2 underline">View</span>
                                    </button>
                                  )}

                                {(row?.TEMPLATE_NAME?.includes("customer_enrichment") 
                                || row?.TEMPLATE_NAME?.toLowerCase().startsWith("customer enrichment")) 
                                && (
                                    <button
                                      onClick={() =>
                                        fetchcsvTableData1(
                                          row.TEMPLATE_NAME,
                                          row.RUN_ID,
                                          output_table_name
                                        )
                                      }
                                      className={`${row.STATUS.toLowerCase() === "completed"
                                        ? "text-deep-navy"
                                        : "text-electric-green/50"
                                        } flex flex-row items-center px-2 justify-center`}
                                      disabled={
                                        row.STATUS.toLowerCase() !== "completed"
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-5 h-5"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                        />
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                      </svg>
                                      <span className="pl-2 underline">View</span>
                                    </button>
                                    // <span
                                    //   className="custom-break-line "
                                    //   title={output_table_name}
                                    // >
                                    //   {output_table_name}
                                    // </span>
                                  )}
                              </div>
                            )}
                          </TableCell>
                          <TableCell align="center">{row?.USER_NAME}</TableCell>
                          <TableCell align="center">
                            {handleDate(row.REQUEST_TS)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              ) : (
                <TableRow className="text-deep-navy">
                  <TableCell
                    className="text-center border border-downriver-200"
                    colSpan={8}
                  >
                    NO DATA FOUND
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={data?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      ) : (
        <div className="flex justify-center mt-8">
          <Spinner />
        </div>
      )}

      <CustomTable
        id={viewTable.runId}
        head={viewTable.head}
        rows={viewTable.rows}
        pagination={
          viewTemplate.queryName === "ADVERTISER_MATCH" ? "none" : true
        }
        open={viewTemplate.openModal}
        handleClose={handleResultModalClose}
        title={"Request Output"}
      />

      {requestFailedReason.openModal ? (
        <CommonModal
          open={requestFailedReason.openModal}
          handleClose={() =>
            setRequestFailedReason({ ...requestFailedReason, openModal: false })
          }
          title={"Request Error"}
          message={requestFailedReason.message}
          buttons={false}
          textColor={"text-red-600"}
          svg={true}
        />
      ) : null}
    </div>
  );
};

export default QueryStatus;
