import * as actionType from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

const initialState = {
  name: "",
  role: [],
  account_name: "",
  ConsumerPartyAccount: "",
  isLoggedIn: false,
  user_name: "",
  organization_name: "",
  consumerDBName: "",
  providerDBName: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.LOGIN_REQUEST:
      return updateObject(state, action.payload);
      
    case actionType.LOGOUT_USER:
        // Set isLoggedIn to false on logout
        localStorage.removeItem("session_id");
      return updateObject(state, { isLoggedIn: false });

    default:
      return state;
  }
};

export default reducer;
