// service.js

import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const baseURL = process.env.REACT_APP_BASE_URL;
const axiosInstance = axios.create({
  baseURL,
});

let sessionExpiryTimeout;

axiosInstance.interceptors.request.use(
  (request) => {
    const accessToken = localStorage.getItem("access_token");
    const sessionId = localStorage.getItem("session_id");
    const tokenExpiry = localStorage.getItem("token_expiry");
    request.baseURL = baseURL;
    request.headers.Authorization = `Bearer ${accessToken}`;
    request.headers["X-Session-ID"] = sessionId;
    request.headers["X-Token-Expiry"] = tokenExpiry;
    const warningThreshold = 2 * 60 * 1000; // 2 minutes in milliseconds
    const remainingTime = new Date(tokenExpiry) - new Date();

    if (remainingTime > warningThreshold) {
      clearTimeout(sessionExpiryTimeout);
      sessionExpiryTimeout = setTimeout(() => {
        // Use toast notification instead of alert
        toast.warning("Your session will expire in the next 2 minutes. Please log in again.");
      }, remainingTime - warningThreshold);
    }
    return request;
  },
  (error) => errorHandler(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      // Handle unauthorized access if needed
    }
    return response;
  },
  (error) => errorHandler(error)
);

const errorHandler = (error) => {
  let errorMsg = {};
  if (
    error.response &&
    error.response.status &&
    error.response.status === 401
  ) {
    localStorage.setItem(
      "loginErrorMessage",
      "Your previous login session has expired. Please login again to use the platform."
    );
    window.location.href = "/login";
  }
  if (error.response && error.response.data && error.response.data) {
    errorMsg = error.response.data;
  }
  return Promise.reject(errorMsg);
};

export const setInterceptor = () => {
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      const error = err.response;
      let errorMsg;
      if (error && error.status === 401) {
        errorMsg = `Your previous login session has expired. Please login again to use the platform.`;
      }
      if (error && error.status === 403) {
        errorMsg = error.status;
      }
      return Promise.reject(
        errorMsg ||
        `Sorry, some system issue. Please try again, and if the issue still persists, please report to our team`
      );
    }
  );
};

export default axiosInstance;
