import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

import CommonModal from "../../CommonComponent/Modal";
import Query_Template_Image from "../../../Assets/AdminConsole/admin_console_query_template.svg";
import API from "../../../apiServices/api";

const QueryTemplate = ({ user, handleToggleDrawer }) => {
  const [queryData, setQueryData] = useState({
    consumer: "",
    template: "",
    status: "",
  });

  const [consumers, setConsumers] = useState([]);
  const [templateNames, setTemplateNames] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    const getConsumerName = async () => {
      const payload = {
        account_name: user?.account_name,
        provider_database_name: user?.providerDBName,
      };
      try {
        const response = await API.getConsumerName(payload);
        if (response.status === 200 && response?.data?.data) {
          setConsumers(response?.data?.data);
        } else {
          setConsumers([]);
        }
      } catch (error) {
        setConsumers([]);
        console.error("Error fetching data:", error);
      }
    };
    getConsumerName();
  }, [user]);

  useEffect(() => {
    if (queryData?.consumer !== "") {
      const getTemplates = async () => {
        const payload = {
          account_name: user?.account_name,
          provider_database_name: user?.providerDBName,
          user: queryData?.consumer,
        };
        try {
          const response = await API.getTemplates(payload);
          if (response.status === 200 && response?.data?.data) {
            setTemplateNames(response?.data?.data);
          } else {
            setTemplateNames([]);
          }
        } catch (error) {
          setTemplateNames([]);
          console.error("Error fetching data:", error);
        }
      };
      getTemplates();
    }
  }, [user, queryData.consumer]);

  useEffect(() => {
    if (queryData?.consumer !== "" && queryData?.template !== "") {
      const fetchTemplateStatus = async () => {
        const payload = {
          account_name: user?.account_name,
          provider_database_name: user?.providerDBName,
          user: queryData?.consumer,
          template_name: queryData?.template,
        };
        try {
          const response = await API.fetchTemplateStatus(payload);
          if (response.status === 200 && response?.data?.data) {
            if (response?.data?.data?.length > 0) {
              setQueryData({
                ...queryData,
                status: response?.data?.data[0]?.TEMPLATE_STATUS,
              });
            } else {
              setQueryData({ ...queryData, status: "" });
            }
          } else {
            setQueryData({ ...queryData, status: "" });
          }
        } catch (error) {
          setQueryData({ ...queryData, status: "" });
          console.error("Error fetching data:", error);
        }
      };
      fetchTemplateStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData.consumer, queryData.template]);

  const handleClickYes = async () => {
    setOpenModal(!openModal);
    setLoading(true);

    const payload = {
      account_name: user?.account_name,
      provider_database_name: user?.providerDBName,
      user: queryData?.consumer,
      template_name: queryData?.template,
      status: queryData?.status === true ? "FALSE" : "TRUE",
    };
    try {
      const response = await API.updateTemplates(payload);
      if (response.status === 200) {
        setLoading(false);
        setQueryData({ consumer: "", template: "", status: "" });
        handleToggleDrawer("right", false);
        toast.success("The Status has been changed.");
      } else {
        setLoading(false);
        toast.error("We are fetching some error for Updating columns status!!");
      }
    } catch (error) {
      setLoading(false);
      toast.error("We are fetching some error for Updating columns status!!");
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = () => {
    if (queryData.consumer === "" || queryData.template === "") {
      return;
    } else {
      setMessage(
        `Are you sure, you want to ${
          queryData.status === true ? "Disable" : "Enable"
        } this template?`
      );
      setOpenModal(!openModal);
    }
  };

  return (
    <div className="w-96">
      <div className="pt-8 bg-opacity-75 backdrop-filter backdrop-blur-lg">
        <div className="flex flex-row items-start text-electric-green">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 mt-1 mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
            />
          </svg>
          <div className="flex flex-col w-full">
            <div className="flex justify-between">
              <h3 className="text-lg font-bold text-electric-green lowercase">
                Configure template
              </h3>
              <button onClick={handleToggleDrawer("right", false, "")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <span className="text-sm mb-4 font-light text-electric-green">
              {" "}
              Enable/Disable template for particular advertiser.
            </span>
          </div>
        </div>
      </div>
      <img
        className="absolute w-96 h-64 bottom-5 opacity-90 z-10 right-0 text-amarant-400"
        src={Query_Template_Image}
        alt=""
      />
      <div className="pl-8 relative z-20">
        <div className="my-4 flex flex-col ">
          <label className="block text-sm font-medium leading-6 text-electric-green ">
            Advertiser Name
          </label>
          <select
            value={queryData.consumer}
            onChange={(e) =>
              setQueryData({ ...queryData, consumer: e.target.value })
            }
            required
            className="bg-deep-navy block w-full rounded-md border-0 py-1.5 text-[#FFFFFF] shadow-sm ring-1 ring-inset ring-[#00FFB4]  placeholder:text-electric-green  focus:ring-2 focus:ring-inset focus:ring-electric-green  sm:text-sm sm:leading-6"
          >
            <option value="">please select</option>
            {consumers?.length > 0 ? (
              consumers?.map((consumer, index) => (
                <option key={index} value={consumer?.CONSUMER_NAME}>
                  {consumer?.CONSUMER_NAME}
                </option>
              ))
            ) : (
              <option value="">Loading...</option>
            )}
          </select>
        </div>

        <div className="my-4 flex flex-col ">
          <label className="block text-sm font-medium leading-6 text-electric-green ">
            template name
          </label>
          <select
            value={queryData.template}
            onChange={(e) => {
              setQueryData({ ...queryData, template: e.target.value });
            }}
            required
            className="bg-deep-navy block w-full rounded-md border-0 py-1.5 text-[#FFFFFF] shadow-sm ring-1 ring-inset ring-[#00FFB4]  placeholder:text-electric-green  focus:ring-2 focus:ring-inset focus:ring-electric-green  sm:text-sm sm:leading-6"
          >
            <option value="">please select</option>
            {templateNames?.length > 0 ? (
              templateNames?.map((template, index) => (
                <option key={index} value={template?.TEMPLATE_NAME}>
                  {template?.TEMPLATE_NAME}
                  {"       "}
                  {template?.TEMPLATE_STATUS === true ? " ✓" : "✗"}
                </option>
              ))
            ) : (
              <option value="">Loading...</option>
            )}
          </select>
        </div>

        {queryData.status !== "" ? (
          <div className="flex justify-end">
            {loading ? (
              <div className="flex w-full justify-center rounded-md bg-electric-green px-3 py-1.5 text-sm font-semibold leading-6 text-deep-navy shadow-sm hover:bg-true-teal focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-green m-4">
                <CircularProgress
                  style={{
                    width: "24px",
                    height: "24px",
                    color: "#000000",
                  }}
                />
              </div>
            ) : (
              <button
                className="flex w-full justify-center rounded-md bg-electric-green px-3 py-1.5 text-sm font-semibold leading-6 text-deep-navy shadow-sm hover:bg-true-teal focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-green mt-4"
                type="submit"
                onClick={handleSubmit}
              >
                {`${queryData.status === true ? "disable" : "enable"}`}
              </button>
            )}
          </div>
        ) : null}

        <CommonModal
          open={openModal}
          handleClose={handleCloseModal}
          handleClickYes={handleClickYes}
          message={message}
          buttons={true}
        />
      </div>
    </div>
  );
};

export default QueryTemplate;
