import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div class="relative overflow-hidden">
        <div class="px-4 sm:px-6 lg:px-8">
          <div class="relative mx-auto max-w-[56rem] p-20 text-center">
            <h1 class="text-4xl font-extrabold tracking-tight text-deep-navy sm:text-5xl">
              GroupM Privacy Notice
            </h1>
          </div>
        </div>
        <div class="relative px-4 sm:px-6 lg:px-8">
          <div class="prose-sm prose prose-slate prose-a:font-semibold prose-a:text-sky-500 hover:prose-a:text-sky-600 mx-auto max-w-[48rem]">
            <div>
              <h2 className="font-bold text-2xl text-deep-navy-700 pb-2">
                Introduction
              </h2>
              <div>
                <p>
                  This Privacy Policy contains important information about how
                  we use your personal information. We respect the privacy of
                  individuals who interact with our business and where you
                  choose to provide us with information about yourself, you
                  trust us to act in a responsible manner with that information.
                </p>
                <p className="my-2">
                  In this privacy policy, references “Agency”, “us”, “we”, or
                  “our”, are all references to GroupM.
                </p>
                <p className="my-2">
                  If you have any questions, comments or concerns about any
                  aspect of this policy or how GroupM, handles your information
                  please email our privacy team at info@groupmdataexchange.com
                </p>
              </div>
            </div>
            <div className="my-2">
              <h2 className="font-bold text-2xl text-deep-navy pb-2">
                What information do we collect?
              </h2>
              <div>
                <p>
                  We collect personal information from you in several ways; you
                  may share information with us, or we may collect it using
                  other means. In this section we explain the different ways we
                  collect personal information from you and some ways in which
                  that information will be used.
                </p>
              </div>
            </div>
            <div className="my-2">
              <h2 className="font-bold text-2xl text-deep-navy pb-2">
                Requesting information from us and responding to your queries
              </h2>
              <div>
                <p>
                  We process contact information such as name, email address,
                  telephone number or postal address, preferences, and other
                  declared personal information about you when you provide it to
                  us. For example, when you fill out online forms, subscribing
                  to our services, call, or text us, enquire about our products
                  and services.
                </p>
              </div>
            </div>
            <div className="my-2">
              <h2 className="font-bold text-2xl text-deep-navy pb-2">
                Interacting with us via Social Media Channels
              </h2>
              <div>
                <p>
                  We process information relating to you when you interact with
                  us through social media platforms such as Linkedin or
                  Facebook.
                </p>
              </div>
            </div>
            <div className="my-2">
              <h2 className="font-bold text-2xl text-deep-navy pb-2">
                Registering for and attending our events, seminars, or
                conferences
              </h2>
              <div>
                <p>
                  If you choose to attend one our events, we will ask for your
                  contact information to provide you with details of the event
                  and manage your attendance. For in-person events, we may ask
                  you to provide us with accessibility information, if
                  appropriate
                </p>
              </div>
            </div>
            <div className="my-2">
              <h2 className="font-bold text-2xl text-deep-navy pb-2">
                Updating you on developments and initiatives
              </h2>
              <div>
                <p>
                  We may process your contact information to keep you updated on
                  updates and developments in our business.
                </p>
              </div>
            </div>
            <div className="my-2">
              <h2 className="font-bold text-2xl text-deep-navy pb-2">
                Interacting with our website
              </h2>
              <div>
                <p>
                  When you visit our website we may collect details of your
                  website visit, including name, email, phone number, IP address
                  (a unique identifier for your computer or other device),
                  website URL and mobile device ID. Our website also uses
                  cookies please see our cookie policy here for{" "}
                  <a
                    href="https://www.wpp.com/en/cookies"
                    className="text-blue-600"
                  >
                    details
                  </a>
                  .
                </p>
              </div>
            </div>
            <div className="my-2">
              <h2 className="font-bold text-2xl text-deep-navy pb-2">
                Applying for a job at GroupM
              </h2>
              <div>
                <p>
                  We process information provided by you in relation to
                  submitting a job application to GroupM (this may include
                  sensitive personal information for example ethnic origin).
                  Please see our Recruitment Privacy Policy{" "}
                  <a
                    className="text-blue-600"
                    href="https://www.wpp.com/people/wpp-privacy-policy-for-recruitment"
                  >
                    here
                  </a>{" "}
                  for further information.
                </p>
              </div>
            </div>
            <div className="my-2">
              <h2 className="font-bold text-2xl text-deep-navy pb-2">
                Visiting our offices
              </h2>
              <div>
                <p>
                  We may collect information such as contact information
                  (including name, email address, telephone number) and CCTV
                  footage in respect to your visit to our office address.
                </p>
              </div>
            </div>
            <div className="my-2">
              <h2 className="font-bold text-2xl text-deep-navy pb-2">
                Interacting with us as a client of GroupM
              </h2>
              <div>
                <p>
                  When interacting with us as a client, we process information
                  we may receive from you or from others in relation to our
                  provision of services to our clients. Please see our Client
                  Privacy Policy{" "}
                  <a
                    className="text-blue-600"
                    href="https://www.wpp.com/client-policy"
                  >
                    here
                  </a>{" "}
                  for further information.
                </p>
              </div>
            </div>
            <div className="my-2">
              <h2 className="font-bold text-2xl text-deep-navy pb-2">
                Interacting with us as a supplier or partner to GroupM
              </h2>
              <div>
                <p>
                  We process information in relation to you as an individual if
                  you provide services to us (or if you work for a company that
                  provides services to us). Please see our Supplier Privacy
                  Policy{" "}
                  <a
                    className="text-blue-600"
                    href="https://www.wpp.com/supplier-policy"
                  >
                    here
                  </a>{" "}
                  for further information.
                </p>
              </div>
            </div>

            {/* Table */}
            <div className="my-2">
              <h2 className="font-bold text-2xl text-deep-navy pb-2">
                How do we use it?
              </h2>
              <div>
                <p>
                  We will use your personal information in the following ways.
                  We are also required by law to state a “legal basis for
                  processing”, i.e., to tell you on what grounds we are allowed
                  to use your information, and this is also set out below:
                </p>
                <div className="flex flex-col text-deep-navy my-2">
                  <div className="flex">
                    <div className="w-1/2 border border-deep-navy font-bold px-4">
                      How we will use your personal information
                    </div>
                    <div className="w-1/2 border border-deep-navy font-bold px-4">
                      Our legal basis for processing
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-1/2 border border-deep-navy px-4 ">
                      To provide or respond to you, with information that you
                      have requested, e.g., this could relate to a newsletter,
                      bulletin, an invitation for an event, your interaction
                      with us on social media
                    </div>
                    <div className="w-1/2 border border-deep-navy px-4">
                      Consent – we only use your personal information for this
                      purpose if you have asked us to do so. You can withdraw
                      your consent at any time.{" "}
                    </div>
                  </div>

                  <div className="flex">
                    <div className="w-1/2 border border-deep-navy px-4">
                      To keep you updated with developments and initiatives at
                      GroupM, its affiliates or partners
                    </div>
                    <div className="w-1/2 border border-deep-navy px-4">
                      Our legitimate interests – we use your information to
                      provide you news and updates on GroupM, its affiliates or
                      partners. You will always have the opportunity to opt-out
                      in each correspondence.
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-1/2 border border-deep-navy px-4">
                      To process your registration for and attendance at events,
                      seminars, conferences, and meetings with GroupM, its
                      affiliates or partners
                    </div>
                    <div className="w-1/2 border border-deep-navy px-4">
                      Our legitimate interests – we use your information to book
                      you on to the requested events and to send confirmation to
                      you and the event organiser.
                    </div>
                  </div>

                  <div className="flex">
                    <div className="w-1/2 border border-deep-navy px-4">
                      To respond to specific queries you may raise regarding
                      GroupM, its affiliates and partners
                    </div>
                    <div className="w-1/2 border border-deep-navy px-4">
                      Consent – we only use your personal information for this
                      purpose if you have asked us to do so. You can withdraw
                      your consent at any time.
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-1/2 border border-deep-navy px-4">
                      To provide you with better ways of accessing information
                      from this website
                    </div>
                    <div className="w-1/2 border border-deep-navy px-4">
                      Consent – we only use your personal information for this
                      purpose if you have consented for us to do so. You can
                      withdraw your consent at any time.
                    </div>
                  </div>

                  <div className="flex">
                    <div className="w-1/2 border border-deep-navy px-4">
                      To process and consider your job application.
                    </div>
                    <div className="w-1/2 border border-deep-navy px-4">
                      Our legitimate interests – we use your personal
                      information to assess your job application, update you on
                      its status and keep you informed of other opportunities,
                      if you have asked us to, via the methods you have
                      selected.
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-1/2 border border-deep-navy px-4">
                      To manage your visit to the office
                    </div>
                    <div className="w-1/2 border border-deep-navy px-4">
                      Our legitimate interests or any other purposes required by
                      law such as for example, compliance with fire protection
                      regulations.
                    </div>
                  </div>
                </div>
              </div>
              <p className="my-2">
                For how we use your information that is collected using cookies
                and similar technologies please see the “Cookies” section below.
              </p>
            </div>

            <div className="my-2">
              <h2 className="font-bold text-2xl text-deep-navy pb-2">
                Do we pass your information to third parties?
              </h2>
              <div>
                <p>
                  We may send your personal information to other Agency and WPP
                  group companies, affiliates and third parties to help us
                  process your personal information for the purposes set out in
                  this policy. Further details of our group companies can be
                  found here.
                </p>
                <p className="my-2">
                  We may disclose your personal information if we or any of our
                  assets are the subject of a sale or similar corporate
                  transaction. We will ensure that the third parties who receive
                  your personal information are required to keep it
                  confidential.
                </p>
                <p className="my-2">
                  We may disclose personal information to third parties when we
                  reasonably believe we are required by law, and in order to
                  investigate, prevent, or take action regarding suspected or
                  actual unlawful or otherwise prohibited activities, including,
                  but not limited to, fraud.
                </p>
              </div>
            </div>
            <div className="my-2">
              <h2 className="font-bold text-2xl text-deep-navy pb-2">
                Where do we send your information?
              </h2>
              <div>
                <p>
                  We are a global company and therefore we may transfer your
                  personal information to countries around the world including
                  the US and other countries outside of the UK and Europe. We
                  will, where the country to which your data is transferred has
                  not been found to provide an adequate level of protection, put
                  in place appropriate safeguards (we use standard contractual
                  clauses) to ensure your information is protected.
                </p>
              </div>
            </div>
            <div className="my-2">
              <h2 className="font-bold text-2xl text-deep-navy pb-2">
                How long do we keep your information?
              </h2>
              <div>
                <p>
                  We only keep your personal information for as long as we need
                  to, to be able to use it for the reasons given in this privacy
                  policy, and for as long as we are required to keep it by law.
                </p>
              </div>
            </div>
            <div className="my-2">
              <h2 className="font-bold text-2xl text-deep-navy pb-2">
                Third Party Websites
              </h2>
              <div>
                <p>
                  Our website links to third party sites which we do not operate
                  or endorse. These websites may use cookies and collect your
                  personal information in accordance with their own privacy
                  policies. This privacy policy does not apply to third party
                  websites and we are not responsible for third party websites.
                </p>
              </div>
            </div>
            <div className="my-2">
              <h2 className="font-bold text-2xl text-deep-navy pb-2">
                How do we protect your information?
              </h2>
              <div>
                <p>
                  We take appropriate technical and organisational measures to
                  ensure that your personal information disclosed to us is kept
                  secure, accurate and up to date and kept only for so long as
                  is necessary for the purposes for which it is used.
                </p>
              </div>
            </div>
            <div className="my-2">
              <h2 className="font-bold text-2xl text-deep-navy pb-2">
                Children’s Privacy
              </h2>
              <div>
                <p>
                  This website is not intended or designed to attract children
                  under the age of 16. We do not knowingly collect personal
                  information from or about any person under the age of 16. If
                  you are under 16 years old and wish to ask a question or use
                  this site in any way which requires you to submit your
                  personal information, please get your parent or guardian to do
                  so on your behalf.
                </p>
              </div>
            </div>

            {/* List */}
            <div className="my-2">
              <h2 className="font-bold text-2xl text-deep-navy pb-2">
                Your rights
              </h2>
              <div>
                <p>You are entitled to ask:</p>
                <ol className=" list-decimal list-inside pl-6 py-2">
                  <li>
                    for a copy of the personal information we hold about you,
                    and details about how we are processing your personal
                    information;
                  </li>
                  <li>
                    to have any inaccuracies in your personal information
                    corrected;
                  </li>
                  <li>
                    if we are processing your personal information by automated
                    means and on the basis of your consent (see “How do we use
                    it?”, above), for us to provide your personal information to
                    you in a structured, commonly-used and machine-readable
                    format. You can also ask us to provide your personal
                    information directly to a third party in this format, and,
                    if technically feasible, we will do so; and
                  </li>
                  <li>
                    to have your personal information erased, or for our use of
                    it to be restricted (for example, if your preferences
                    change, or if you don’t want us to send you the information
                    you have requested).
                  </li>
                </ol>
                <p className="my-2">
                  Please contact us using the details set out below if you would
                  like to exercise any of these rights.{" "}
                </p>
                <p className="my-2">
                  Please contact us using the details set out below if you would
                  like to exercise any of these rights.{" "}
                </p>
                You also have the right to make a complaint to the supervisory
                authority if you’re not happy with how we’ve handled your
                personal information. Please refer to the data protection
                authority where you are located. In the UK, the supervisory
                authority is the Information Commissioner’s Office{" "}
                <a href="http://www.ico.org.uk" className="text-blue-600">
                  (http://www.ico.org.uk)
                </a>
                .
              </div>
            </div>

            <div className="my-2">
              <h2 className="font-bold text-2xl text-deep-navy pb-2">
                Responsible Disclosures
              </h2>
              <div>
                <p>
                  Agency appreciates and values the identification and reporting
                  of security vulnerabilities carried out by well-intentioned,
                  ethical security researchers. Please read our Responsible
                  Disclosures Policy in full.
                </p>
              </div>
            </div>
            <div className="my-2">
              <h2 className="font-bold text-2xl text-deep-navy pb-2">
                How to contact us
              </h2>
              <div>
                <p>
                  If you wish to exercise any of your rights in relation to your
                  personal information or if you have any queries about how we
                  use your personal information, please let us know by
                  contacting us at the following address: GroupM, Bay 99- A WPP
                  campus, Level 2, The ORB, IA Project Rd, next to Jw Marriott
                  Sahar, Navpada, Vile Parle East, Andheri East, Mumbai,
                  Maharashtra 400099 or by email at info@groupmdataexchange.com.
                </p>
              </div>
            </div>
            <div className="my-2">
              <h2 className="font-bold text-2xl text-deep-navy pb-2">
                Changes to this privacy policy
              </h2>
              <div>
                <p>
                  We review this privacy policy on a regular basis to ensure
                  that it is up-to-date with our use of your personal
                  information, and compliant with applicable data protection
                  laws.
                </p>
                <p className="my-2">
                  We reserve the right, at our discretion, to revise this
                  privacy policy at any time. The updated privacy policy will be
                  posted on our website. You are encouraged to review this
                  privacy policy from time to time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        onClick={() => navigate("/")}
        className="my-8 flex justify-center text-base pb-8 leading-6 text-blue-600 font-bold hover:text-gray-900 hover:underline cursor-pointer"
      >
        Back to home
      </div>
    </div>
  );
};

export default PrivacyPolicy;
