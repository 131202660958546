import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import { Alert, Box, CircularProgress, Modal } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { toast } from "react-toastify";

import API from "../../../apiServices/api";
import { account_roles, account_type_list } from "../../../utils/data";
import { validateEmail } from "../../../utils/commonFunctions";
import SelectDropdown from "../../CommonComponent/SelectDropdown";

const redirectionUser = process.env.REACT_APP_REDIRECTION_URL;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  p: 2,
  borderRadius: 5,
};

const initialState = {
  userName: "",
  email: "",
  account_type: "",
  account_member: "",
  role: [],
};

const AdminConsoleProfile = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();

  const user = state && state.user;
  const UserRole = state && state.user && state.user.role;

  const [data, setData] = useState([]);
  const [accountMemberList, setAccountMemberList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const [disableTemplate, setDisableTemplate] = useState(false);
  const handleCloseDisableTemplate = () => {
    setDisableTemplate(!disableTemplate);
  };

  const [userDetails, setUserDetails] = useState(initialState);
  const [accountMember, setAccountMember] = useState({});
  const [errors, setErrors] = useState({
    userName: null,
    email: null,
    account_type: null,
    account_member: null,
    role: null,
  });
  const [toggleDrawerPosition, setToggleDrawerPosition] = useState({
    right: false,
  });

  const fetchProfileTable = async () => {
    const payload = {
      account_name: redirectionUser,
      UserRole: UserRole?.includes("DATAEXADMIN") ? "DATAEXADMIN" : "OTHER",
      partyAccount: user?.ConsumerPartyAccount,
    };
    try {
      const response = await API.fetchUserProfile(payload);
      if (response.status === 200 && response?.data?.data) {
        setData(response?.data?.data);
      } else {
        setData([]);
      }
    } catch (error) {
      setData([]);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchProfileTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, UserRole]);

  useEffect(() => {
    if (userDetails?.account_type !== "") {
      setAccountMemberList([]);
      setUserDetails({ ...userDetails, role: [] });

      const fetchData = async () => {
        const payload = {
          account_name: redirectionUser,
          account_type: userDetails?.account_type?.toUpperCase(),
        };
        try {
          const response = await API.getMemberFromAccType(payload);
          if (response.status === 200 && response?.data?.data) {
            setAccountMemberList(response?.data?.data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails?.account_type]);

  const handleChange = (event, name) => {
    setUserDetails({
      ...userDetails,
      [name]: event,
    });
  };

  const onChangeHandler = (event) => {
    const emptyMsg = "Required field";

    const inputName = event.target.name;
    const inputValue = event.target.value;
    setSubmitError(null);

    if (inputName === "userName") {
      if (inputValue === "") {
        setErrors({ ...errors, userName: emptyMsg });
      } else {
        setErrors({ ...errors, userName: null });
      }
    } else if (inputName === "email") {
      let isEmailValid = validateEmail(inputValue);
      if (inputValue === "") {
        setErrors({ ...errors, email: emptyMsg });
      } else if (!isEmailValid) {
        setErrors({ ...errors, email: "Please enter a valid email address." });
      } else {
        setErrors({ ...errors, email: null });
      }
    } else if (inputName === "account_type") {
      if (inputValue === "") {
        setErrors({ ...errors, account_type: emptyMsg });
      } else {
        setErrors({ ...errors, account_type: null });
      }
    } else if (inputName === "account_member") {
      if (inputValue === "") {
        setErrors({ ...errors, account_member: emptyMsg });
      } else {
        setAccountMember(
          accountMemberList?.find((item) => item.USER === inputValue)
        );
        setErrors({ ...errors, account_member: null });
      }
    } else if (inputName === "role") {
      if (inputValue.length === 0) {
        setErrors({ ...errors, role: emptyMsg });
      } else {
        setErrors({ ...errors, role: null });
      }
    }
    setUserDetails({ ...userDetails, [inputName]: inputValue });
  };

  const handleRole = async (status, userName, role) => {
    setLoading(true);
    setDisableTemplate(!disableTemplate);

    const payload = {
      account_name: redirectionUser,
      role: role,
      status: status,
      user_name: userName,
    };
    try {
      const response = await API.updateUserProfile(payload);
      if (response.status === 200) {
        fetchProfileTable();
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setUserDetails({ ...initialState });
    setToggleDrawerPosition({ ...toggleDrawerPosition, [anchor]: open });
  };

  const validateForm = () => {
    const emptyMsg = "Required field";

    const newErrors = {};

    if (userDetails.userName === "") {
      newErrors.userName = emptyMsg;
    }
    if (userDetails.email === "") {
      newErrors.email = emptyMsg;
    }
    if (userDetails.account_member === "") {
      newErrors.account_member = emptyMsg;
    }
    if (userDetails.account_type === "") {
      newErrors.account_type = emptyMsg;
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const submitUser = async () => {
    const payload = {
      account_name: redirectionUser,
      user: userDetails?.userName,
      partyAccount: accountMember?.PARTY_ACCOUNT,
      provider: userDetails?.role?.includes("Provider") ? "TRUE" : "FALSE",
      consumer: userDetails?.role?.includes("Consumer") ? "TRUE" : "FALSE",
      publisher: userDetails?.role?.includes("Publisher") ? "TRUE" : "FALSE",
      dataexadmin: userDetails?.role?.includes("Dataexadmin")
        ? "TRUE"
        : "FALSE",
      account: accountMember?.ACCOUNT_NAME,
      org_name: accountMember?.ORGANIZATION_NAME,
      email: userDetails?.email,
    };
    try {
      const response = await API.insertDataInAdmin(payload);
      if (response?.status === 200 && response?.data?.data) {
        const userPayload = {
          account_name: accountMember?.ACCOUNT_NAME,
          user: userDetails?.userName,
          email: userDetails?.email,
        };
        try {
          const response = await API.insertDataInUser(userPayload);
          if (response?.status === 200 && response?.data?.data) {
            setLoading(false);
            fetchProfileTable();
            toggleDrawer("right", false);
            toast.success("User Created Successfully.");
          } else {
            setLoading(false);
            setSubmitError(
              "We are facing some issue, Please, try Again to after sometime.."
            );
          }
        } catch (error) {
          setLoading(false);
          setSubmitError(
            "We are facing some issue, Please, try Again to after sometime.."
          );
          console.error("Error fetching data:", error);
        }
      } else {
        setLoading(false);
        setSubmitError(
          "We are facing some issue, Please, try Again to after sometime.."
        );
      }
    } catch (error) {
      setLoading(false);
      setSubmitError(
        "We are facing some issue, Please, try Again to after sometime.."
      );
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmitUserRequest = async () => {
    const isFormValid = validateForm();

    if (isFormValid) {
      setLoading(true);

      const payload = {
        account_name: redirectionUser,
        user: userDetails.userName,
      };
      try {
        const response = await API.getAllUsernames(payload);
        if (response?.status === 200 && response?.data?.data) {
          let count = response?.data?.data[0]?.COUNT;
          if (count) {
            setErrors({
              ...errors,
              userName:
                "The User Name is already exist. Please use another User Name to register",
            });
            setLoading(false);
          } else {
            submitUser();
          }
        } else {
          setLoading(false);
          setSubmitError(
            "We are facing some issue, Please, try Again to after sometime.."
          );
        }
      } catch (error) {
        setLoading(false);
        setSubmitError(
          "We are facing some issue, Please, try Again to after sometime.."
        );
        console.error("Error fetching data:", error);
      }
    }
  };

  return (
    <div className="p-4 w-full">
      <div className="flex flex-row justify-between items-center w-full m-4">
        <div className="flex flex-between">
          <div
            className="text-xl font-bold text-deep-navy mr-4 cursor-pointer"
            onClick={() => navigate("/admin-console")}
          >
            <ArrowBackIcon />
          </div>
          <h1 className="text-xl font-bold text-deep-navy mr-2">
            User Profiles
          </h1>
        </div>
        {UserRole?.includes("DATAEXADMIN") &&
          ["right"].map((anchor) => (
            <React.Fragment key={anchor}>
              <button
                className="my-2 mr-4 pr-4 flex items-center justify-center rounded-md bg-deep-navy px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-electric-green hover:text-deep-navy focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-green"
                onClick={toggleDrawer(anchor, true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
                Create new user
              </button>
              {toggleDrawerPosition[anchor] && (
                <SwipeableDrawer
                  anchor={anchor}
                  open={toggleDrawerPosition[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  onOpen={toggleDrawer(anchor, true)}
                >
                  <div className="flex flex-col flex-shrink h-full w-full px-5    bg-deep-navy text-electric-green">
                    <img
                      className="absolute  w-96  bottom-1 opacity-90 z-10 right-0 text-amarant-400"
                      // src={searchillustration}
                      alt=""
                    />
                    <div
                      className=" z-20  my-4 px-4 py-2 w-96  bg-deep-navy/50"
                      name="myForm"
                    >
                      <div className="flex flex-row justify-between">
                        <h3 className="text-xl font-semibold">
                          Create New User
                        </h3>
                        <button onClick={toggleDrawer("right", false)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                      <div>
                        <div className="mt-2 pb-2 flex flex-col">
                          <label
                            htmlFor="userName"
                            className="flex text-sm font-medium leading-6 text-electric-green"
                          >
                            Username<p className="text-red-600 pl-1">*</p>
                          </label>
                          <div>
                            <input
                              id="userName"
                              type="text"
                              name="userName"
                              placeholder="e.g. aditi_nair"
                              onChange={onChangeHandler}
                              required
                              className="block w-full rounded-md border-0 py-1.5 text-electric-green bg-blend-darken bg-deep-navy shadow-sm ring-1 ring-inset ring-true-teal placeholder:text-true-teal focus:ring-2 focus:ring-inset focus:ring-electric-green sm:text-sm sm:leading-6"
                            />
                            {errors?.userName && errors?.userName !== null ? (
                              <span className="text-[#f44336] text-sm">
                                {errors.userName}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="mt-2 pb-2 flex flex-col">
                          <label
                            htmlFor="email"
                            className="flex text-sm font-medium leading-6 text-electric-green"
                          >
                            Email Id<p className="text-red-600 pl-1">*</p>
                          </label>
                          <div>
                            <input
                              type="text"
                              name="email"
                              placeholder="e.g. aditi.nair@groupm.com"
                              onChange={onChangeHandler}
                              required
                              className="block w-full rounded-md border-0 py-1.5 text-electric-green bg-blend-darken bg-deep-navy shadow-sm ring-1 ring-inset ring-true-teal placeholder:text-true-teal focus:ring-2 focus:ring-inset focus:ring-electric-green sm:text-sm sm:leading-6"
                            />
                            {errors?.email && errors?.email !== null ? (
                              <span className="text-[#f44336] text-sm">
                                {errors.email}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="mt-2 pb-2 flex flex-col">
                          <label>Account Type</label>
                          <select
                            id="account_type"
                            name="account_type"
                            required
                            className="block w-full rounded-md border-0 py-1.5 text-electric-green  bg-deep-navy shadow-sm ring-1 ring-inset ring-true-teal placeholder:text-true-teal focus:ring-2 focus:ring-inset focus:ring-electric-green sm:text-sm sm:leading-6"
                            value={userDetails.account_type}
                            onChange={onChangeHandler}
                          >
                            <option value="">select a Account Type</option>
                            {account_type_list?.length > 0 ? (
                              account_type_list?.map((item, index) => (
                                <option key={index} value={item.value}>
                                  {item.name}
                                </option>
                              ))
                            ) : (
                              <option value="">Loading...</option>
                            )}
                          </select>
                          {errors?.account_type &&
                          errors?.account_type !== null ? (
                            <span className="text-[#f44336] text-sm">
                              {errors.account_type}
                            </span>
                          ) : null}
                        </div>
                        <div className="mt-2 pb-21 flex flex-col">
                          <label>Account Member</label>
                          <select
                            name="account_member"
                            onChange={onChangeHandler}
                            required
                            value={userDetails.account_member}
                            className="block w-full rounded-md border-0 py-1.5 text-electric-green  bg-deep-navy shadow-sm ring-1 ring-inset ring-true-teal placeholder:text-true-teal focus:ring-2 focus:ring-inset focus:ring-electric-green sm:text-sm sm:leading-6"
                          >
                            <option value="">please select</option>
                            {accountMemberList?.length > 0 ? (
                              accountMemberList?.map((item, index) => (
                                <option key={index} value={item.USER}>
                                  {item.ORGANIZATION_NAME + " - " + item.USER}
                                </option>
                              ))
                            ) : (
                              <option value="">Loading...</option>
                            )}
                          </select>
                          {errors?.account_member &&
                          errors?.account_member !== null ? (
                            <span className="text-[#f44336] text-sm">
                              {errors.account_member}
                            </span>
                          ) : null}
                        </div>
                        <div className="mt-2 pb-2 flex flex-col">
                          <SelectDropdown
                            title="Role"
                            mode="multiple"
                            name="role"
                            value={userDetails?.role}
                            placeholder="select roles"
                            data={account_roles[userDetails?.account_type]}
                            setValue={(e, value) => {
                              handleChange(e, value);
                            }}
                          />
                        </div>

                        <div className="flex justify-end">
                          {loading ? (
                            <div className="flex w-full justify-center rounded-md bg-electric-green px-3 py-1.5 text-sm font-semibold leading-6 text-deep-navy shadow-sm hover:bg-true-teal focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-green mt-4">
                              <CircularProgress
                                style={{
                                  width: "24px",
                                  height: "24px",
                                  color: "#FFFFFF",
                                }}
                              />
                            </div>
                          ) : (
                            <button
                              className="flex w-full justify-center rounded-md bg-electric-green px-3 py-1.5 text-sm font-semibold leading-6 text-deep-navy shadow-sm hover:bg-true-teal focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-green mt-4"
                              type="submit"
                              onClick={handleSubmitUserRequest}
                            >
                              Register User
                            </button>
                          )}
                        </div>
                        {/* for showing error */}
                        <div className="my-4">
                          {submitError && (
                            <Alert className="text-red-600" severity="error">
                              {submitError}
                            </Alert>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </SwipeableDrawer>
              )}
            </React.Fragment>
          ))}
      </div>
      {UserRole?.includes("DATAEXADMIN") ? <p>The data admin possesses the ability to grant or revoke permissions for any user, as well as the authority to create new user accounts.</p>
      :<p>Advertisers with administrative privileges can access permission details for both their own and subuser accounts.</p>}
      {/* <p> sub title</p> */}
      <TableContainer className="mt-4">
        <Table
          sx={{ minWidth: 650, borderRadius: 0 }}
          stickyHeader
          size="small"
          classes={{ root: "w-100" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow
              sx={{
                "& th": {
                  fontSize: "0.9rem",
                  fontWeight: 900,
                  color: "#0A2756",
                  backgroundColor: "#e8effb",
                  borderRadius: 0,
                  borderTop: 1,
                  borderLeft: 1,
                  borderColor: "#d6d3d1",
                },
                "& th:first-of-type": {
                  borderLeft: 1,
                  borderColor: "#d6d3d1",
                },
                "& th:last-child": {
                  borderRight: 1,
                  borderColor: "#d6d3d1",
                },
              }}
            >
              <TableCell key={0} align="center">
                User
              </TableCell>
              <TableCell key={1} align="center">
                Organization Name
              </TableCell>
              <TableCell key={2} align="center">
                {/* Consumer Advanced */}
                Advanced Advertiser(Customer Enrichment)
              </TableCell>
              <TableCell key={3} align="center">
                {/* Consumer Basic */}
                Basic Advertiser(Match Rate)
              </TableCell>
              <TableCell key={4} align="center">
                data partner
              </TableCell>
              <TableCell key={5} align="center">
                Admin
              </TableCell>
            </TableRow>
          </TableHead>
          {UserRole?.includes("DATAEXADMIN") ? (
            <TableBody>
              {data?.map((row, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{
                      "& td": {
                        borderLeft: 1,
                        borderColor: "#d6d3d1",
                        color: "#0A2756",
                      },
                      "& td:last-child": {
                        borderRight: 1,
                        borderColor: "#d6d3d1",
                      },
                    }}
                  >
                    <TableCell align="center">{row?.USER}</TableCell>
                    <TableCell align="center">
                      {row?.ORGANIZATION_NAME}
                    </TableCell>

                    <TableCell align="center">
                      {row.PROVIDER.toLowerCase() !== "true" ? (
                        <Switch
                          checked={row.CONSUMER.toLowerCase() === "true"}
                          onChange={() =>
                            handleRole(
                              row.CONSUMER.toLowerCase() === "true"
                                ? "FALSE"
                                : "TRUE",
                              row.USER,
                              "CONSUMER"
                            )
                          }
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      ) : (
                        <div className="flex justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M19.5 12h-15"
                            />
                          </svg>
                        </div>
                      )}
                    </TableCell>

                    <TableCell align="center">
                      {row.PROVIDER.toLowerCase() !== "true" ? (
                        <Switch
                          checked={row.PUBLISHER.toLowerCase() === "true"}
                          onChange={() =>
                            handleRole(
                              row.PUBLISHER.toLowerCase() === "true"
                                ? "FALSE"
                                : "TRUE",
                              row.USER,
                              "PUBLISHER"
                            )
                          }
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      ) : (
                        <div className="flex justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M19.5 12h-15"
                            />
                          </svg>
                        </div>
                      )}
                    </TableCell>

                    <TableCell align="center">
                      {row.CONSUMER.toLowerCase() !== "true" ||
                      row.CONSUMER.toLowerCase() !== "true" ? (
                        <Switch
                          checked={row.PROVIDER.toLowerCase() === "true"}
                          onChange={() =>
                            handleRole(
                              row.PROVIDER.toLowerCase() === "true"
                                ? "FALSE"
                                : "TRUE",
                              row.USER,
                              "PROVIDER"
                            )
                          }
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      ) : (
                        <div className="flex justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M19.5 12h-15"
                            />
                          </svg>
                        </div>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {/* row.ADMIN.toLowerCase() === "true" */}
                      {row.ADMIN.toLowerCase() === "true" ? (
                        <div className="flex justify-center">
                          <CheckCircleSharpIcon className="w-8 h-8" />
                        </div>
                      ) : (
                        <div className="flex justify-center">
                          <CancelSharpIcon className="w-8 h-8" />
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              {data?.map((row, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{
                      "& td": {
                        borderLeft: 1,
                        borderColor: "#d6d3d1",
                        color: "#0A2756",
                      },
                      "& td:last-child": {
                        borderRight: 1,
                        borderColor: "#d6d3d1",
                      },
                    }}
                  >
                    <TableCell align="center">{row?.USER}</TableCell>
                    <TableCell align="center">
                      {row?.ORGANIZATION_NAME}
                    </TableCell>

                    <TableCell align="center">
                      {row.CONSUMER.toLowerCase() === "true" ? (
                        <div className="flex justify-center">
                          <CheckCircleSharpIcon className="w-8 h-8" />
                        </div>
                      ) : (
                        <div className="flex justify-center">
                          <CancelSharpIcon className="w-8 h-8" />
                        </div>
                      )}
                    </TableCell>

                    <TableCell align="center">
                      {row.PUBLISHER.toLowerCase() === "true" ? (
                        <div className="flex justify-center">
                          <CheckCircleSharpIcon className="w-8 h-8" />
                        </div>
                      ) : (
                        <div className="flex justify-center">
                          <CancelSharpIcon className="w-8 h-8" />
                        </div>
                      )}
                    </TableCell>

                    <TableCell align="center">
                      {row.PROVIDER.toLowerCase() === "true" ? (
                        <div className="flex justify-center">
                          <CheckCircleSharpIcon className="w-8 h-8" />
                        </div>
                      ) : (
                        <div className="flex justify-center">
                          <CancelSharpIcon className="w-8 h-8" />
                        </div>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {row.ADMIN.toLowerCase() === "true" ? (
                        <div className="flex justify-center">
                          <CheckCircleSharpIcon className="w-8 h-8" />
                        </div>
                      ) : (
                        <div className="flex justify-center">
                          <CancelSharpIcon className="w-8 h-8" />
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Modal
        open={disableTemplate}
        onClose={handleCloseDisableTemplate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {loading ? (
          <Box sx={style}>
            <div className="flex flex-row items-center justify-between sticky z-30 py-2 px-4 top-0 w-full text-amaranth-600">
              <CircularProgress
                style={{
                  width: "24px",
                  height: "24px",
                  color: "amaranth-600",
                }}
              />
              <h3 className="font-bold text-amaranth-600 p-4">
                Wait we are changing the role....
              </h3>
              <button onClick={handleCloseDisableTemplate}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5"
                >
                  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
              </button>
            </div>
          </Box>
        ) : (
          <Box sx={style}>
            <div className="flex flex-row items-center justify-between sticky z-30 py-2 px-4 top-0 w-full text-deep-navy">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <h3 className="font-bold text-deep-navy p-4">
                Role has been changed.
              </h3>
              <button onClick={handleCloseDisableTemplate}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5"
                >
                  <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                </svg>
              </button>
            </div>
          </Box>
        )}
      </Modal>
    </div>
  );
};

export default AdminConsoleProfile;
