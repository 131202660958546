import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// import HTWLogo from "../../../Assets/Logos/Data_Haven_Logo.svg";
// import Beam from "../../../Assets/beams-basic.png";

const TermsAndConditions = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div class="relative overflow-hidden">
        <img
          //   src={Beam}
          alt=""
          class="absolute left-1/2 top-0 -ml-[39rem] w-[113.125rem] max-w-none"
        />
        <div class="px-4 sm:px-6 lg:px-8">
          <div class="relative mx-auto max-w-[37.5rem] py-20 text-center">
            <h1 class="text-4xl font-extrabold tracking-tight text-slate-900 sm:text-5xl font-['HelveticaNeue-Bold']">
              Terms of use
            </h1>
          </div>
        </div>
        <div class="relative px-4 sm:px-6 lg:px-8 text-deep-navy">
          <div class="prose-sm prose prose-slate prose-a:font-semibold prose-a:text-sky-500 hover:prose-a:text-sky-600 mx-auto max-w-[48rem]">
            <div className="mt-6 text-deep-navy">
              <h2 className="font-bold text-2xl pb-2 ">
                1. By using our GroupM Data Exchange, you accept these terms
              </h2>
              <p className="ml-6  text-lg">
                By using our GroupM Data Exchange, you confirm that you accept
                these terms and conditions and agree to be bound by them. If you
                do not agree to abide by these conditions of use you should not
                use the GroupM Data Exchange in any way.
              </p>
            </div>

            <div className="mt-6 text-deep-navy">
              <h2 className="font-bold text-2xl pb-2 ">
                2. There are other terms that may apply to you
              </h2>
              <p className="ml-6  text-lg">
                These terms and conditions refer to the following additional
                terms, which also apply to your use of our GroupM Data Exchange
                which are set out in in our{" "}
                <a href="/privacy-policy" className="text-blue-600">
                  Privacy Policy.
                </a>{" "}
                We may make changes to these terms. We reserve the right to vary
                the conditions of use at any time and will post any variations
                here. You are advised to review the conditions of use on a
                regular basis as you will be deemed to have accepted variations
                if you continue to use the GroupM Data Exchange after they have
                been posted.
              </p>
            </div>

            <div className="mt-6 text-deep-navy">
              <h2 className="font-bold text-2xl pb-2 ">
                3. Information contained on the GroupM Data Exchange
              </h2>
              <p className="ml-6  text-lg">
                All information on the GroupM Data Exchange is supplied by the
                participating Business entities but neither Group M nor the
                business entities can guarantee or can be held liable for its
                accuracy or timeliness and we may change the information at any
                time without notice. You must not rely on the information of the
                GroupM Data Exchange as the same is subject to update always and
                you acknowledge that you must take appropriate steps to verify
                this information before acting on it.  Any reference to any
                product or service which has been or may be provided by GroupM
                or any other company does not amount to a promise that such
                product or service will be available at any time. Changes to or
                improvements in such products or services may be made at any
                time without notice.
              </p>
            </div>
            <div className="mt-6 text-deep-navy">
              <h2 className="font-bold text-2xl pb-2 ">
                4. USE OF GROUPM DATA EXCHANGE
              </h2>
              <p className="ml-6  text-lg">
                Your use of GroupM Data Exchange is subject to all agreements
                such as a license agreement or user agreement if applicable that
                accompanies or is included with the GroupM Data Exchange,
                ordering documents, exhibits, and other terms and conditions
                that apply ("License Terms") You may use the GroupM Data
                Exchange subject to the following: (a) the GroupM Data Exchange
                may be used solely for your personal, informational,
                non-commercial purposes; (b) the GroupM Data Exchange may not be
                modified or altered in any way; and (c) the GroupM Data Exchange
                may not be redistributed.
              </p>
            </div>
            <div className="mt-6 text-deep-navy">
              <h2 className="font-bold text-2xl pb-2 ">5. No Warranty</h2>
              <p className="ml-6  text-lg">
                The GroupM Data Exchange “as is” without any warranty of any
                kind, express or implied, as to the operation of the GroupM Data
                Exchange, the accuracy of the GroupM Data Exchange(in so far as
                such warranties may be excluded under any relevant law) and to
                the extent permitted by law, GroupM shall not be liable for any
                losses or damage whether direct or indirect (including, without
                limitation direct or indirect loss of profits), consequential,
                special or otherwise incidental that may result from use of the
                website howsoever arising.
              </p>
            </div>
            <div className="mt-6 text-deep-navy">
              <h2 className="font-bold text-2xl pb-2 ">
                6. How we may use your personal information
              </h2>
              <p className="ml-6  text-lg">
                We will only use your personal information as set out in our{" "}
                <a href="/privacy-policy" className="text-blue-600">
                  Privacy Policy
                </a>
                .
              </p>
            </div>
            <div className="mt-6 text-deep-navy">
              <h2 className="font-bold text-2xl pb-2 ">
                7. Security, Passwords and Means of Accessing the Site and
                Content{" "}
              </h2>
              <p className="ml-6  text-lg">
                You agree not to access or use the GroupM Data Exchange in any
                manner that could damage, disable, overburden, or impair any
                systems or networks. You agree not to attempt to gain
                unauthorized access to any parts of the GroupM Data Exchange or
                any accounts for accessing the GroupM Data Exchange, computer
                systems or networks. You agree not to interfere or attempt to
                interfere with the proper working of the GroupM Data Exchange,
                computer systems or networks. You agree not to use any robot,
                spider, scraper or other automated means to access the GroupM
                Data Exchange or any accounts, computer systems or networks
                without GroupM’s express written permission. If any of the
                Services require you to open an account, you must complete the
                registration process by providing GroupM with current, complete
                and accurate information as required by the applicable
                registration form. You may also be required to choose a password
                and a user name. Access to and use of password protected or
                secure access to the GroupM Data Exchange are restricted to
                authorized users only. You agree not to share your password(s),
                account information, or access to the GroupM Data Exchange with
                any other person. You are responsible for maintaining the
                confidentiality of password(s) and account information, and you
                are responsible for all activities that occur through the use of
                your password(s) or account(s) or as a result of your access to
                the GroupM Data Exchange. You agree to notify GroupM immediately
                of any use of your password(s) or account(s) that you did not
                authorize or that is not authorized by these Terms of Use.
              </p>
            </div>
            <div className="mt-6 text-deep-navy">
              <h2 className="font-bold text-2xl pb-2 ">
                8. No Unlawful or Prohibited Use
              </h2>
              <p className="ml-6  text-lg">
                You agree not to use the GroupM Data Exchange, or Content
                provided on or through the GroupM Data Exchange, for any purpose
                that is unlawful or prohibited by these Terms of Use or the
                rules, guidelines or terms of use posted for a specific area of
                the GroupM Data Exchange or content provided on or through the
                GroupM Data Exchange.
              </p>
            </div>
            <div className="mt-6 text-deep-navy">
              <h2 className="font-bold text-2xl pb-2 ">9. Indemnity</h2>
              <p className="ml-6  text-lg">
                You agree to indemnify and hold harmless GroupM, its officers,
                directors, employees and agents from and against any and all
                claims, liabilities, damages, losses or expenses, including
                reasonable attorneys' fees and costs, due to or arising out of
                content that you share, your violation of these Terms of Use or
                any additional rules, guidelines or terms of use posted for a
                specific usage of the GroupM Data Exchange , or your violation
                or infringement of any third party rights, including
                intellectual property rights.
              </p>
            </div>
            <div className="mt-6 text-deep-navy">
              <h2 className="font-bold text-2xl pb-2 ">
                10. Termination of Use
              </h2>
              <p className="ml-6  text-lg">
                GroupM may, in its sole discretion, at any time discontinue
                providing or limit access to the GroupM Data Exchange. You agree
                that GroupM may, in its sole discretion, at any time, terminate
                or limit your access to, or use of the GroupM Data Exchange.
                Oracle may terminate or limit your access to or use of the
                GroupM Data Exchange determines, in its sole discretion, that
                you have infringed the copyrights of a third party. You agree
                that GroupM shall not be liable to you or any third-party for
                any termination or limitation of your access to, or use of the
                GroupM Data Exchange.
              </p>
            </div>
            <div className="mt-6 text-deep-navy">
              <h2 className="font-bold text-2xl pb-2 ">
                11. Intellectual Property
              </h2>
              <p className="ml-6  text-lg">
                More information on making claims of copyright infringement.
                Unauthorized copying, distribution, modification, public
                display, or public performance of copyrighted works may be an
                infringement of the rights of the copyright owner. You agree
                that you will not use the GroupM Data Exchange to infringe
                GroupM’s Intellectual Property rights or the Intellectual
                Property rights of others. You may not remove, deface, or
                overprint any notice of copyright, trademark, logo, or other
                notice of ownership from any originals or copies of GroupM Data
                Exchange, third party GroupM Data Exchange, or any products or
                content you access on or through GroupM’s GroupM Data Exchange
                and cannot be held liable for any loss or damage you incur as a
                result thereof.
              </p>
            </div>
            <div className="mt-6 text-deep-navy">
              <h2 className="font-bold text-2xl pb-2 ">12. Governing Law </h2>
              <p className="ml-6  text-lg">
                These conditions of use are governed by the laws of India and
                you agree that Indian courts shall have exclusive jurisdiction
                in any dispute.  To the extent that any part of these conditions
                of use is found to be invalid, unlawful or unenforceable by any
                court of competent jurisdiction such part shall to that extent
                be severed from the remaining terms all of which shall remain in
                full force and effect as permitted by law.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        onClick={() => navigate("/")}
        className="my-8 flex justify-center text-base pb-8 leading-6 text-blue-600 font-bold hover:text-gray-900 hover:underline cursor-pointer"
      >
        Back to home
      </div>
    </div>
  );
};

export default TermsAndConditions;
