import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import md5 from "md5";
import { toast } from "react-toastify";

import { CircularProgress } from "@mui/material";
import API from "../../../apiServices/api";
import { validatePassword } from "../../../utils/commonFunctions";

const redirectionUser = process.env.REACT_APP_REDIRECTION_URL;

const MyProfile = () => {
  const state = useSelector((state) => state);
  const user = state && state.user;

  const [profileData, setProfileData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [originalPassword, setOriginalPassword] = useState("");
  const [verify, setVerify] = useState(false);

  const [details, setDetails] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    password: null,
    confirmPassword: null,
  });
  const [passwordNote, setPasswordNote] = useState("");

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const getProfileData = async () => {
      const payload = {
        account_name: redirectionUser,
        user: user?.name,
      };

      try {
        const response = await API.getProfilePageData(payload);
        if (response?.status === 200 && response?.data?.data) {
          setProfileData(response?.data?.data);
        } else {
          setProfileData([]);
        }
      } catch (error) {
        console.error("Error while fetching data:", error);
      }
    };
    getProfileData();
  }, [user?.name, user?.account_name]);

  // To get data of that user
  useEffect(() => {
    const getUserData = async () => {
      const payload = {
        account_name: user?.account_name,
        user_name: user?.name,
      };

      try {
        const response = await API.getUserDataResetPass(payload);
        if (response?.status === 200 && response?.data?.data) {
          let data = response?.data?.data[0];
          setUserData(data);
        } else {
          setUserData({});
        }
      } catch (error) {
        console.error("Error while fetching data:", error);
      }
    };
    getUserData();
  }, [user?.name, user?.account_name]);

  const handlePasswordChange = (event) => {
    const { name, value } = event.target;
    const emptyMsg = "Required field";

    if (name === "password") {
      let isPasswordValid = validatePassword(value);
      if (value === "") {
        setErrors({ ...errors, password: emptyMsg });
      } else if (!isPasswordValid) {
        setPasswordNote(
          "Password must be between 8 to 16 characters long with Characters(Uppercase or Lowercase) and numbers. No special characters are allowed."
        );
        let passErrorMsg = "Please check the note below";
        setErrors({ ...errors, password: passErrorMsg });
      } else if (
        details.confirmPassword !== "" &&
        details.confirmPassword !== value
      ) {
        setErrors({
          ...errors,
          password: null,
          confirmPassword: "Password doesn't match",
        });
        setPasswordNote("");
      } else {
        setErrors({ ...errors, password: null, confirmPassword: null });
        setPasswordNote("");
      }
    } else if (name === "confirmPassword") {
      if (value === "") {
        setErrors({ ...errors, confirmPassword: emptyMsg });
      } else if (details.password !== value) {
        setErrors({ ...errors, confirmPassword: "Password doesn't match" });
      } else {
        setErrors({ ...errors, confirmPassword: null });
      }
    }
    setDetails({ ...details, [name]: value });
  };

  const validateForm = () => {
    const emptyMsg = "Required field";

    const newErrors = {};

    if (details.password === "") {
      newErrors.password = emptyMsg;
    }
    if (details.confirmPassword === "") {
      newErrors.confirmPassword = emptyMsg;
    }
    if (
      details.password !== details.confirmPassword ||
      errors.confirmPassword !== null
    ) {
      newErrors.confirmPassword = "Password doesn't match";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleVerifyPassword = (event) => {
    event.preventDefault();
    if (md5(originalPassword) === userData?.PASSWORD) {
      setVerify(true);
      toast.success("Password Verified Successfully");
    } else {
      setVerify(false);
      toast.error("Please enter correct password");
    }
  };
   console.log(userData?.PASSWORD);
   console.log(md5(originalPassword));

  const sendEmailtoUser = async () => {
    const payload = {
      mailusedfor: "reset_password",
      recipient: userData?.EMAIL,
      user_name: userData?.USER,
    };
    try {
      const response = await API.mailtoadmin(payload);
      if (response.status === 200) {
        setLoader(false);
        toast.success("Password updated successfully.");
        setDetails({ ...details, password: "", confirmPassword: "" });
        setVerify(false);
        setOriginalPassword("");
      } else {
        setLoader(false);
        toast.error(
          "Password updated successfully, We are facing some issue to send an email."
        );
      }
    } catch (error) {
      setLoader(false);
      toast.error(
        "Password updated successfully, We are facing some issue to send an email."
      );

      console.error("Error fetching data:", error);
    }
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();
    const isFormValid = validateForm();

    if (isFormValid) {
      setLoader(true);
      const payload = {
        account_name: user?.account_name,
        user: user?.name,
        password: md5(details.confirmPassword),
      };

      try {
        const response = await API.resetPassword(payload);
        if (response?.status === 200 && response?.data) {
          sendEmailtoUser();
        } else {
          toast.error("Something went wrong, please try after sometime.");
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);

        toast.error("Something went wrong, please try after sometime.");
        console.error("Error while fetching data:", error);
      }
    }
  };

  return (
    <div className="flex flex-col flex-grow w-full h-full bg-gray-50">
      <div className="flex h-12 sticky top-0 px-5  py-2 text-deep-navy flex-row items-center justify-between w-full">
        <h3 className="text-xl font-bold">My profile</h3>
      </div>
      <div className="mx-auto my-auto min-w-[50rem] max-w-3xl mt-8">
        <form>
          <div className="w-full bg-white rounded-lg mx-auto mt-8 flex overflow-hidden rounded-b-none  shadow-sm">
            <div className="w-1/2 bg-amaranth-50 p-8 hidden md:inline-block">
              <h2 className="font-bold text-base text-deep-navy mb-4 tracking-wide">
                User Details
              </h2>
              <dl className="divide-y divide-gray-100">
                <div className="flex flex-col px-3 py-2 sm:px-0">
                  <dt className="text-sm font-bold leading-6 text-gray-900">
                    Username
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-deep-navy sm:col-span-2 sm:mt-0">
                    {profileData ? profileData[0]?.USER : "Loading..."}
                  </dd>
                </div>
                <div className="flex flex-col px-3 py-2 sm:px-0">
                  <dt className="text-sm font-bold leading-6 text-gray-900">
                    Email Id
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-deep-navy sm:col-span-2 sm:mt-0">
                    {profileData ? profileData[0]?.EMAIL : "Loading..."}
                  </dd>
                </div>
                <div className="flex flex-col px-3 py-2 sm:px-0">
                  <dt className="text-sm font-bold leading-6 text-gray-900">
                    Company Name
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-deep-navy sm:col-span-2 sm:mt-0">
                    {profileData ? profileData[0]?.ORGANIZATION_NAME : "Loading..."}
                  </dd>
                </div>
                {/* <div className="flex flex-col px-3 py-2 sm:px-0">
                  <dt className="text-sm font-bold leading-6 text-gray-900">
                    Designation
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-deep-navy sm:col-span-2 sm:mt-0">
                    {profileData ? profileData[0]?.DESIGNATION : "Loading..."}
                  </dd>
                </div> */}
              </dl>
            </div>

            {!verify ? (
              <div className="md:w-1/2 w-full ">
                <div className="my-8">
                  <h2 className="font-base text-xl flex justify-center font-bold text-deep-navy mb-4 tracking-wide">
                    Verify current password
                  </h2>
                  <h2 className="font-base text-md px-8 font-bold text-deep-navy mb-4 tracking-wide">
                    To reset your password please verify it first.
                  </h2>
                </div>
                <div className="px-16">
                  <label for="name" className="text-sm text-deep-navy">
                    Enter password
                  </label>
                  <input
                    className="block w-full rounded-md border-0 py-1.5 text-deep-navy  bg-blend-darken shadow-sm ring-1 ring-inset ring-deep-navy  placeholder:text-deep-navy  focus:ring-2 focus:ring-inset focus:ring-deep-navy  sm:text-sm sm:leading-6"
                    type="password"
                    value={originalPassword}
                    name="originPassword"
                    onChange={(e) => setOriginalPassword(e.target.value)}
                  />
                  <div className="py-2">
                    {errors.password !== "" ? (
                      <span className="text-red-600">{errors.password}</span>
                    ) : null}
                  </div>
                </div>
                <div className="flex justify-center">
                  <button
                    className="rounded-md px-4 py-2 mb-4 text-white bg-deep-navy  text-base hover:bg-electric-green hover:text-deep-navy text-center font-semibold"
                    onClick={handleVerifyPassword}
                  >
                    Verify
                  </button>
                </div>
              </div>
            ) : (
              <div className="md:w-1/2 w-full ">
                <h2 className="py-8 flex justify-center font-base text-xl font-bold text-deep-navy mb-4 tracking-wide">
                  Change password
                </h2>
                <div className=" px-16">
                  <label for="name" className="text-sm text-deep-navy">
                    New password
                  </label>
                  <input
                    className="block w-full rounded-md border-0 py-1.5 text-deep-navy  bg-blend-darken shadow-sm ring-1 ring-inset ring-deep-navy  placeholder:text-deep-navy  focus:ring-2 focus:ring-inset focus:ring-deep-navy  sm:text-sm sm:leading-6"
                    type="password"
                    value={details?.password}
                    name="password"
                    onChange={handlePasswordChange}
                  />
                  <div className="py-2">
                    {errors.password !== "" ? (
                      <span className="text-red-600">{errors.password}</span>
                    ) : null}
                  </div>
                </div>
                <div className="px-16">
                  <label for="email" className="text-sm text-deep-navy">
                    Confirm password
                  </label>
                  <input
                    className="block w-full rounded-md border-0 py-1.5 text-deep-navy bg-blend-darken shadow-sm ring-1 ring-inset ring-deep-navy  placeholder:text-deep-navy  focus:ring-2 focus:ring-inset focus:ring-deep-navy  sm:text-sm sm:leading-6"
                    type="password"
                    name="confirmPassword"
                    value={details?.confirmPassword}
                    onChange={handlePasswordChange}
                  />
                  <div className="py-2">
                    {errors.confirmPassword !== "" ? (
                      <span className="text-red-600">
                        {errors.confirmPassword}
                      </span>
                    ) : null}
                  </div>
                </div>
                {loader ? (
                  <div className="flex justify-center mx-16 rounded-md px-2 py-2  text-white bg-deep-navy  text-lg hover:bg-electric-green hover:text-deep-navy text-center font-semibold">
                    <CircularProgress
                      style={{
                        width: "24px",
                        height: "24px",
                        color: "#FFFFFF",
                      }}
                    />
                  </div>
                ) : (
                  <div className="flex justify-center">
                    <button
                      className="rounded-md px-4 py-2 mb-4 text-white bg-deep-navy  text-base hover:bg-electric-green hover:text-deep-navy text-center font-semibold"
                      onClick={handleResetPassword}
                    >
                      Reset Password
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </form>
        <div className="py-2">
          {passwordNote !== "" ? (
            <span className="text-red-600">
              <strong>Password Note : </strong>
              {passwordNote}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
