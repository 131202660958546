import React, { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import { Alert, CircularProgress } from "@mui/material";
import md5 from "md5";
import axios from "axios";

import * as actions from "../../../redux/actions/index";
import BgVideo from "../../../Assets/loginbg.mp4";
import BgVideoGreen from "../../../Assets/loginbg_green.mp4";
import API from "../../../apiServices/api";

const redirectionUser = process.env.REACT_APP_REDIRECTION_URL;
const reactAppAuthURL = process.env.REACT_APP_BASE_URL_AUTH;
const reactAppAuthAccessToken = process.env.REACT_APP_AUTH_ACCESS_TOKEN;

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginDetails, setLoginDetails] = useState({
    userName: "",
    password: "",
  });

  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [privacyCheckError, setPrivacyCheckError] = useState(false);

  const [errors, setErrors] = useState({
    userName: null,
    password: null,
    message: "",
  });
  const [loginError, setLoginError] = useState("");

  const [loading, setLoading] = useState(false);

  // Show toaster when Access token has expired..
  const loginErrorMessage = localStorage.getItem("loginErrorMessage");

  if (loginErrorMessage) {
    toast.error(loginErrorMessage);
    localStorage.removeItem("loginErrorMessage");
  }

  const handleOnChange = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    setLoginError("");
    if (inputName === "userName") {
      if (inputValue === "") {
        setErrors({ ...errors, userName: "Please enter User Name" });
      } else {
        setErrors({ ...errors, userName: null });
      }
    } else if (inputName === "password") {
      if (inputValue === "") {
        setErrors({ ...errors, password: "Please enter password" });
      } else {
        setErrors({ ...errors, password: null });
      }
    }
    setLoginDetails({ ...loginDetails, [inputName]: inputValue });
  };

  const getAllConsumers = async (userRole, partyAccount) => {
    const payload = {
      account_name: redirectionUser,
      user: loginDetails?.userName,
    };
    try {
      const response = await API.getAdminPartyAccountApi(payload);
      if (response?.status === 200 && response?.data?.data) {
        let data_consumer = response?.data?.data?.[0];

        const payload = {
          account_name: data_consumer?.ACCOUNT_NAME,
          user: loginDetails?.userName,
          password: md5(loginDetails?.password),
        };
        // to check user is blocked or not...
        try {
          const response = await API.checkBlockedUser(payload);
          if (
            response?.status === 200 &&
            response?.data?.data &&
            response?.data?.data?.toUpperCase() === "TRUE"
          ) {
            try {
              const response = await API.getAuthorisationApi(payload);
              if (response?.status === 200 && response?.data?.data) {
                if (parseInt(response?.data?.data[0]?.COUNT) === 1) {
                  const payload = {
                    account_name: redirectionUser,
                    user: loginDetails?.userName,
                  };
                  try {
                    const response = await API.getAuthorisedUserDetailsApi(
                      payload
                    );
                    if (response?.status === 200 && response?.data?.data) {
                      let data = response?.data?.data; // Find user login info]
                      if (data?.length > 0) {
                        const userData = data && data[0];

                        const userRole = [];
                        if (userData?.PUBLISHER?.toLowerCase() === "true") {
                          userRole.push("Publisher");
                        }
                        if (userData?.PROVIDER?.toLowerCase() === "true") {
                          userRole.push("Provider");
                        }
                        if (userData?.CONSUMER?.toLowerCase() === "true") {
                          userRole.push("Consumer");
                        }
                        if (
                          userData?.PROVIDER?.toLowerCase() === "true" &&
                          userData?.ADMIN?.toLowerCase() === "true"
                        ) {
                          userRole.push("Provider_Admin");
                        }
                        if (userData?.DATAEXADMIN?.toLowerCase() === "true") {
                          userRole.push("DATAEXADMIN");
                        }
                        if (
                          userData?.BASIC_PUBLISHER?.toLowerCase() === "true"
                        ) {
                          userRole.push("BASIC_PUBLISHER");
                        }
                        if (
                          userData?.CONSUMER?.toLowerCase() === "true" &&
                          userData?.ADMIN?.toLowerCase() === "true"
                        ) {
                          userRole.push("Consumer_Admin");
                        }
                        if (
                          userData?.PUBLISHER?.toLowerCase() === "true" &&
                          userData?.ADMIN?.toLowerCase() === "true"
                        ) {
                          userRole.push("Consumer_Admin");
                        }
                        dispatch(
                          actions.loginRequest({
                            isLoggedIn: true,
                            name: userData?.USER,
                            role: userRole,
                            ConsumerPartyAccount: userData?.PARTY_ACCOUNT,
                            account_name: userData?.ACCOUNT_NAME,
                            user_name: userData?.USER,
                            organization_name: userData?.ORGANIZATION_NAME,
                            consumerDBName: `DCR_${userData?.PARTY_ACCOUNT}_CONSUMER`,
                            providerDBName: `DCR_${userData?.PARTY_ACCOUNT}_PROVIDER_DB`,
                          })
                        );
                        navigate("/home");
                      } else {
                        // Username not found
                        setLoading(false);
                        setLoginError("Invalid Credentials");
                        toast.error(
                          "You entered an incorrect username, password or both."
                        );
                      }
                    } else {
                      setLoginError("Invalid Credentials");
                      setLoading(false);
                    }
                  } catch (error) {
                    setLoginError("Invalid Credentials");
                    setLoading(false);
                  }
                } else {
                  setLoginError(response?.data?.data);
                  setLoading(false);
                }
              } else {
                setLoginError("Invalid Credentials");
                setLoading(false);
              }
            } catch (error) {
              setLoginError(
                "We are facing some issue in Login. Please try again after sometime"
              );
              setLoading(false);
            }
          } else if (response?.status === 200 && response?.data?.data) {
            setLoginError(response?.data?.data);
            setLoading(false);
          } else {
            setLoginError(
              "We are facing some issue in Login. Please try again after sometime"
            );
            setLoading(false);
          }
        } catch (error) {
          setLoginError(
            "We are facing some issue in Login. Please try again after sometime"
          );
          setLoading(false);
        }
      } else {
        setLoading(false);
        setLoginError("We are facing issue. Please try again after sometime!!");
      }
    } catch (error) {
      setLoading(false);
      setLoginError("We are facing issue. Please try again after sometime!!");
      console.log("We are facing issue. Please try again after sometime!!");
    }
  };

  const handleTermsChange = () => {
    setIsTermsChecked((prev) => !prev);
  };

  const handleSubmit = async (event) => {
    //Prevent page reload
    event.preventDefault();

    if (!isTermsChecked) {
      setPrivacyCheckError(true);
      return;
    } else {
      setPrivacyCheckError(false);
    }

    if (loginDetails?.userName === "") {
      setErrors({ ...errors, userName: "Please enter User name" });
      return;
    } else if (loginDetails?.password === "") {
      setErrors({ ...errors, password: "Please enter password" });
      return;
    }

    if (loginDetails?.userName !== "" || loginDetails?.password !== "") {
      setLoading(true);
      // get Access token...
      axios
        .get(
          `${reactAppAuthURL}/fetch_access_token?user=${loginDetails?.userName}`,
          {
            headers: {
              Authorization: `Bearer ${md5(reactAppAuthAccessToken)}`,
            },
          }
        )
        .then(async (response) => {
          if (response?.status === 200 && response?.data) {
            localStorage.setItem("access_token", response?.data?.access_token);
            localStorage.setItem("session_id", response?.data?.session_id);
            localStorage.setItem("token_expiry", response?.data?.token_expiry);
            getAllConsumers();
          }
        })
        .catch((error) => {
          setLoginError(
            "We are facing some issue while connecting to server. Please try again after sometime"
          );
          setLoading(false);
          console.log(error);
        });
    }
  };

  // JSX code for login form
  const renderForm = (
    <>
      <div className="space-y-6">
        <div>
          <label
            htmlFor="uname"
            className="block text-sm font-medium leading-6 text-electric-green"
          >
            Username
          </label>
          <div className="mt-2">
            <input
              id="userName"
              type="text"
              name="userName"
              placeholder="Please enter a username. e.g. aditi_nair"
              onChange={handleOnChange}
              required
              className="block w-full rounded-md border-0 py-1.5 text-[#FFFFFF] bg-blend-darken bg-deep-navy shadow-sm ring-1 ring-inset ring-[#00FFB4] placeholder:text-[#00FFB4] focus:ring-2 focus:ring-inset focus:ring-electric-green sm:text-sm sm:leading-6"
            />
            {errors.userName !== null ? (
              <span className="text-[#f44336] text-sm">{errors.userName}</span>
            ) : null}
          </div>
        </div>
        <div>
          <label
            htmlFor="password"
            className="block text-sm font-medium leading-6 text-electric-green"
          >
            Password
          </label>
          <div className="mt-2">
            <input
              id="password"
              type="password"
              name="password"
              placeholder="Please enter your password."
              required
              onChange={handleOnChange}
              className="block w-full rounded-md border-0 py-1.5 text-[#FFFFFF] bg-blend-darken bg-deep-navy shadow-sm ring-1 ring-inset ring-[#00FFB4] placeholder:text-[#00FFB4] focus:ring-2 focus:ring-inset focus:ring-electric-green sm:text-sm sm:leading-6"
            />
            {errors.password !== null ? (
              <span className="text-[#f44336] text-sm">{errors.password}</span>
            ) : null}
          </div>
        </div>

        <div>
          <label className="flex">
            <input
              type="checkbox"
              checked={isTermsChecked}
              onChange={handleTermsChange}
            />
            <div className="ml-2 text-white cursor-pointer ">
              <span>Please accept </span>{" "}
              <a
                href="/terms-and-conditions"
                className="underline"
                target="_blank"
              >
                Terms and Conditions
              </a>{" "}
            </div>
          </label>

          <div>
            {privacyCheckError && (
              <span style={{ color: "red" }}>
                {" "}
                Please accept terms and conditions
              </span>
            )}
          </div>
        </div>

        <div>
          {loading ? (
            <div className="flex w-full justify-center rounded-md bg-electric-green px-3 py-1.5 text-sm font-semibold leading-6 text-deep-navy shadow-sm hover:bg-true-teal focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-green">
              <CircularProgress
                style={{
                  width: "24px",
                  height: "24px",
                  color: "#000000",
                }}
              />
            </div>
          ) : (
            <button
              onClick={handleSubmit}
              className="flex w-full justify-center rounded-md bg-electric-green px-3 py-1.5 font-semibold leading-6 text-deep-navy shadow-sm hover:bg-true-teal focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-green"
            >
              log in
            </button>
          )}
        </div>
      </div>
      {/* for Forgot password */}
      <div className="my-2">
        <span
          className="text-sm font-medium leading-6 text-electric-green cursor-pointer hover:underline"
          onClick={() => navigate("/forgot-password")}
        >
          Forgot password?
        </span>
      </div>

      {/* for showing error */}
      <div className="my-4">
        {loginError !== "" && (
          <Alert className="text-red-600" severity="error">
            {loginError}
          </Alert>
        )}
      </div>
    </>
  );

  return (
    <div className="   flex flex-row  flex-1   justify-center items-center bg-deep-navy">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm w-2/6  bg-deep-navy mb-10 lg:mb-20">
        <div className=" flex flex-row items-center justify-center  ">
          <span className=" text-white font-semi-bold  text-3xl  ">
            <span className="text-white text-3xl">G</span>roupM
            <span className="text-white text-3xl"> D</span>ata
            <span className="text-white text-3xl"> E</span>xchange
          </span>
        </div>
        <h2 className="mt-10 mb-10 text-center text-2xl font-light   leading-9 tracking-tight text-electric-green">
          Sign in to your account
        </h2>
        {/* </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm relative z-30"> */}
        {renderForm}
      </div>
      <div className="relative overflow-hidden h-screen w-4/6 ">
        <video
          autoPlay="autoplay"
          loop={true}
          muted
          className="absolute z-10 w-auto min-w-full min-h-full max-w-none  backdrop-contrast-100 backdrop-blur-sm"
        >
          <source src={BgVideoGreen} type="video/mp4" />
          <source src={BgVideo} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default Login;
