import React, { useEffect, useState } from "react";

import { Alert, CircularProgress, SwipeableDrawer } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "../redux/actions/index";
import SelectDropdown from "./CommonComponent/SelectDropdown";

import CommonModal from "./CommonComponent/Modal";
import CustomTable from "./CommonComponent/Table";
import Match_Rate_Card from "../Assets/Match_Rate_card.png";

import {
  ApprovedStatus,
  CompletedStatus,
  RejectedStatus,
  FailedStatus,
  WaitingStatus,
  OtherStatus,
} from "./CommonComponent/StatusColumn";

import enrichment from "../Assets/Profiling_Isometric.svg";
import searchillustration from "../Assets/Target audience _Two Color.svg";

import { handleDate } from "../utils/commonFunctions";

import Spinner from "./CommonComponent/Spinner";
import "./styles.css";
import "./pure-react.css";
import API from "../apiServices/api";

const redirectionUser = process.env.REACT_APP_REDIRECTION_URL;

const initialState = {
  Query_Name: "",
  Provider_Name: "",
  Column_Names: [],
  Consumer_Name: "",
  Attribute_Value: "",
};
const Enrichment = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  const user = state && state.user;
  const TableData = state && state.ConsumerForm && state.ConsumerForm.TableData;
  const requestId = state && state.ConsumerForm && state.ConsumerForm.RequestId;
  const loadingTable =
    state && state.ConsumerForm && state.ConsumerForm.loadingTable;

  const [formData, setFormData] = useState({
    ...initialState,
    Consumer_Name: user?.account_name,
  });

  const [tableHead, setTableHead] = useState([]);
  const [tableRows, setTableRows] = useState([]);

  const [providerList, setProviderList] = useState([]);
  const [templateList, setTemplateList] = useState("");
  const [databaseName, setDatabaseName] = useState("");
  const [columns, setColumns] = useState([]);
  const [byPassAPICalled, setByPassAPICalled] = useState(false);

  const [tableData, setTableData] = useState([]);

  const [viewActionModal, setViewActionModal] = useState(false);
  const [requestFailedReason, setRequestFailedReason] = React.useState({
    openModal: false,
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [columnError, setColumnError] = useState("");

  const [toggleDrawerPosition, setToggleDrawerPosition] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    search: false,
  });

  useEffect(() => {
    if (TableData) {
      setTableHead(TableData?.head || []);
      setTableRows(TableData?.rows || []);
    }
  }, [TableData]);

  useEffect(() => {
    let intervalId;
    if (byPassAPICalled === true) {
      intervalId = setInterval(() => {
        fetchMainTable();
      }, 5000);
    }
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [byPassAPICalled]);

  useEffect(() => {
    dispatch(
      actions.ConsumerQueryForm({
        loadingTable: true,
      })
    );
    fetchMainTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMainTable = async () => {
    const payload = {
      account_name: user?.account_name,
      db_name: user?.consumerDBName,
      template_name: "CUSTOMER ENRICHMENT",
      role: user?.role?.includes("Consumer_Admin") ? "ADMIN" : "USER",
      user_name: user?.name,
    };
    try {
      const response = await API.fetchData(payload);
      if (response.status === 200 && response?.data?.data) {
        let res = response?.data?.data;
        setTableData(res);
        dispatch(
          actions.ConsumerQueryForm({
            loadingTable: false,
          })
        );
      } else {
        dispatch(
          actions.ConsumerQueryForm({
            loadingTable: false,
          })
        );
      }
    } catch (error) {
      dispatch(
        actions.ConsumerQueryForm({
          loadingTable: false,
        })
      );
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const getAllProviders = async () => {
      const payload = {
        account_name: redirectionUser,
        db_name: user?.consumerDBName,
        consumer_name: user?.account_name,
        template_name: "enrichment",
      };
      try {
        const response = await API.getAllProvidersList(payload);
        if (response.status === 200 && response?.data?.data) {
          setProviderList(response?.data?.data);
        } else {
          setProviderList([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getAllProviders();
  }, [user]);

  useEffect(() => {
    if (databaseName !== "" && formData["Query_Name"] !== "") {
      const getAllowedColumns = async () => {
        const payload = {
          account_name: user?.account_name,
          databaseName: databaseName,
          Query_Name: formData["Query_Name"],
        };
        try {
          const response = await API.getAllowedColumns(payload);
          if (response.status === 200 && response?.data?.data) {
            let col_name = response?.data?.data[0]?.ALLOWED_COLUMNS?.split("|");
            col_name = col_name?.map((item) => {
              return item?.split(".")[1];
            });
            if (col_name && col_name?.length > 0) {
              let temp = [];
              temp.push({ value: "all", name: "All" });
              col_name?.map((value) => {
                return temp.push({ value: value, name: value });
              });
              setColumns(temp);
            } else {
              setColumns([]);
              setFormData({
                ...formData,
                Column_Names: [],
              });
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      getAllowedColumns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [databaseName, formData["Query_Name"], user?.account_name]);

  const handleSelectProvider = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    setTemplateList([]);
    getDatabaseName(event.target.value);
  };

  const handleSelectedTemp = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const getDatabaseName = async (selectedProvider) => {
    const payload = {
      account_name: user?.account_name,
      selectedProvider: selectedProvider,
      consumer_database_name: user?.consumerDBName,
    };
    try {
      const response = await API.getDatabaseName(payload);
      if (response.status === 200 && response?.data?.data) {
        let db_name = response?.data?.data[0]?.DATABASE;
        setDatabaseName(db_name);

        payload.databaseName = db_name;
        try {
          const response = await API.getTemplateNames(payload);
          if (response.status === 200 && response?.data?.data) {
            setTemplateList(response.data.data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCustomerFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (event, name) => {
    const element = "all";
    const index = event?.indexOf(element);

    if (event?.includes("all")) {
      let allSelect = columns?.map((obj) => {
        return obj.value;
      });
      if (index !== -1) {
        allSelect?.splice(index, 1);
      }
      setFormData({
        ...formData,
        [name]: allSelect,
      });
    } else {
      setFormData({
        ...formData,
        [name]: event,
      });
    }
  };

  // const downloadFile = async (TEMPLATE_NAME, RUN_ID) => {
  //   TEMPLATE_NAME = TEMPLATE_NAME.replace(/\s/g, "_");
  //   const payload = {
  //     account_name: user?.account_name,
  //     db_name: user?.consumerDBName,
  //     templateName: TEMPLATE_NAME,
  //     run_id: RUN_ID,
  //   };
  //   try {
  //     const response = await API.downloadFileAPI(payload);
  //     if (response.status === 200 && response?.data) {
  //       const csvData = jsonToCsv(response?.data); // Create a Blob from the CSV data
  //       downloadFileInCSV(csvData, TEMPLATE_NAME, RUN_ID);
  //     } else {
  //       toast.error("File cannnot be downloaded...");
  //     }
  //   } catch (error) {
  //     toast.error("File cannnot be downloaded...");
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const callByPassAPI = async (newReqId, providerAccIdentifier) => {
    setByPassAPICalled(true);

    const payload = {
      account_name: user?.account_name,
      db_name: user?.consumerDBName,
      provider_account_identifier: providerAccIdentifier,
      newReqId: newReqId,
      template_name: "CUSTOMER ENRICHMENT",
    };
    try {
      const response = await API.callProcedureMatchRate(payload);
      if (response.status === 200) {
        fetchMainTable();
        setByPassAPICalled(false);
        try {
          const response = await API.getRequestDataUsingRunId(payload);
          if (
            response.status === 200 &&
            response?.data?.data &&
            response?.data?.data?.length > 0
          ) {
            let result = response?.data?.data[0];
            const payload = {
              account_name: redirectionUser,
              RUN_ID: result?.RUN_ID,
              TEMPLATE_NAME: result?.TEMPLATE_NAME,
              CONSUMER_RECORD_COUNT: result?.CONSUMER_RECORD_COUNT,
              PROVIDER_NAME: result?.PROVIDER_NAME,
              CONSUMER_NAME: result?.CONSUMER_NAME,
              // REQUEST_TS: result?.REQUEST_TS,
              REQUEST_TS: result?.RUN_ID,
              STATUS: result?.STATUS,
              MATCHED_PERCENTAGE: 0,
              ATTRIBUTES: result?.COLOUMNS,
              USER_NAME: result?.USER_NAME,
            };
            try {
              const response = await API.insertLogTableData(payload);
              if (response.status === 200) {
                fetchMainTable();
                setByPassAPICalled(false);
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        setByPassAPICalled(false);
        fetchMainTable();
      }
    } catch (error) {
      setByPassAPICalled(false);
      fetchMainTable();
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    formData.RunId = Date.now();

    if (!formData.Column_Names || formData.Column_Names.length <= 0) {
      setColumnError("please select the columns");
      return;
    }

    setLoading(true);
    // const delimiter = "&";
    // const selectedColumns = `#${formData.Column_Names?.join(delimiter)}#`;
    const selectedColumns = `${formData.Column_Names?.join(",")}`;
    try {
      const payload = {
        account_name: user?.account_name,
        db_name: user?.consumerDBName,
        provider_name: formData.Provider_Name,
      };

      const response = await API.getProviderAccount(payload);
      if (
        response.status === 200 &&
        response?.data?.data[0]?.PROV_ACCT_IDENTIFIER
      ) {
        let providerAccIdentifier =
          response?.data?.data[0]?.PROV_ACCT_IDENTIFIER;
        try {
          const payload = {
            account_name: user?.account_name,
            template_name: formData?.Query_Name,
            provider_name: formData?.Provider_Name,
            columns: selectedColumns,
            consumer_name: formData?.Consumer_Name,
            run_id: formData?.RunId,
            attribute_value: formData?.Attribute_Value,
            consumer_database_name: user?.consumerDBName,
            provider_account_identifier: providerAccIdentifier,
            user_name: user?.name,
          };

          const response = await API.insertEnrichmentRequest(payload);
          if (response.status === 200) {
            try {
              const payload = {
                account_name: formData?.Provider_Name,
                // db_name: user?.providerDBName,
                db_name: `DCR_${providerAccIdentifier}_PROVIDER_DB`,
                run_id: formData.RunId,
              };

              const response = await API.insertRunId(payload);
              if (response.status === 200) {
                fetchMainTable();
                setLoading(false);
                setFormData({
                  ...initialState,
                  Consumer_Name: user?.account_name,
                });
                setToggleDrawerPosition({
                  ...toggleDrawerPosition,
                  right: false,
                });
                callByPassAPI(formData?.RunId, providerAccIdentifier);
              } else {
                setLoading(false);
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          }
        } catch (error) {
          setLoading(false);
          console.error("Error fetching data:", error);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  // const fetchcsvTableData = async (templateName, runId) => {
  //   templateName = templateName.replace(/\s/g, "_");

  //   setViewActionModal(true);

  //   const payload = {
  //     account_name: user?.account_name,
  //     db_name: user?.consumerDBName,
  //     templateName: templateName,
  //     run_id: runId,
  //   };
  //   try {
  //     const response = await API.viewSampleData(payload);
  //     if (response.status === 200 && response?.data?.data) {
  //       if (response?.data?.data) {
  //         let data = response?.data?.data;
  //         let head = [];
  //         let row = [];
  //         if (data?.length > 0) {
  //           head = data && Object.keys(data[0]);
  //           data?.map((obj) => {
  //             return row.push(head?.map((key) => obj[key]));
  //           });
  //         }
  //         dispatch(
  //           actions.ConsumerQueryForm({
  //             TableData: { head: head, rows: row },
  //             RequestId: runId,
  //           })
  //         );
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setFormData({ ...initialState, Consumer_Name: user?.account_name });
    setToggleDrawerPosition({ ...toggleDrawerPosition, [anchor]: open });
  };
  const[identifierList, setIdentifierList] = useState([]);

  useEffect(() => {
    if (formData?.Provider_Name !== "") {
      const getIdentifierTypes = async () => {
        const payload = {
          account_name: redirectionUser,
          provider_name: formData?.Provider_Name,
          consumer_name: user?.account_name,
        };
        try {
          const response = await API.getIdentifierTypes(payload);
          if (response.status === 200 && response?.data?.data) {
            setIdentifierList(
              response?.data?.data?.[0]?.IDENTIFIER_TYPE?.split(",")
            );
          } else {
            setIdentifierList([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      getIdentifierTypes();
    }
  }, [user?.account_name, formData?.Provider_Name]);


  return (
    <div className="flex flex-col w-full px-4 ">
      <div className="flex flex-row justify-between items-center w-full mt-2">
        <div>
          <h3 className="text-xl font-bold text-deep-navy mr-2">
            {/* Consumer query */}
            Enrichment
          </h3>
        </div>
        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            <button
              className="my-2 pr-4 flex items-center justify-center rounded-md bg-deep-navy px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#00FFFF] hover:text-deep-navy focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-green"
              onClick={toggleDrawer(anchor, true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              {/* Create new */}
              raise new request
            </button>
            {toggleDrawerPosition[anchor] && (
              <SwipeableDrawer
                anchor={anchor}
                open={toggleDrawerPosition[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                <div className="flex flex-col flex-shrink h-full w-full px-5    bg-deep-navy text-electric-green">
                  <img
                    className="absolute  w-96  bottom-1 opacity-90 z-10 right-0 text-amarant-400"
                    src={searchillustration}
                    alt=""
                  />
                  <form
                    className=" z-20  my-4 px-4 py-2 w-96  bg-deep-navy/50"
                    name="myForm"
                    onSubmit={handleSubmit}
                  >
                    <div className="flex flex-row justify-between">
                      <h3 className="text-xl font-semibold">
                        {/* New consumer query */}
                        New Enrichment Request
                      </h3>
                      <button onClick={toggleDrawer("right", false)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <div className="mt-2 pb-2 flex flex-col">
                        <label>data partners</label>
                        <select
                          id="provider"
                          name="Provider_Name"
                          required
                          className="block w-full rounded-md border-0 py-1.5 text-[#FFFFFF]  bg-deep-navy shadow-sm ring-1 ring-inset ring-[#00FFB4] placeholder:text-true-teal focus:ring-2 focus:ring-inset focus:ring-electric-green sm:text-sm sm:leading-6"
                          value={formData["Provider_Name"]}
                          onChange={handleSelectProvider}
                        >
                          <option value="">select data partners</option>
                          {providerList?.length > 0 ? (
                            providerList?.map((item, index) => (
                              <option key={index} value={item.PROVIDER_NAME}>
                                {item.PROVIDER_NAME}
                              </option>
                            ))
                          ) : (
                            <option value="">Loading...</option>
                          )}
                        </select>
                      </div>

                      <div className="mt-2 pb-2 flex flex-col">
                        <label>template Name </label>
                        <select
                          id="selectedTemp"
                          required
                          name="Query_Name"
                          value={formData["Query_Name"]}
                          className="block w-full rounded-md border-0 py-1.5 text-[#FFFFFF]  bg-deep-navy shadow-sm ring-1 ring-inset ring-[#00FFB4] placeholder:text-true-teal focus:ring-2 focus:ring-inset focus:ring-electric-green sm:text-sm sm:leading-6"
                          onChange={handleSelectedTemp}
                        >
                          <option value="">select template</option>
                          {templateList?.length > 0 ? (
                            templateList.map((item, index) => (
                              <option key={index} value={item.TEMPLATE_NAME}>
                                {item.TEMPLATE_NAME}
                              </option>
                            ))
                          ) : (
                            <option value="">Loading...</option>
                          )}
                        </select>
                      </div>

                      <div className="mt-2 pb-2 flex flex-col">
                        <SelectDropdown
                          title="Attributes"
                          mode="multiple"
                          name="Column_Names"
                          value={formData?.Column_Names}
                          placeholder={<span style={{ color: 'white' }}>select Attribute</span>}
                          data={columns}
                          setValue={(e, value) => {
                            handleChange(e, value);
                          }}
                        />
                      </div>

                      <div className="my-2">
                        {columnError !== "" && (
                          <Alert className="text-red-600" severity="error">
                            {columnError}
                          </Alert>
                        )}
                      </div>
                      <div className="mt-2 pb-21 flex flex-col">
                        <label>Identifier Type</label>
                        <select
                          name="Attribute_Value"
                          onChange={handleCustomerFormData}
                          required
                          value={formData["Attribute_Value"]}
                          className="block w-full rounded-md border-0 py-1.5 text-[#FFFFFF]  bg-deep-navy shadow-sm ring-1 ring-inset ring-[#00FFB4] placeholder:text-true-teal focus:ring-2 focus:ring-inset focus:ring-electric-green sm:text-sm sm:leading-6"
                        >
                          <option value="">select Identifier</option>
                          {/* <option value="email">Email</option>
                          <option value="phone">Phone</option> */}
                          {identifierList?.map((item, index) => {
                            return (
                              <option key={index} value={item?.toLowerCase()}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="flex justify-end">
                        {loading ? (
                          <div className="flex w-full justify-center rounded-md bg-electric-green px-3 py-1.5 text-sm font-semibold leading-6 text-deep-navy shadow-sm hover:bg-true-teal focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-green mt-4">
                            <CircularProgress
                              style={{
                                width: "24px",
                                height: "24px",
                                color: "#FFFFFF",
                              }}
                            />
                          </div>
                        ) : (
                          <button
                            className="flex w-full justify-center rounded-md bg-electric-green px-3 py-1.5 text-sm font-semibold leading-6 text-deep-navy shadow-sm hover:bg-true-teal focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-green mt-4"
                            type="submit"
                          >
                            submit request
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </SwipeableDrawer>
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="w-full mt-2 mb-4 text-deep-navy">
        <p className="">
          In the enrichment phase, we augment data by retrieving or enhancing
          information post-match rate calculation, refining and expanding
          datasets based on the identified matching count between advertisers
          and partners. This strategic process builds upon the identified
          matching count, ensuring a refined and robust dataset for
          comprehensive analysis.
        </p>
      </div>

      <img
        className="absolute  w-1/4 -z-50 bottom-0 opacity-40 right-0 text-amarant-400 "
        src={enrichment}
        alt=""
      />

      {!loadingTable ? (
        <div className="flex flex-col w-full px-2 ">
          <h1 className="text-xl font-regular text-deep-navy pb-2 font-bold flex justify-center">
            {/* Recent queries */} Recent Request
          </h1>
          {tableData?.length > 0 ? (
            <div className="my-4 grid grid-cols-1 gap-4 sm:grid-cols-3">
              {tableData?.map((item, index) => {
                const output_table_name = `${item?.TEMPLATE_NAME?.replace(
                  / /g,
                  "_"
                )}_${item?.RUN_ID}`;
                const columns = item?.COLOUMNS?.split(",");
                const firstTwoColumns = item?.COLOUMNS?.split(",")?.slice(0, 2);
                return (
                  <div
                    key={index}
                    class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
                  >
                    <div
                      className={`${
                        item.STATUS.toLowerCase() === "true"
                          ? "bg-electric-green"
                          : item.STATUS.toLowerCase() === "false"
                          ? "bg-red-200"
                          : item.STATUS.toLowerCase() === "completed"
                          ? "bg-electric-green"
                          : item.STATUS.toLowerCase() === "failed"
                          ? "bg-red-200"
                          : "bg-downriver-200"
                      } h-10 flex items-center rounded-lg`}
                    >
                      <span className="text-green-900 mx-4 font-bold">
                        {item.STATUS.toLowerCase() === "true"
                          ? ApprovedStatus("approved")
                          : item.STATUS.toLowerCase() === "false"
                          ? RejectedStatus("rejected")
                          : item.STATUS.toLowerCase() === "completed"
                          ? CompletedStatus(item.STATUS)
                          : item.STATUS.toLowerCase() === "failed"
                          ? FailedStatus(item.STATUS)
                          : item.STATUS.toLowerCase() === "waiting for approval"
                          ? WaitingStatus(item.STATUS)
                          : OtherStatus(item.STATUS)}
                      </span>
                    </div>
                    <div className="p-4 text-sm">
                      <div className="flex items-center">
                        <img className="h-7 w-7" src={Match_Rate_Card} alt="" />
                        <div className="mx-4 block">
                          <div class="text-sm text-deep-navy dark:text-white">
                            <strong>Request Id - </strong>
                            {item?.RUN_ID}
                          </div>
                          <span class="text-sm text-deep-navy dark:text-white">
                            <strong>
                              {item?.PROVIDER_ORG_NAME?.toUpperCase()}{" "}
                              <span className="text-base"> | </span>{" "}
                              {item?.CONSUMER_ORG_NAME?.toUpperCase()}
                            </strong>
                          </span>
                        </div>
                      </div>

                      <hr className="my-2 border-slate-300" />
                      <div class="my-2 flex justify-between text-deep-navy">
                        <span className="font-bold">Identifier Type</span>
                        <span>{item?.IDENTIFIER_TYPE}</span>
                      </div>
                      <hr className="my-2 border-slate-300" />
                      <div class="my-2 flex justify-between text-deep-navy">
                        <span className="font-bold">Attributes</span>
                        <div
                          className={columns?.length > 2 && "cursor-pointer"}
                          title={columns?.length > 2 && columns?.join(", ")}
                        >
                          {firstTwoColumns?.map((item, index) => (
                            <span key={index}>
                              {item}
                              {index !== firstTwoColumns.length - 1 && ", "}
                            </span>
                          ))}
                          {columns.length > 2 && (
                            <button>
                              <span className="pl-2 font-bold">
                                +{columns.length - 2}
                              </span>
                            </button>
                          )}
                        </div>
                        {/* <span>{item?.COLOUMNS}</span> */}
                      </div>
                      <hr className="my-2 border-slate-300" />
                      <div class="my-2 flex justify-between text-deep-navy">
                        <span className="font-bold">Total Count</span>
                        <span>{item?.CONSUMER_RECORD_COUNT}</span>
                      </div>
                      <hr className="my-2 border-slate-300" />
                      <div class="my-2 flex justify-between text-deep-navy">
                        <span className="font-bold">Enrichment Count</span>
                        <span>{item?.MATCH_COUNT}</span>
                      </div>
                      <hr className="my-2 border-slate-300" />
                      <div class="my-2 flex justify-between text-deep-navy">
                        <span className="font-bold">Requested By</span>
                        <span>{item?.USER_NAME}</span>
                      </div>
                      <hr className="my-2 border-slate-300" />
                      <div class="my-2 flex justify-between text-deep-navy">
                        <span className="font-bold">Requested On</span>
                        {/* <span>{item?.REQUEST_TS}</span> */}
                        <span>{handleDate(item?.REQUEST_TS)}</span>
                      </div>
                      <hr className="my-2 border-slate-300" />
                      {item.STATUS.toLowerCase() === "failed" ||
                      item.STATUS.toLowerCase() === "false" ? (
                        <div>
                          <button
                            onClick={() =>
                              setRequestFailedReason({
                                ...requestFailedReason,
                                openModal: true,
                                message: item.ERROR_FOR_WEBUI,
                              })
                            }
                            className="flex flex-row items-center px-2 justify-center text-red-600"
                            title="Request Error"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 text-red-600"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                              />
                            </svg>
                            <span className="pl-2 underline">Error</span>
                          </button>
                        </div>
                      ) : (
                        <div class="text-deep-navy flex">
                          <span className="font-bold whitespace-nowrap ">
                            Output Table
                          </span>
                          <span
                            className="text-right overflow-hidden custom-break-word block ml-[5px]"
                            title={output_table_name}
                          >
                            {output_table_name}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              <span className="text-deep-navy font-bold mt-4">
                There is no recent queries are available. Please submit a
                request to see queries!!
              </span>
            </div>
          )}
        </div>
      ) : (
        <div className="flex justify-center mt-8">
          <Spinner />
        </div>
      )}

      <CustomTable
        id={requestId}
        head={tableHead}
        rows={tableRows}
        pagination={true}
        open={viewActionModal}
        handleClose={() => {
          setViewActionModal(false);
          dispatch(
            actions.ConsumerQueryForm({
              TableData: { head: [], rows: [] },
            })
          );
        }}
        title={"Query Result"}
      />

      {requestFailedReason.openModal ? (
        <CommonModal
          open={requestFailedReason.openModal}
          handleClose={() =>
            setRequestFailedReason({ ...requestFailedReason, openModal: false })
          }
          title={"Request Error"}
          message={requestFailedReason.message ? requestFailedReason.message : "Oops!Something went wrong.Please try again later."}
          buttons={false}
          textColor={"text-red-600"}
          svg={true}
        />
      ) : null}
    </div>
  );
};

export default Enrichment;
