import React, { useEffect, useState, useRef } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import API from "../apiServices/api";
import { parseStringFromSpecialCharacters } from "../utils/commonFunctions";
import consumerImage from "../Assets/consumerimg.jpg";
import providerImage from "../Assets/provider.png";
import enrichment from "../Assets/enrichment.png";

import MatchRateVideo from "../Assets/Videos/Match_rate.mp4";

import "../Assets/CSS/common.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const redirectionUser = process.env.REACT_APP_REDIRECTION_URL;

const Home = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams();

  const sliderRef = useRef(null);

  const user = state && state.user;

  const [counts, setCounts] = useState({
    enrichments: 0,
    integrations: 0,
    providers: 0,
  });
  const [dataPercentage, setDataPercentage] = useState([]);
  const [dataPartners, setDataPartners] = useState([]);
  const [dataPartnersExadmin, setDataPartnersExadmin] = useState({
    latest_partners: [],
    latest_advertisers: [],
  });

  useEffect(() => {
    const fetchDataForCounts = async () => {
      const payload = {
        account_name: redirectionUser,
        consumer_name: user?.account_name,
      };
      try {
        let response;
        if (
          user?.role?.includes("Consumer") ||
          user?.role?.includes("Publisher")
        ) {
          response = await API.noOfConsumerEnrichments(payload);
          if (response.status === 200 && response?.data) {
            setCounts({
              ...counts,
              enrichments: response?.data?.enrichments[0]?.COUNT,
              integrations: response?.data?.integrations[0]?.COUNT,
              providers: response?.data?.providers[0]?.COUNT,
            });
          }
        } else if (user?.role?.includes("Provider")) {
          response = await API.noOfProviderEnrichments(payload);
          if (response.status === 200 && response?.data) {
            setCounts({
              ...counts,
              enrichments: response?.data?.enrichments[0]?.COUNT,
              integrations: response?.data?.integrations[0]?.COUNT,
              providers: response?.data?.consumers[0]?.COUNT,
            });
          }
        }
      } catch (error) {
        console.error("Error while fetching data:", error);
      }
    };

    fetchDataForCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const fetchDataMatcRatePercentage = async () => {
      const payload = {
        account_name: redirectionUser,
        consumer_name: user?.account_name,
        role: user?.role?.includes("Provider")
          ? "PROVIDER_NAME"
          : "CONSUMER_NAME",
        admin_role: user?.role?.includes("Consumer_Admin") ? "ADMIN" : "USER",
        user_name: user?.name,
      };

      try {
        let response;
        if (
          user?.role?.includes("Consumer") ||
          user?.role?.includes("Publisher")
        ) {
          response = await API.getConsumerMatchRatePercentage(payload);
          if (response.status === 200 && response?.data) {
            setDataPercentage(response?.data?.data);
          }
        } else if (user?.role?.includes("Provider")) {
          const payload = {
            account_name: redirectionUser,
            PROVIDER_NAME: user?.account_name,
          };
          response = await API.getProviderMatchRatePercentage(payload);
          if (response.status === 200 && response?.data) {
            setDataPercentage(response?.data?.data);
          }
        }
      } catch (error) {
        console.error("Error while fetching data:", error);
      }
    };
    fetchDataMatcRatePercentage();
  }, [user?.account_name, user?.role, user?.name]);

  useEffect(() => {
    const fetchDataPartners = async () => {
      const payload = {
        account_name: redirectionUser,
        consumer_name: user?.account_name,
      };

      try {
        let response;
        if (
          user?.role?.includes("Consumer") ||
          user?.role?.includes("Publisher")
        ) {
          response = await API.dataPartnersConsumer(payload);
          if (response.status === 200 && response?.data) {
            setDataPartners(response?.data?.data);
          }
        } else if (user?.role?.includes("Provider")) {
          response = await API.providersHomepageAdvertisers(payload);
          if (response.status === 200 && response?.data) {
            setDataPartners(response?.data?.publishers);
          }
        }
      } catch (error) {
        console.error("Error while fetching data:", error);
      }
    };

    fetchDataPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.account_name, user?.role]);

  useEffect(() => {
    if (user?.role?.includes("DATAEXADMIN")) {
      const fetchAllDataDataexadmin = async () => {
        const payload = {
          account_name: redirectionUser,
        };
        try {
          const response = await API.dataexadminHomePageData(payload);
          if (response.status === 200 && response?.data) {
            setCounts({
              ...counts,
              enrichments: response?.data?.consumers[0]?.COUNT,
              integrations: response?.data?.integrations[0]?.COUNT,
              providers: response?.data?.providers[0]?.COUNT,
            });
            setDataPartnersExadmin({
              latest_advertisers: response?.data?.latest_advertisers,
              latest_partners: response?.data?.latest_partners,
            });
          }
        } catch (error) {
          console.error("Error while fetching data:", error);
        }
      };

      fetchAllDataDataexadmin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.account_name, user?.role]);

  const settings = {
    infinite: true,
    slidesToShow: dataPartners?.length > 1 ? 2 : dataPartners?.length,
    slidesToScroll: 1,
    speed: 500,
    focusOnSelect: true,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  return (
    <>
      <div className="bg-white shadow-md rounded-lg p-4 m-2 border border-gray-300">
        <div className="welcome-user text-deep-navy">
          <h1>
            Welcome{" "}
            <p className="userName">
              {" "}
              {user?.name
                ? parseStringFromSpecialCharacters(
                  user.name.charAt(0).toUpperCase() + user.name.slice(1)
                )
                : null}
              !
            </p>
          </h1>

          <div id="welcome-msg" className="flex justify-center items-center">
            <div className="welcome-msg">
              {user?.role?.includes("DATAEXADMIN") ? (
                <p className="text-base">
                  Welcome to the Admin Dashboard, your central hub for streamlined management.
                  Access a curated list of Data Partners and Advertisers tailored to your needs.
                  <br></br>
                  Dive into your platform's activity history and explore the latest additions.
                  Navigate seamlessly with our intuitive guide videos and efficient administrative experience.


                </p>
              ) : (
                <p className="text-base">
                  Welcome to the Home Page—a hub of insights and collaboration.
                  Explore the count of partners, enrichments, and activity history.
                  Dive into data partner profiles, learn more about the features with guide videos,
                  and discover tips and strategies for achieving top match rate results—your
                  all-in-one destination for a dynamic and informed data system.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div id="Cards">
        <div className="cards left-card">
          <img src={consumerImage} alt="Consumer" />
          <span className="cardKey text-white font-bold">
            {user?.role?.includes("Provider")
              ? "Advertisers Available To You"
              : "Data Partners Available To You"}
          </span>
          <span className="cardValue text-white font-bold">
            {counts.providers}
          </span>
        </div>
        <div className="cards middle-card">
          <img src={providerImage} alt="Integrations" />
          <span className="cardKey text-white font-bold">Activity History</span>
          <span className="cardValue text-white font-bold">
            {counts.integrations}
          </span>
        </div>
        <div className="cards right-card">
          <img src={user?.role?.includes("DATAEXADMIN") ? consumerImage : enrichment}
            alt="Provider" />
          <span className="cardKey text-white font-bold">
            {user?.role?.includes("DATAEXADMIN")
              ? "Advertisers Available To You"
              : "Number of Enrichments"}
          </span>
          <span className="cardValue text-white font-bold">
            {counts.enrichments}
          </span>
        </div>
      </div>

      <div id="third-panel">
        <div className="flex">
          {user?.role?.includes("Consumer") ||
            user?.role?.includes("Publisher") ? (
            <div id="dataTable" className=" px-8 bg-white">
              <div>
                <span className="flex mt-1 justify-center item-center text-deep-navy text-2xl text-center w-full font-bold">
                  Data Partners
                </span>
                <Slider ref={sliderRef} {...settings}>
                  {dataPartners?.map((item, idx) => {
                    const firstTwoCategories = item?.CATEGORIES?.split(
                      ","
                    ).slice(0, 2);
                    const firstTwoDataPoints = item?.DATA_POINTS?.split(
                      ","
                    ).slice(0, 2);
                    return (
                      <div>
                        <div className="p-1.5">
                          <div
                            className={
                              dataPartners?.length > 1 ? "consumer_card" : "consumer_card w-1/2"
                            }
                          >
                            <div className="card_header">
                              <img
                                key={idx}
                                src={`${process.env.PUBLIC_URL}/Logos/${item?.PROVIDER_NAME.toLowerCase()}.png`}
                                alt={item.PROVIDER_NAME}
                              />

                              {item.INTEGRATED === "Yes" ? (
                                <span className="text-deep-navy text-sm font-bold">
                                  INTEGRATED
                                </span>
                              ) : (
                                <span className="text-red-600 text-sm font-bold">
                                  NOT INTEGRATED
                                </span>
                              )}
                            </div>
                            <div>
                              <div className="innercontainer">
                                <p className="titled">Categories:</p>{" "}
                                <p
                                  className={
                                    item?.CATEGORIES?.split(",")?.length > 2
                                      ? "cursor-pointer categories_data"
                                      : "cursor-pointer categories_data"
                                  }
                                  title={
                                    item?.CATEGORIES?.split(",")?.length > 2 &&
                                    item?.CATEGORIES?.split(",")?.join(", ")
                                  }
                                  onClick={() => {
                                    queryParams.set(
                                      "ProviderName",
                                      item?.PROVIDER_NAME
                                    );
                                    navigate(
                                      `/search-catalog?${queryParams.toString()}`
                                    );
                                  }}
                                >
                                  {firstTwoCategories?.map((item, index) => (
                                    <span key={index}>
                                      {item}
                                      {index !==
                                        firstTwoCategories.length - 1 && ", "}
                                    </span>
                                  ))}
                                  {item?.CATEGORIES?.split(",").length > 2 && (
                                    <button>
                                      ...
                                      <span className="pl-2 font-bold">
                                        +
                                        {item?.CATEGORIES?.split(",").length -
                                          2}{" "}
                                        more
                                      </span>
                                    </button>
                                  )}
                                </p>
                              </div>
                              <div className="innercontainer">
                                <p className="titled">Attributes:</p>{" "}
                                <p
                                  className={
                                    item?.DATA_POINTS?.split(",")?.length > 2
                                      ? "cursor-pointer attributes_data"
                                      : "cursor-pointer attributes_data"
                                  }
                                  title={
                                    item?.DATA_POINTS?.split(",")?.length > 2 &&
                                    item?.DATA_POINTS?.split(",")?.join(", ")
                                  }
                                  onClick={() => {
                                    queryParams.set(
                                      "ProviderName",
                                      item?.PROVIDER_NAME
                                    );
                                    navigate(
                                      `/search-catalog?${queryParams.toString()}`
                                    );
                                  }}
                                >
                                  {firstTwoDataPoints?.map((item, index) => (
                                    <span key={index}>
                                      {item}
                                      {index !==
                                        firstTwoDataPoints.length - 1 && ", "}
                                    </span>
                                  ))}
                                  {item?.DATA_POINTS?.split(",").length > 2 && (
                                    <button>
                                      ...
                                      <span className="pl-2 font-bold">
                                        +
                                        {item?.DATA_POINTS?.split(",").length -
                                          2}{" "}
                                        more
                                      </span>
                                    </button>
                                  )}
                                </p>
                              </div>
                              <div className="innercontainer">
                                <p className="titled">Record count:</p>
                                <p className="record_data">
                                  {item?.TOTAL_RECORDS
                                    ? item?.TOTAL_RECORDS
                                    : "NA"}
                                </p>
                              </div>
                              <div className="innercontainer">
                                <p className="titled">Avg fill rate:</p>{" "}
                                <p className="avg_fill_rate_data">
                                  {item?.AVG_FILL_RATE
                                    ? item?.AVG_FILL_RATE
                                    : "NA"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          ) : user?.role?.includes("Provider") ? (
            <div id="dataTable" className=" px-8 bg-white  ">
              <div>
                <span className="flex mt-0.5  justify-center item-center text-deep-navy text-2xl text-center w-full font-bold">
                  Advertisers
                </span>
                <Slider ref={sliderRef} {...settings}>
                  {dataPartners?.map((item, idx) => {
                    //const firstTwoCategories = "Gender"?.split(",").slice(0, 2);
                    const firstTwoCategories = item?.CATEGORIES_INTRESTED?.split(
                      ","
                    ).slice(0, 2);
                    const firstTwoDataPoints = item?.DATA_POINTS_ENRICHED?.split(
                      ","
                    ).slice(0, 2);
                    return (
                      <div>
                        <div className="p-1.5">
                          <div
                            className={
                              dataPartners?.length > 1 ? "consumer_card" : "consumer_card w-1/2"
                            }
                          >
                            <div className="card_header">
                              <img
                                key={idx}
                                src={`${process.env.PUBLIC_URL}/Logos/${item?.CONSUMER_NAME.toLowerCase()}.png`}
                                alt={item.CONSUMER_NAME}
                              />
                              {item.INTEGRATED === "Yes" ? (
                                <span className="text-deep-navy text-sm font-bold">
                                  INTEGRATED
                                </span>
                              ) : (
                                <span className="text-red-600 text-sm font-bold">
                                  NOT INTEGRATED
                                </span>
                              )}
                            </div>
                            <div>
                              <div className="innercontainer">
                                <p className="titled">Categories Interested:</p>{" "}
                                <p
                                  className={
                                    item?.CATEGORIES_INTRESTED?.split(",")
                                      ?.length > 2
                                      ? "cursor-pointer categories_interested"
                                      : "categories_interested"
                                  }
                                  title={
                                    item?.CATEGORIES_INTRESTED?.split(",")
                                      ?.length > 2 &&
                                    item?.CATEGORIES_INTRESTED?.split(
                                      ","
                                    )?.join(", ")
                                  }
                                >
                                  {firstTwoCategories?.map((item, index) => (
                                    <span key={index}>
                                      {item}
                                      {index !==
                                        firstTwoCategories.length - 1 && ", "}
                                    </span>
                                  ))}
                                  {item?.CATEGORIES_INTRESTED?.split(",")
                                    .length > 2 && (
                                      <button>
                                        ...
                                        <span className="pl-2 font-bold">
                                          +
                                          {item?.CATEGORIES_INTRESTED?.split(",")
                                            .length - 2}{" "}
                                          more
                                        </span>
                                      </button>
                                    )}
                                </p>
                              </div>
                              <div className="innercontainer">
                                <p className="titled">Data Points Enriched:</p>{" "}
                                <p
                                  className={
                                    item?.DATA_POINTS_ENRICHED?.split(",")?.length > 2
                                      ? "cursor-pointer attributes_data"
                                      : "cursor-pointer attributes_data"
                                  }
                                  title={
                                    item?.DATA_POINTS_ENRICHED?.split(",")?.length > 2 &&
                                    item?.DATA_POINTS_ENRICHED?.split(",")?.join(", ")
                                  }

                                >
                                  {firstTwoDataPoints?.map((item, index) => (
                                    <span key={index}>
                                      {item}
                                      {index !==
                                        firstTwoDataPoints.length - 1 && ", "}
                                    </span>
                                  ))}
                                  {item?.DATA_POINTS_ENRICHED?.split(",").length > 2 && (
                                    <button>
                                      ...
                                      <span className="pl-2 font-bold">
                                        +
                                        {item?.DATA_POINTS_ENRICHED?.split(",").length -
                                          2}{" "}
                                        more
                                      </span>
                                    </button>
                                  )}
                                </p>
                              </div>
                              {/* <div className="innercontainer">
                                <p className="titled">Data Points Enriched:</p>{" "}
                                <p className="data_points_enriched">
                                  {item.DATA_POINTS_ENRICHED}
                                </p>
                              </div> */}
                              <div className="innercontainer">
                                <p className="titled">No Of Match Rate:</p>
                                <p className="no_of_match_rate">
                                  {item?.NO_OF_MATCH_RATES
                                    ? item?.NO_OF_MATCH_RATES
                                    : "NA"}
                                </p>
                              </div>
                              <div className="innercontainer">
                                <p className="titled">No Of Enrichments:</p>{" "}
                                <p className="no_of_enrichments">
                                  {item?.NO_OF_ENRICHMENTS
                                    ? item?.NO_OF_ENRICHMENTS
                                    : "NA"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          ) : (
            <div className=" w-[65.8%]">
              <h1 className="dataexlatestPartner">Latest Data Partners</h1>
              <div className="datexmatchratepartner ">
                <div className="dataexboximage">
                  {dataPartnersExadmin?.latest_partners?.map((item, idx) => {
                    return (
                      <img
                        key={idx}
                        src={`${process.env.PUBLIC_URL}/Logos/${item?.ACCOUNT_NAME.toLowerCase()}.png`}
                        alt={item?.ACCOUNT_NAME}
                      />
                    );
                  })}
                </div>
              </div>
              <h1 className="dataexlatestPartner">Latest Advertisers</h1>
              <div className="datexmatchratepartner ">
                <div className="dataexboximage">
                  {dataPartnersExadmin?.latest_advertisers?.map((item, idx) => {
                    return (
                      <img
                        key={idx}
                        src={`${process.env.PUBLIC_URL}/Logos/${item?.ACCOUNT_NAME.toLowerCase()}.png`}
                        alt={item?.ACCOUNT_NAME}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          <div id="third-panel-right" >
            <div id="video-head">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-8 h-8 text-black-400 ml-1px"
              >
                <path
                  fillRule="evenodd"
                  d="M1.5 5.625c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v12.75c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 18.375V5.625zm1.5 0v1.5c0 .207.168.375.375.375h1.5a.375.375 0 00.375-.375v-1.5a.375.375 0 00-.375-.375h-1.5A.375.375 0 003 5.625zm16.125-.375a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h1.5A.375.375 0 0021 7.125v-1.5a.375.375 0 00-.375-.375h-1.5zM21 9.375A.375.375 0 0020.625 9h-1.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 00.375-.375v-1.5zm0 3.75a.375.375 0 00-.375-.375h-1.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 00.375-.375v-1.5zm0 3.75a.375.375 0 00-.375-.375h-1.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 00.375-.375v-1.5zM4.875 18.75a.375.375 0 00.375-.375v-1.5a.375.375 0 00-.375-.375h-1.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h1.5zM3.375 15h1.5a.375.375 0 00.375-.375v-1.5a.375.375 0 00-.375-.375h-1.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375zm0-3.75h1.5a.375.375 0 00.375-.375v-1.5A.375.375 0 004.875 9h-1.5A.375.375 0 003 9.375v1.5c0 .207.168.375.375.375zm4.125 0a.75.75 0 000 1.5h9a.75.75 0 000-1.5h-9z"
                  clipRule="evenodd"
                />
              </svg>
              <span>How to videos</span>
              <span
                className="viewall hover:underline cursor-pointer"
                onClick={() => navigate("/how-to-videos")}
              >
                View All{" "}
              </span>
            </div>
            <div className="max-h-[calc(100vh-4rem)] rounded-lg">
              <video
                width="200"
                height="50"
                controls
                className="w-full h-full rounded-lg"
              >
                <source src={MatchRateVideo} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>

      {user?.role?.includes("Consumer") ||
        user?.role?.includes("Publisher") ||
        user?.role?.includes("Provider") ? (
        <div>
          <h1 className="latestPartner">Top Match Rate Result</h1>
          <div className="matchratepartner ">
            {dataPercentage &&
              dataPercentage.map((item, index) => (
                <div className="matchrate_and_image">
                  <span>
                    Match Rate:{" "}
                    {item.MATCHED_PERCENTAGE !== null
                      ? item.MATCHED_PERCENTAGE.toFixed(2) + "%"
                      : "N/A"}{" "}
                    |
                  </span>
                  <img
                    key={index}
                    src={`${process.env.PUBLIC_URL}/Logos/${user?.role?.includes("Consumer") ||
                      user?.role?.includes("Publisher")
                      ? item?.PROVIDER_NAME.toLowerCase()
                      : item?.CONSUMER_NAME.toLowerCase()
                      }.png`}
                    width="50px"
                    height="30px"
                    alt={
                      user?.role?.includes("Consumer") ||
                        user?.role?.includes("Publisher")
                        ? item?.PROVIDER_NAME
                        : item?.CONSUMER_NAME
                    }
                  />
                </div>
              ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Home;
